import './AddressBook.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addressActions from '../../actions/addressActions';
import userActions from '../../actions/userActions';
import { useHistory } from 'react-router-dom';
import countriesActions from '../../actions/countriesActions';
import isoCountries, { AllowedCountries } from '../../utils/countryList';
import IonIcon from '@reacticons/ionicons';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

type AddressBookItem = {
  address: string;
  address2?: string | undefined;
  addressId: string;
  city: string;
  zipcode: string;
};

export interface FormFieldValidation {
  fullname: string | undefined;
  address: string | undefined;
  zipcode: string | undefined;
  city: string | undefined;
  fullnameValid: boolean;
  addressValid: boolean;
  zipcodeValid: boolean;
  cityValid: boolean;
}

interface IObjectKeys {
  [key: string]: string | number;
}

const defaultFormValidationState = {
  fullname: '',
  address: '',
  zipcode: '',
  city: '',
  fullnameValid: false,
  addressValid: false,
  zipcodeValid: false,
  cityValid: false,
};

const NewAddressForm = (props: any) => {
  const { userId, newAddressSavedHandler } = props;

  const { t } = useTranslation();
  const defaultCountryValue = 'NL';
  const [activeAddress, setActiveAddress] = useState<AddressBookItem | undefined>(undefined);
  const [addressError, setAddressError] = useState<boolean>(false);
  const [fullNameInputValue, setFullNameInputValue] = useState<string>('');
  const [addressInputValue, setAddressInputValue] = useState<string>('');
  const [streetNumberInputValue, setStreetNumberInputValue] = useState<string>('');
  const [address2InputValue, setAddress2InputValue] = useState<string>('');
  const [regionInputValue, setRegionInputValue] = useState<string>('');
  const [zipCodeInputValue, setZipCodeInputValue] = useState<string>('');
  const [cityInputValue, setCityInputValue] = useState<string>('');
  const [countryInputValue, setCountryInputValue] = useState<string>(defaultCountryValue);
  const [countryLongInputValue, setCountryLongInputValue] = useState<string>(defaultCountryValue);
  const [countryInputValueValidation, setCountryInputValueValidation] = useState<boolean>(true);
  const [fullNameIsValid, setFullNameIsValid] = useState<boolean>(false);
  const [addressIsValid, setAddressIsValid] = useState<boolean>(false);
  const [zipCodeIsValid, setZipCodeIsValid] = useState<boolean>(false);
  const [cityIsValid, setCityIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [countries, setCountries] = useState<IObjectKeys>({});
  const [formValidation, setFormValidation] = useState<FormFieldValidation>(defaultFormValidationState);
  const [addressSelector, setAddressSelector] = useState<any>(null);
  const [formVisible, setFormVisible] = useState(false);
  const [highlightMissingInputs, setHighlightMissingInputs] = useState(false);
  const [showAdditionalLine, setShowAdditionalLine] = useState<boolean>(false);

  const history = useHistory();

  const getAddresses = async () => {
    const addresses = (await addressActions.getAddresses(userId)) || {};
    const userActiveAddress = await userActions.getUser(userId).then((r) => (r.metadata || {}).activeAddress);
    setActiveAddress(addresses[0]);
  };

  const loadTranslatedCountries = async () => {
    let allowedCountries: AllowedCountries[];
    try {
      const activeCountries = await countriesActions.getCountries();
      allowedCountries = activeCountries
        .map((country: any) => country.countryCode)
        .filter((countryCode: string) => countryCode !== 'EU');
    } catch (error) {
      allowedCountries = ['NL', 'BE', 'FR', 'GB', 'DE', 'ES'];
    }
    const countries: IObjectKeys = {};
    Object.values(allowedCountries).forEach((countryCode) => {
      const countryName = isoCountries[countryCode];
      countries[countryCode] = countryName;
    });
    setCountries(countries);
  };

  useEffect(() => {
    loadTranslatedCountries();
    getAddresses();
  }, []);

  const saveAddressAndContinue = (event: any) => {
    event.preventDefault();
    setLoading(true);

    const addressLine1 = `${addressInputValue} ${streetNumberInputValue}`;

    const fullAddress = {
      fullname: fullNameInputValue,
      zipcode: zipCodeInputValue,
      address: addressLine1,
      address2: address2InputValue,
      city: cityInputValue,
      region: regionInputValue,
      countryCode: countryInputValue,
    };

    userActions
      .getUser(userId)
      .then(() => addressActions.createAddress(userId, fullAddress))
      .then(() => {
        console.log('SUCCESS');
        newAddressSavedHandler();
      })
      .catch((error) => {
        console.log('ERROR');
        setLoading(false);
        console.error(error);
      });
  };

  const handleUserInput = (event: any) => {
    const { name, value } = event.target;

    switch (name) {
      case 'fullname':
        setFullNameInputValue(value);
        break;
      case 'address':
        setAddressInputValue(value);
        break;
      case 'streetNumber':
        setStreetNumberInputValue(value);
        break;
      case 'address2':
        setAddress2InputValue(value);
        break;
      case 'zipcode':
        setZipCodeInputValue(value);
        break;
      case 'region':
        setRegionInputValue(value);
        break;
      case 'city':
        setCityInputValue(value);
        break;
      case 'countryCode':
        setCountryInputValue(value);
        break;
      default:
        return;
    }
    validateField(name, value);
  };

  const validateField = (name: string, value: string) => {
    let formErrors: FormFieldValidation = defaultFormValidationState;
    switch (name) {
      case 'fullname':
        const fullnameValid = value.length > 0;
        setFullNameIsValid(value.length > 0);
        formErrors.fullname = fullnameValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      case 'address':
        const addressValid = value.length > 0;
        setAddressIsValid(value.length > 0);
        formErrors.address = addressValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      case 'zipcode':
        const zipcodeValid = value.length > 3;
        setZipCodeIsValid(value.length > 3);
        formErrors.zipcode = zipcodeValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      case 'city':
        const cityValid = value.length > 0;
        setCityIsValid(value.length > 0);
        formErrors.city = cityValid ? undefined : t('SendToNewAddress.validation.message');
        break;
      default:
        break;
    }

    setFormValidation(formErrors);
  };
  const returnUrl = () => {
    const { returnUrl } = props;

    if (returnUrl) {
      console.log('returnURL');
      return returnUrl();
    }
    return history.goBack();
  };

  useEffect(() => {
    setFormIsValid(fullNameIsValid && addressIsValid && cityIsValid && zipCodeIsValid);
  }, [fullNameIsValid, addressIsValid, cityIsValid, zipCodeIsValid]);

  const handleInvalidButtonPress = () => {
    setHighlightMissingInputs(true);
  };

  const handleSelectPlace = async (value: any) => {
    if (!value) {
      setFormVisible(false);
      return;
    }
    const results = await geocodeByPlaceId(value.value.place_id);
    const addressComponents = results[0].address_components;

    let localAddress = '';
    let localAddress2 = '';
    let localCity = '';
    let localZip = '';
    let localRegion = '';
    let localCountry = '';
    let longCountryName = '';

    let street_number = '';

    addressComponents.forEach((component) => {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          street_number = component.long_name;
          break;
        case 'route':
          localAddress = component.long_name;
          break;
        case 'locality':
          localCity = component.long_name;
          break;
        case 'administrative_area_level_1':
          localRegion = component.long_name;
          break;
        case 'postal_code':
          localZip = component.long_name;
          break;
        case 'country':
          localCountry = component.short_name;
          longCountryName = component.long_name;
          break;
        default:
          break;
      }
    });

    setAddressInputValue(localAddress);
    setStreetNumberInputValue(street_number);
    setAddress2InputValue(localAddress2);
    setCityInputValue(localCity);
    setZipCodeInputValue(localZip);
    setRegionInputValue(localRegion);
    setCountryInputValue(localCountry);
    setCountryLongInputValue(longCountryName);

    validateField('address', localAddress);
    validateField('city', localCity);
    validateField('zipcode', localZip);

    setFormVisible(true);
  };

  return (
    <span>
      <div className="details">
        <div className="addressBook">
          <h2>{t('AddressBook.NewAddressForm.title')}</h2>
          <p>{t('AddressBook.NewAddressForm.text')}</p>
          <div className="form">
            <div className="googlePlacesInput">
              <GooglePlacesAutocomplete
                selectProps={{
                  placeholder: t('AddressBook.autocomplete.startTypeAddress'),
                  value: addressSelector,
                  onChange: (newValue: any) => handleSelectPlace(newValue),
                }}
              />
            </div>

            <form onSubmit={saveAddressAndContinue}>
              {formVisible && (
                <>
                  <fieldset>
                    <label htmlFor="address-name">{t('SendToNewAddress.name.text')} *</label>
                    <div className="input-wrapper">
                      <input
                        onChange={handleUserInput}
                        value={fullNameInputValue}
                        name="fullname"
                        id="address-name"
                        className={highlightMissingInputs && !fullNameIsValid ? 'highlightError' : ''}
                        type="text"
                        required
                      />
                      {fullNameInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                    </div>
                    <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="address-address">{t('SendToNewAddress.streetAndHouseNumber.text')} *</label>
                    <div className="input-wrapper">
                      <input
                        onChange={(event) => handleUserInput(event)}
                        value={addressInputValue}
                        name="address"
                        id="address-address"
                        className={highlightMissingInputs && !addressInputValue ? 'highlightError' : ''}
                        type="text"
                        maxLength={300}
                        required
                      />
                      {addressInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                    </div>
                    <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                  </fieldset>
                  {!showAdditionalLine && (
                    <div>
                      <p className="addExtraLine" onClick={() => setShowAdditionalLine(true)}>
                        {t('app_screens:AddressForm.label.show_additional_line')}
                      </p>
                    </div>
                  )}
                  {showAdditionalLine && (
                    <fieldset>
                      <label htmlFor="address-address">{t('SendToNewAddress.address2.text')}</label>
                      <div className="input-wrapper">
                        <input
                          onChange={(event) => handleUserInput(event)}
                          name="address2"
                          value={address2InputValue}
                          id="address-address2"
                          className="address"
                          type="text"
                          maxLength={300}
                        />
                        {addressInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                      </div>
                      <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                    </fieldset>
                  )}
                  <fieldset>
                    <label htmlFor="address-address">{t('SendToNewAddress.streetNumber.text')} *</label>
                    <div className="input-wrapper">
                      <input
                        onChange={(event) => handleUserInput(event)}
                        value={streetNumberInputValue}
                        name="streetNumber"
                        id="address-streetNumber"
                        className={highlightMissingInputs && !streetNumberInputValue ? 'highlightError' : ''}
                        type="text"
                        maxLength={300}
                        required
                      />
                      {streetNumberInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                    </div>
                    <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                  </fieldset>

                  <fieldset>
                    <label htmlFor="address-zipcode">{t('SendToNewAddress.postcode.text')} *</label>
                    <div className="input-wrapper">
                      <input
                        onChange={(event) => handleUserInput(event)}
                        value={zipCodeInputValue}
                        name="zipcode"
                        id="address-zipcode"
                        className={highlightMissingInputs && !zipCodeInputValue ? 'highlightError' : ''}
                        type="text"
                        required
                      />
                      {zipCodeInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                    </div>
                    <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="address-address">{t('SendToNewAddress.region.text')}</label>
                    <div className="input-wrapper">
                      <input
                        onChange={(event) => handleUserInput(event)}
                        value={regionInputValue}
                        name="region"
                        id="address-region"
                        className="address"
                        type="text"
                        maxLength={300}
                      />
                      {regionInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                    </div>
                    <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="address-city">{t('SendToNewAddress.city.text')} *</label>
                    <div className="input-wrapper">
                      <input
                        onChange={(event) => handleUserInput(event)}
                        value={cityInputValue}
                        id="address-city"
                        className={highlightMissingInputs && !cityInputValue ? 'highlightError' : ''}
                        name="city"
                        type="text"
                        maxLength={200}
                        required
                      />
                      {cityInputValue.length > 0 && <IonIcon name="checkmark" className="done" />}
                    </div>
                    <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
                  </fieldset>
                  <fieldset>
                    <label htmlFor="country">{t('SendToNewAddress.country.text')}</label>
                    <select
                      id="country"
                      name="countryCode"
                      onChange={(event) => handleUserInput(event)}
                      value={countryInputValue}
                    >
                      {countries &&
                        Object.entries(countries).map(([countryCode, countryName]) => (
                          <option key={countryCode} value={countryCode}>
                            {countryName}
                          </option>
                        ))}
                    </select>
                    <div
                      className="message"
                      style={{
                        display: Object.keys(countries).includes(countryInputValue) ? 'none' : 'block',
                      }}
                    >
                      We do not support {countryLongInputValue} ({countryInputValue}) at the moment.
                    </div>
                  </fieldset>
                </>
              )}
              {addressError && <div className="DisplayWarning">{t('SendToNewAddress.wrongAddress.text')}</div>}
              <fieldset>
                {formIsValid && (
                  <button id="sendOrderBt" className="button" type="submit" disabled={!formIsValid}>
                    {t('AddressBook.NewAddressForm.save.button')}
                  </button>
                )}
              </fieldset>
            </form>
            <div style={{ gap: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {formVisible && !formIsValid && (
                <button id="sendOrderBt" className="button" onClick={handleInvalidButtonPress}>
                  {t('AddressBook.NewAddressForm.save.button')}
                </button>
              )}
              {highlightMissingInputs && (
                <div className="DisplayWarning" style={{ margin: 0 }}>
                  {t('AddressBook.NewAddressForm.completeRequiredFields.label')}
                </div>
              )}
              <button id="shareOrderLinkCancelLink" onClick={returnUrl} className="link" type="button">
                {t('SendToNewAddress.send.cancel.text')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default NewAddressForm;
