import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from '../../services/i18n';
import './OrderHistory.scss';
import Spinner from '../../helpers/Spinner/Spinner';
import { Link, Redirect } from 'react-router-dom';
import useFulfillment from '../../utils/hooks/useFulfillment';
import useProduct from '../../utils/hooks/useProduct';

const KickSmartSupport = (props) => {
  const { userId, orderId } = props;

  const { fulfillments, error, isLoading } = useFulfillment(userId, orderId);
  const { products, error: errProduct, isLoading: isLoadingProduct } = useProduct();

  const getFirstImage = (sku) => {
    if (!products[sku]) {
      return null;
    }

    const image = products[sku].imageSrc;
    if (typeof image === 'string') {
      return image;
    } else if (Array.isArray(image) && image.length > 0) {
      return image[0];
    } else {
      return null;
    }
  };

  if (error || errProduct) return <Redirect to={`/${global.language}/contact`} />;
  if (isLoading || isLoadingProduct) return <Spinner />;

  return (
    <section className="orderHistory">
      <div className="flex-wrapper">
        <div className="flex-container">
          <h2 className="title">{i18next.t('SmartSupport.select_product_to_resolve')}</h2>
          <ul className="order-list">
            {fulfillments &&
              fulfillments.map((fulfillment) => (
                <li key={fulfillment.id} className="order-item">
                  <Link to={`/${global.language}/SmartSupport/${fulfillment.id}?reqByFulfillmentId=true`}>
                    <div className="order-detail">
                      <div style={{ display: 'flex', textAlign: 'start' }}>
                        <div className="product-image">
                          <img className="product-image-item" src={getFirstImage(fulfillment.packageId)} />
                        </div>
                        <div className="product-item">
                          <div className="product-name">
                            {fulfillment.units} x{' '}
                            {products[fulfillment.packageId] ? products[fulfillment.packageId].title.en : 'Product'}
                          </div>
                          <div className="product-variant"></div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
KickSmartSupport.propTypes = {
  userId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
export default KickSmartSupport;
