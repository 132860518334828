import productsActions from '../../actions/productsActions';
import { Product, SUPPORTED_PRODUCT_CATEGORIES } from './types';

export const shopProductsAndCategorySelectorUpdated = async (excludeCategories: string[], categoryOrder: string[]) => {
  const products = (await productsActions.getFormattedProducts()) as Product[];
  const categories = await productsActions.getProductCategories2();
  // Start by filtering and mapping the categories as before
  const specialCategoryProducts: { [key: string]: string[] } = {
    coupon: ['105', '120', '115'],
    subscription: ['2', '8', '20'],
    wall: ['701', '713'],
  };

  const filteredAndMappedCategories = Object.entries(categories)
    .filter(([key]) => !excludeCategories.includes(key))
    .map(([categoryKey, _]) => {
      const categoryProducts = specialCategoryProducts[categoryKey]
        ? specialCategoryProducts[categoryKey].map((id: any) => products[id]).filter((product: Product) => product)
        : Object.values(products).filter((product) => product.type === categoryKey);

      return { title: categoryKey, data: categoryProducts };
    });

  // Sort the categories according to the order specified in `categoryOrder`
  const orderedCategories = categoryOrder
    .map((orderKey) => filteredAndMappedCategories.find((category) => category.title === orderKey))
    .filter((category) => category && category.data.length > 0); // This removes any undefined entries if `categoryOrder` includes non-existent categories

  return orderedCategories;
};

const getShopProductData = async () => {
  const categoriesCollection = await productsActions.getProductCategories2();
  const categories = Object.keys(categoriesCollection);

  const formattedProducts = (await productsActions.getFormattedProducts()) as any;

  const productCollectionKeys = Object.keys(formattedProducts);
  const productCollection = [];
  const categoryArray = [];
  const categoryCollection = {} as any;

  for (const key of productCollectionKeys) {
    if (formattedProducts[key]) {
      productCollection.push({
        ...formattedProducts[key],
      });
    }
  }

  for (let i = 0; i < categories.length; i++) {
    const categoryType = categories[i];
    if (SUPPORTED_PRODUCT_CATEGORIES.includes(categoryType)) {
      const products = productCollection.filter((obj) => obj.type === categoryType);
      const productCollection2 = {} as any;
      for (let y = 0; y < products.length; y++) {
        const resultProduct = products[y];
        productCollection2[resultProduct.key] = { ...products[y] };
      }

      if (products.length > 0) {
        const category = categoriesCollection[categoryType];

        if (categoryType === 'coupon') {
          const categoryProducts = [];
          const giftCardStandardProduct = products.filter((obj: any) => obj.key === '105'); // productSelector(state, '105');
          const giftCardPhotoBookProduct = products.filter((obj: any) => obj.key === '120'); //productSelector(state, '120');
          const giftCardStarterSetProduct = products.filter((obj: any) => obj.key === '115'); //productSelector(state, '115');
          if (giftCardStandardProduct.length == 1) {
            categoryProducts.push(giftCardStandardProduct[0]);
          }
          if (giftCardPhotoBookProduct.length == 1) {
            categoryProducts.push(giftCardPhotoBookProduct[0]);
          }
          if (giftCardStarterSetProduct.length == 1) {
            categoryProducts.push(giftCardStarterSetProduct[0]);
          }
          categoryArray.push({
            id: categoryType,
            description: category.description,
            title: category.title,
            imageSrc: category.thumb,
            products: categoryProducts,
            order: category.order ? category.order : 100,
          });
        } else {
          categoryArray.push({
            id: categoryType,
            description: category.description,
            title: category.title,
            imageSrc: category.thumb,
            products: productCollection2,
            order: category.order ? category.order : 100,
          });
        }
      }
    }
  }

  categoryArray.sort((a, b) => (a.order > b.order ? 1 : -1));
  categoryArray.forEach((category) => (categoryCollection[category.id] = category));
  // setShopData(categoryCollection);
  return categoryCollection;
};

export { getShopProductData };
