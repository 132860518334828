import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';
import userActions from './userActions';
import firebase from 'firebase';
// import auth from '@react-native-firebase/auth';

const API_URL = `${apiBaseUrl}/products`;
const BASE_IMG_PATH = '/img/products';

const GIFT_CARDS_STANDARD = [103, 104, 105];

export const findTranslation = (objectWithTranslations, languages) => {
  try {
    let found;

    languages.forEach((languageToCheck) => {
      if (found === undefined && languageToCheck in objectWithTranslations) {
        found = objectWithTranslations[languageToCheck];
      }
    });

    return found;
  } catch (error) {
    return null;
  }
};

const getProductCategories2 = async () => {
  return new Promise(async (resolve, reject) => {
    firebase
      .database()
      .ref('ProductCategories')
      .on(
        'value',
        (snapshot) => {
          const categories = snapshot.val();
          resolve(categories);
        },
        (error) => {
          // dispatch(getPictureOrdersFailedAction({ error: error.code }));
        }
      );
  });
};

export default {
  getProductCategories2,
  getProduct(productId) {
    const requestBody = {
      method: 'GET',
    };

    return fetch(`${API_URL}/${productId}`, requestBody);
  },

  getProductCategories() {
    return firebase
      .database()
      .ref('ProductCategories')
      .on(
        'value',
        (snapshot) => {
          const categories = snapshot.val();
          return categories;
        },
        (error) => {
          // dispatch(getPictureOrdersFailedAction({ error: error.code }));
        }
      );
  },

  getProducts() {
    const requestBody = {
      method: 'GET',
    };

    return fetch(`${API_URL}`, requestBody);
  },

  /**
   * Extracts the language at /:language/key into /key
   * Where the language is read from user's profile
   *
   * @param {*} product
   * @param {*} userId
   */
  extractTranslatedProduct(product, productId, languages, uiLanguages) {
    try {
      if (!(product instanceof Object && languages instanceof Array && languages.length > 0)) {
        throw new Error('Invalid input');
      }
      /**
       * Initialize products with image and thumnail urls from local path
       */
      const formattedProduct = {
        imageSrc: [`${BASE_IMG_PATH}/${productId}.png`],
        thumbSrc: [`${BASE_IMG_PATH}/${productId}_thumb.png`],
        key: productId,
      };

      // Keys to skip translation using uiLanguage
      const uiForbiddenKeys = {
        currency: true,
        price: true,
        shippingCost: true,
      };

      Object.entries(product).forEach(([key, objectWithTranslations]) => {
        // If the object is not structures /:language/otherPath,
        // then fallback to objectWithTranslations
        let translation;
        // If uiLanguages are passed and the key is not forbidden
        if (uiLanguages && !uiForbiddenKeys[key]) {
          translation = findTranslation(objectWithTranslations, uiLanguages);
        }

        if (translation === undefined) {
          translation = findTranslation(objectWithTranslations, languages);
        }

        formattedProduct[key] = translation || translation === 0 ? translation : objectWithTranslations;
      });

      return formattedProduct;
    } catch (error) {
      console.error(error);

      return null;
    }
  },

  /**
   * Gets all the products from the API
   * Filters by type === 'product
   * Extracts translated keys based on language from profile
   *
   * @param {*} userId
   */
  async getFormattedProducts() {
    const productsObject = await this.getProducts();
    const uid = userActions.getUserId();
    let languageCode, countryCode;
    if (uid) {
      const { profile } = await userActions.getUser(uid);
      languageCode = profile.languageCode;
      countryCode = profile.countryCode;
    } else {
      const globalLang = global.language.split('-');
      languageCode = globalLang[0];
      countryCode = globalLang[0];
    }

    const KLIKKIE_COUNTRIES = ['NL', 'BE', 'GB', 'FR'];
    if (KLIKKIE_COUNTRIES.includes(countryCode) === false) {
      languageCode = 'en';
      countryCode = 'EU';
    }

    const language = `${languageCode}-${countryCode}`;

    const languagesFallback = [language, countryCode, languageCode];
    let uiLanguagesFallback;

    if (global.language) {
      const [uiLanguageCode, uiCountryCode] = global.language.split('-');

      uiLanguagesFallback = [global.language, uiLanguageCode, uiCountryCode];
    }

    const filteredProducts = {};

    Object.entries(productsObject)
      .filter(([key]) => {
        const productKey = parseInt(key, 10);
        const product = productsObject[key];
        if (GIFT_CARDS_STANDARD.includes(productKey)) return true;
        if (
          !product.active ||
          !product.activeCountry[countryCode] ||
          productKey <= 110 ||
          productKey === 501 ||
          (productKey > 600 && productKey < 700) ||
          (productKey > 300 && productKey < 400)
        ) {
          return false;
        }

        return true;
      })
      .forEach(([productId, product]) => {
        filteredProducts[productId] = this.extractTranslatedProduct(
          product,
          productId,
          languagesFallback,
          uiLanguagesFallback
        );
      });
    return filteredProducts;
  },
  async getFormattedProduct(productId) {
    try {
      if (!productId) {
        throw new Error('No productId or userId');
      }
      const product = await this.getProduct(productId);

      if (!GIFT_CARDS_STANDARD.includes(parseInt(productId)) && (productId < 110 || productId === 501)) {
        return null;
      }

      const { profile } = await userActions.getUser();

      let { languageCode, countryCode } = profile;

      const KLIKKIE_COUNTRIES = ['NL', 'BE', 'GB', 'FR'];
      if (KLIKKIE_COUNTRIES.includes(countryCode) === false) {
        languageCode = 'en';
        countryCode = 'EU';
      }

      const language = `${languageCode}-${countryCode}`;

      const languagesFallback = [language, countryCode, languageCode];
      let uiLanguagesFallback;

      if (global.language) {
        const [uiLanguageCode, uiCountryCode] = global.language.split('-');

        uiLanguagesFallback = [global.language, uiLanguageCode, uiCountryCode];
      }

      return this.extractTranslatedProduct(product, productId, languagesFallback, uiLanguagesFallback);
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async getPhotoSets(userId) {
    try {
      const productsObject = await this.getProducts();
      const { profile } = await userActions.getUser(userId);
      let { languageCode, countryCode } = profile;

      const KLIKKIE_COUNTRIES = ['NL', 'BE', 'GB', 'FR'];
      if (KLIKKIE_COUNTRIES.includes(countryCode) === false) {
        languageCode = 'en';
        countryCode = 'EU';
      }

      const language = `${languageCode}-${countryCode}`;

      const languagesFallback = [language, countryCode, languageCode];
      let uiLanguagesFallback;

      if (global.language) {
        const [uiLanguageCode, uiCountryCode] = global.language.split('-');

        uiLanguagesFallback = [global.language, uiLanguageCode, uiCountryCode];
      }

      const filteredProducts = Object.entries(productsObject)
        .filter(([key, { type, active }]) => type === 'pictures' && key !== '501' && active)
        .map(([productId, product]) =>
          this.extractTranslatedProduct(product, productId, languagesFallback, uiLanguagesFallback)
        );

      return filteredProducts;
    } catch (error) {
      console.error('getPhotoSets', error);
      throw error;
    }
  },
};
