import { useEffect, useState } from 'react';
import productActions from '../../actions/productsActions';

const useProduct = () => {
  const [products, setProducts] = useState<any[] | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    productActions
      .getProducts()
      .then((res) => {
        setProducts(res);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  }, []);

  return { products, error, isLoading };
};

export default useProduct;
