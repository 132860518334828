import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import addressActions from '../../actions/addressActions';
import userActions from '../../actions/userActions';
import NewAddressForm from './NewAddressForm';
import AddressBookItemNew from './AddressBookItemNew';
import IonIcon from '@reacticons/ionicons';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

type Address = {
  addressId: string;
  fullname: string;
  address: string;
  city: string;
  countryCode: string;
  zipcode: string;
};

type AddressCollection = Address[];

const AddressBookNew = (props: any) => {
  const { userId } = props;
  const query = useQuery();
  const { t } = useTranslation();
  const [toSubscription, setToSubscription] = useState<boolean>(false);
  const [activeAddress, setActiveAddress] = useState<string | undefined>(undefined);
  const [addresses, setAddresses] = useState<AddressCollection>([]);

  const primaryAddress = useMemo(() => {
    return activeAddress ? addresses.filter((address: Address) => address.addressId === activeAddress)[0] : undefined;
  }, [activeAddress, addresses]);

  const addressesFiltered = useMemo(() => {
    return activeAddress ? addresses.filter((address: Address) => address.addressId !== activeAddress) : addresses;
  }, [activeAddress, addresses]);

  const showNewAddressForm = useMemo(() => {
    let reasonValue: boolean = false;
    if (query.get('new')) {
      const newValue = query.get('new') as string;
      if (newValue == 'true') return true;
      console.log({ reasonValue });
    }

    return reasonValue;
  }, [query.get('new')]);

  const handleSubscriptionChangeResponse = async (snapshot: any | null) => {
    const updatedAddresses: Address[] = [];
    snapshot.forEach((childSnapshot: any) => {
      var childKey = childSnapshot.key;
      updatedAddresses.push({
        addressId: childKey,
        ...childSnapshot,
      });
    });
    setAddresses(updatedAddresses);
  };

  useEffect(() => {
    loadAddressBook();
  }, []);
  const loadAddressBook = async () => {
    const addresses = (await addressActions.getAddresses(userId)) || {};
    const activeAddress = await userActions.getUser(userId).then((r) => (r.metadata || {}).activeAddress);
    setActiveAddress(activeAddress);
    handleSubscriptionChangeResponse(addresses);
  };

  if (toSubscription) {
    return <Redirect to={`/${global.language}/subscription`} />;
  }

  const addAddressHandler = () => {
    history.back();
  };

  const setNewActiveAddress = (addressId: string) => {
    if (addressId !== primaryAddress?.addressId) {
      addressActions.setActiveAddress(userId, addressId);
    }
  };
  const refreshAddressbook = () => {
    console.log('refreshAddressbook');
    loadAddressBook();
  };

  if (showNewAddressForm) {
    return <NewAddressForm newAddressSavedHandler={addAddressHandler} userId={userId} />;
  }

  return (
    <div className="profileAddressBook">
      {activeAddress && primaryAddress && (
        <div className="primaryAddress">
          <h2>{t('AddressBook.PrimaryAddress.title')}</h2>
          <AddressBookItemNew
            address={primaryAddress}
            key={0}
            isPrimaryAddress={true}
            refreshAddressbook={refreshAddressbook}
          />
        </div>
      )}

      <h2>{t('AddressBook.OtherAddress.title')}</h2>

      <Link
        className="addAddress"
        to={{
          pathname: `/${global.language}/addressbook`,
          search: '?new=true',
        }}
      >
        <div className="circle">+</div>
        <span>{t('AddressBook.addNewAddress.button')}</span>
      </Link>
      <div className="book">
        {addressesFiltered.length > 0 &&
          addressesFiltered &&
          addressesFiltered.map((address, index) => {
            return (
              <AddressBookItemNew
                address={address}
                key={index}
                isPrimaryAddress={activeAddress == address.addressId}
                refreshAddressbook={refreshAddressbook}
                setNewActiveAddress={setNewActiveAddress}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AddressBookNew;
