import TagManager from 'react-gtm-module';
import { matomoInstance } from '../services/matomoInstance';

// New data structure for purchase event
function transformOrderToGTM4(order) {
  // Populate the items
  const items = order.products.map((product) => ({
    item_id: product.sku,
    item_name: product.name,
    item_brand: 'klikkie',
    item_category: product.type,
    price: product.unitPrice.toString(),

  }));

  // Populate the ecommerece event
  const transformed = {
    eccomerce: {
      transaction_id: order.id,
      currency: order.currency,
      value: order.totalToCharge === 0 ? 0 : order.totalToCharge / 100,
      items,
      coupon: order.discountCode,
      discount: order.totalDiscount === 0 ? 0 : order.totalDiscount / 100,
    }
  };
  return transformed;
}

function transformOrderToMatomo(order) {
  return order.products.map((product) => ({
    sku: product.sku,
    name: product.name,
    category: product.type,
    price: product.unitPrice,
    quantity: product.units,
  }));
}

export const trackingClick = (eventCategory, eventLabel, order) => {
  if (!eventCategory || !eventLabel) {
    console.error('Error: eventCategory and eventLabel are required for trackingClick.');
    return;
  }

  const event = {
    dataLayer: {
      event: 'event',
      eventCategory,
      eventAction: 'click',
      eventLabel,
    },
  };
  if (order) {
    event.dataLayer.ecommerce = transformOrderToGTM4(order);

    // Track purchase in Matomo
    matomoInstance.trackEvent({
      category: 'Ecommerce',
      action: 'Purchase',
      name: eventLabel,
      value: order.totalToCharge,
    });

    // Track order details in Matomo
    matomoInstance.trackEcommerceOrder({
      orderId: order.orderId,
      grandTotal: order.totalToCharge,
      subTotal: order.subTotal,
      tax: order.totalVat,
      shipping: order.totalShipping,
      discount: order.discountAmount,
      items: transformOrderToMatomo(order),
    });

  } else {
    // Track click event in Matomo
    matomoInstance.trackEvent({
      category: eventCategory,
      action: 'click',
      name: eventLabel,
    });
  }
  TagManager.dataLayer(event);
};