import moment from 'moment';
import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import i18next from '../services/i18n';
import { getUserBearer, getUserId } from './authActions';
import { database, auth, currentUser } from '../services/firebase';

const API_URL = `${apiBaseUrl}/subscriptions`;

export const subscriptionListener = async (f) => {
  const userId = getUserId();
  subscriber = database.ref(`Subscriptions/${userId}`).on('value', f);
};

export default {
  async getSubscription(userId) {
    try {
      const token = await getUserBearer();
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const subscription = await fetch(`${API_URL}/${userId}`, requestBody);

      return subscription;
    } catch (error) {
      // console.error(error);
      throw new Error(i18next.t('Login.error.subscription'));
    }
  },

  async pauseSubscription(userId, data) {
    try {
      const token = await getUserBearer();
      const requestBody = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };
      const request = await fetch(`${API_URL}/${userId}/pause`, requestBody);
      return request;
    } catch (error) {
      // console.error(error);
      throw new Error(i18next.t('Login.error.subscription'));
    }
  },

  async unPauseSubscription(userId) {
    try {
      const token = await getUserBearer();
      const requestBody = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const request = await fetch(`${API_URL}/${userId}/unpause`, requestBody);
      return request;
    } catch (error) {
      // console.error(error);
      throw new Error(i18next.t('Login.error.subscription'));
    }
  },

  async getActiveOrder(userId) {
    const subscription = await this.getSubscription(userId);

    if (!subscription) {
      return null;
    }
    return subscription.activeOrder;
  },

  async getScheduledEvents(userId) {
    try {
      const token = await getUserBearer();
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const events = await fetch(`${apiBaseUrl}/eventSchedules/user/${userId}`, requestBody);

      return events;
    } catch (error) {
      // console.log('getScheduledEvents error', error);
      const updatedError = new Error(error.data || error.message || 'Unknown');
      // Sentry.withScope((scope) => {
      //   scope.setLevel('error');
      //   Sentry.captureException(updatedError);
      // });
      throw new Error(error);
    }
  },

  async getScheduledEventData(userId) {
    try {
      const data = await this.getScheduledEvents(userId);
      if (data) {
        const dataEntries = Object.entries(data);
        const events = dataEntries.map((entry) => {
          const eventId = entry[0];
          const eventData = entry[1];
          const event = {
            eventId,
            ...eventData,
          };
          return event;
        });
        return events;
      }
    } catch (error) {
      return [];
    }
    return [];
  },

  async getReactivationDate(userId) {
    try {
      const data = await this.getScheduledEvents(userId);
      if (data) {
        const dataEntries = Object.entries(data);
        const activationEventArray = dataEntries.filter((scheduledEvent, index) => {
          const eventData = scheduledEvent[1];
          const { event, triggerAt } = eventData;
          return event === 'subscription-activate' && triggerAt;
        });

        if (activationEventArray.length === 1 && activationEventArray[0][1]) {
          const activationEvent = activationEventArray[0][1];
          const { triggerAt } = activationEvent;
          const reactivationDateUnix = moment(triggerAt).format('DD MMMM YYYY');
          return reactivationDateUnix;
        }
      }
    } catch (error) {
      return undefined;
    }
  },
  async workoutActualStatus(subscription, scheduledEventData) {
    const hasPausedEvent = scheduledEventData.filter((event) => {
      return event.event === 'subscription-pause';
    })[0];

    const hasReactivationEvent = scheduledEventData.filter((event) => {
      return event.event === 'subscription-activate';
    })[0];

    const isCurrentlyPaused = subscription.status === 'paused';
    const isCurrentlyActive = subscription.status === 'active';
    const hasPrepaidUntil = subscription.prepaidUntil;
    // console.table(
    //   'hasReactivationEvent',
    //   hasReactivationEvent,
    //   'isCurrentlyPaused',
    //   isCurrentlyPaused,
    //   'isCurrentlyActive',
    //   isCurrentlyActive,
    //   'hasPrepaidUntil',
    //   hasPrepaidUntil
    // );

    if (hasReactivationEvent && !hasPausedEvent && isCurrentlyPaused && !isCurrentlyActive) {
      // console.log('SCENARIO 4');
      return 'paused';
    }

    if (!hasPausedEvent && !hasReactivationEvent && !isCurrentlyActive && isCurrentlyPaused) {
      // console.log('SCENARIO 3');
      return 'churned';
    }

    if (hasPausedEvent && !hasReactivationEvent && isCurrentlyActive && !isCurrentlyPaused) {
      // console.log('SCENARIO 1');
      return 'churned';
    }

    if (hasPausedEvent && hasReactivationEvent && isCurrentlyActive && !isCurrentlyPaused) {
      // console.log('SCENARIO 2');
      return 'paused';
    }

    if (!hasPausedEvent && !hasReactivationEvent && isCurrentlyActive && !isCurrentlyPaused && hasPrepaidUntil) {
      // console.log('SCENARIO 5');
      return 'active';
    }

    if (!hasPausedEvent && !hasReactivationEvent && isCurrentlyActive && !isCurrentlyPaused && !hasPrepaidUntil) {
      // console.log('SCENARIO 6');
      return 'active';
    }
  },
};
