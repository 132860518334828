import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

const API_URL = `${apiBaseUrl}/addresses`;
const API_BASE_URL = `${apiBaseUrl}`;

export default {
  createAddress(userId, address) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(address),
      };

      return fetch(`${API_URL}/${userId}`, requestBody);
    });
  },

  getAddress(userId, addressId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${userId}/${addressId}`, requestBody);
    });
  },

  getAddresses(uid) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${uid}`, requestBody);
    });
  },
  deleteAddress(uid, addressId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${uid}/${addressId}`, requestBody);
    });
  },
  editAddress(uid, addressId, address) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(address),
      };

      return fetch(`${API_URL}/${uid}/${addressId}`, requestBody);
    });
  },
  setActiveAddress(userId, addressId) {
    const metadata = { activeAddress: addressId };
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(metadata),
      };

      return fetch(`${API_BASE_URL}/users/${userId}/metadata`, requestBody);
    });
  },
};
