import { trackingClick } from '../actions/trackingActions';

export const trackingProductsPage = (page) => {
  let eventCategory;
  if (page === 'products') eventCategory = 'shop';
  else if (page === 'product') eventCategory = 'product';
  else eventCategory = 'unknown';

  return eventCategory;
};

export const trackingProducts = (elementClicked, page) => {
  const eventCategory = trackingProductsPage(page);
  let eventLabel;

  if (elementClicked === 'link') eventLabel = 'read_more';
  else if (elementClicked === 'button' && page === 'shop') eventLabel = 'view_item';
  else if (elementClicked === 'button' && page === 'product') eventLabel = 'add_to_cart';
  else eventLabel = 'unknown_action';

  if (!eventCategory || !eventLabel) {
    console.error('Error: category and action are required.', { eventCategory, eventLabel });
    return;
  }

  return trackingClick(eventCategory, eventLabel);
};