import React from 'react';
import './Checkout.scss';
import { formatPrice } from '../../utils/CurrencyUtils';
import { useTranslation } from 'react-i18next';
import ProductLineItem from '../../components/ProductLineItem/ProductLineItem';
import AddressBookSelector from '../../components/AddressBook/AddressBookSelector';
import { Link } from 'react-router-dom';
import { getLinksfromLocize } from '../../config';

interface ICheckoutPresentationalProps {
  product: any;
  isLoading: boolean;
  user: any;
  paymentStatus?: 'checkout' | 'success' | 'failed';
  addressRequired?: boolean;
  selectAddressHandler: (selectedAddressId: string) => void;
  buyHandler: () => void;
  backToHomeHandler: () => void;
  tryAgainHandler: () => void;
}

const CheckoutPresentational = (props: ICheckoutPresentationalProps) => {
  const {
    product,
    user,
    isLoading,
    paymentStatus,
    addressRequired,
    selectAddressHandler,
    buyHandler,
    backToHomeHandler,
    tryAgainHandler,
  } = props;
  const { price, currency, units } = product;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>{t('common:loading')}</p>
      </div>
    );
  }

  const renderProductLineTitle = () => {
    return <ProductLineItem product={product} />;
  };

  const renderShippingLine = () => {
    const shippingCost = product.shippingCost || 0;
    const formattedShippingPrice = formatPrice(shippingCost, currency, 'currency');

    return (
      <div className="checkout-line-item">
        <p className="title">{t('web-app:Shop.shipping')}</p>
        <p className="descriptor">{formattedShippingPrice}</p>
      </div>
    );
  };

  const renderTotalLine = () => {
    const shippingCost = product.shippingCost || 0;

    const formattedTotalPrice = formatPrice(price + shippingCost, currency, 'currency');
    return (
      <div className="total-line-item" style={{ background: 'clear' }}>
        <h2 className="title">{t('web-app:Shop.total')}</h2>
        <h2 className="descriptor">{formattedTotalPrice}</h2>
      </div>
    );
  };

  const renderBuyButton = () => {
    return (
      <div>
        <button
          id="confirmBuyLink"
          className="button buyButton"
          type="button"
          onClick={buyHandler}
          // disabled={!this.state.addressId}
        >
          {t('Shop.product.checkout.button', {
            // price: formattedPrice,
          })}
        </button>
      </div>
    );
  };

  const renderAddressItem = () => {
    if (!addressRequired) return null;

    return (
      <div>
        <p style={{ margin: 0 }}>{t('shop:checkout.willShipToMultipleAddress.label')}</p>
        <AddressBookSelector user={user} changeAddressId={(addressId: string) => selectAddressHandler(addressId)} />
      </div>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <div className="paymentStatusContainer">
        <img
          src="https://media.giphy.com/media/VcvlXopOiPMwBFZ24Q/giphy.gif"
          className="decoratedImg animation"
          alt="animation"
        />
        <h2
          dangerouslySetInnerHTML={{
            __html: t('Shop.product.success.title', { product }),
          }}
        />
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: t('Shop.product.success.body', { product }),
          }}
        />
        <Link id="back2Home" to={`/${global.language}/shop`} onClick={backToHomeHandler}>
          <button id="backHomeLink" className="button buyButton" type="button">
            {t('Shop.product.success.button')}
          </button>
        </Link>
      </div>
    );
  };

  const renderFailureMessage = () => {
    const link = getLinksfromLocize();

    return (
      <div className="paymentStatusContainer">
        <img
          src="https://media1.giphy.com/media/73h3LBWraONTW/giphy.gif?cid=790b76115d1cbf1a6b572f456399f792&rid=giphy.gif"
          className="decoratedImg animation"
          alt="animation"
        />
        <h2
          className="Page__title u-upper"
          dangerouslySetInnerHTML={{
            __html: t('Shop.product.failed.title'),
          }}
        />
        <p
          className="Page__copy"
          dangerouslySetInnerHTML={{
            __html: t('Shop.product.failed.body'),
          }}
        />
        <div className="Page__button" role="button">
          <button id="backHomeLink" className="button buyButton" type="button" onClick={tryAgainHandler}>
            {t('Shop.product.failed.button')}
          </button>
        </div>
        <a href={link.contact as string}>{t('app_screens:AppSupport.ContactUs.label')}</a>
      </div>
    );
  };

  const renderMainContent = () => {
    if (paymentStatus && paymentStatus === 'success') {
      return renderSuccessMessage();
    }

    if (paymentStatus && paymentStatus === 'failed') {
      return renderFailureMessage();
    }

    return (
      <div className="checkout-container-inner">
        <div>
          <h1>{t('Shop.product.checkout.title')}</h1>
        </div>
        {renderProductLineTitle()}
        {renderShippingLine()}
        {renderTotalLine()}
        {renderAddressItem()}
        {renderBuyButton()}
      </div>
    );
  };

  return (
    <div className="checkout-container">
      <div className="checkout-container-inner">{renderMainContent()}</div>
    </div>
  );
};

export default CheckoutPresentational;
