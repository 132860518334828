import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import LogoAnimation from '../../helpers/LogoAnimation/LogoAnimation';

import KlikkieLogo from '../../img/klikkie-logo.png';
import InstagramIcon from '../../img/icons/instagram_icon.png';
import FacebookIcon from '../../img/icons/facebook_icon.png';
import TranslatedLink from '../Link/TranslatedLink';
import { useTranslation } from 'react-i18next';
import { trackingClick } from '../../actions/trackingActions';

const Footer = () => {
  const { t } = useTranslation();

  const trackingClickHandlerGenerator = (eventLabel: string) => {
    trackingClick('Menu', eventLabel);
  };

  const renderPaymentMethods = () => {
    return (
      <div>
        <div>
          <p className="header">Secure Payments</p>
        </div>
        <div className="paymentMethods">
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/604152e5875747202768523f_ideal.png"
            loading="lazy"
            alt="iDeal logo"
            className="payments nl-only"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/648040ec6410b57715f9cee1_Apple_Pay_Mark_RGB_041619.svg"
            loading="lazy"
            alt=""
            className="payments"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/604152e587574721ca685243_paypal.png"
            loading="lazy"
            alt=""
            className="payments"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/62b1b2145a09193174838e87_bancontact.png"
            loading="lazy"
            alt=""
            className="payments be-only"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/604152e5875747a972685241_visa.png"
            loading="lazy"
            alt=""
            className="payments en-only"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/604152e5875747519d685240_ms.png"
            loading="lazy"
            alt=""
            className="payments"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/62b1b2275a5cea7d85ac05f7_cb.png"
            loading="lazy"
            alt=""
            className="payments fr-only"
          />
          <img
            src="https://assets-global.website-files.com/604152e58757471c916851fa/604152e58757473fca685242_amex.png"
            loading="lazy"
            alt=""
            className="payments"
          />
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <ul>
        <div>
          <p className="header">Menu</p>
        </div>
        <li>
          <TranslatedLink to="/" onClick={() => trackingClickHandlerGenerator('current_order')}>
            <div>{t('Menu.currentMonth.text')}</div>
          </TranslatedLink>
        </li>
        <li>
          <TranslatedLink to="/orders" onClick={() => trackingClickHandlerGenerator('orders')}>
            <div>{t('Menu.myMonths.text')}</div>
          </TranslatedLink>
        </li>
        <li>
          <TranslatedLink to="/orderHistory" onClick={() => trackingClickHandlerGenerator('orderHistory')}>
            <div>{t('Menu.shop.orderHistory')}</div>
          </TranslatedLink>
        </li>
        <li>
          <TranslatedLink to="/shop" onClick={() => trackingClickHandlerGenerator('shop')}>
            <div>{t('Menu.shop.text')}</div>
          </TranslatedLink>
        </li>
        <li>
          <TranslatedLink to="/invite" onClick={() => trackingClickHandlerGenerator('mgm')}>
            <div>{t('Menu.invite')}</div>
          </TranslatedLink>
        </li>
        <li>
          <TranslatedLink to="/profile" onClick={() => trackingClickHandlerGenerator('profile')}>
            <div>{t('Menu.myProfile.text')}</div>
          </TranslatedLink>
        </li>
      </ul>
    );
  };

  return (
    <div className="footer">
      <div className="container">
        <div className="left-side">
          <Link to={`/${global.language}/`} className="logo">
            <img className="logo" src={KlikkieLogo} />
          </Link>
          <div className="socials">
            <div>
              <p>Join us on our social networks!</p>
            </div>
            <div className="social-links">
              <a href="https://instagram.com/klikkieapp" target="_blank" rel="noopener noreferrer">
                <img className="social_link" src={InstagramIcon} />
              </a>
              <a href="https://www.facebook.com/Klikkie" target="_blank" rel="noopener noreferrer">
                <img className="social_link" src={FacebookIcon} />
              </a>
            </div>
          </div>
        </div>
        <div className="right-side">
          <div className="menu-section">
            {renderPaymentMethods()}
            <div>
              <p>© 2024 klikkie b.v</p>
            </div>
          </div>
          <div className="menu-section">{renderMenu()}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
