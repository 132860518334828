import React from 'react';
import Lottie from 'lottie-react';
import animation from './smalllogo.json';
import './CircleLogoAnimation.scss';

const CircleLogoAnimation = () => (
  <div className="circleLogo">
    <Lottie animationData={animation} loop={false} />
  </div>
);
export default CircleLogoAnimation;
