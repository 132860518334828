import React, { useState } from 'react';
import PropTypes from 'prop-types';
import userActions from '../../actions/userActions';
import './SelectLanguage.scss';
import { trackingClick } from '../../actions/trackingActions';
import IonIcon from '@reacticons/ionicons';

const SelectLanguage = ({ changeLanguage }) => {
  const [display, setDisplay] = useState(false);
  const [languages] = useState([
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'nl',
      name: 'Dutch',
    },
    {
      code: 'fr',
      name: 'Francais',
    },
    {
      code: 'de',
      name: 'Deutsch',
    },
    {
      code: 'es',
      name: 'Español',
    },
  ]);

  const [currentLanguageCode, currentCountryCode] = global.language.split('-');

  const currentLanguage = languages.find(({ code }) => code === currentLanguageCode);
  const displayLanguages = languages.filter((lang) => lang.code !== currentLanguage.code);

  const toggleLanguages = () => setDisplay(!display);

  const getLanguageCode = (lang) => {
    switch (lang) {
      case 'en':
        return 'en-EU';
      case 'fr':
        return 'fr-FR';
      case 'es':
        return 'es-ES';
      case 'de':
        return 'de-DE';
      case 'nl': {
        switch (currentCountryCode) {
          default:
          case 'NL':
            return 'nl-NL';
          case 'BE':
            return 'nl-BE';
        }
      }
      default:
        return global.language;
    }
  };

  const handleChangeLanguage = (lang) => {
    const newLanguage = getLanguageCode(lang);
    changeLanguage(newLanguage);
    userActions.setProfileLanguage(lang);
    toggleLanguages();
  };

  const trackingLanguageChange = (eventLabel) => {
    trackingClick('menu', eventLabel);
  };

  const handleLanguageContainer = () => {
    toggleLanguages();
    trackingLanguageChange('language_switch');
  };

  const handleLanguageChange = (language) => () => {
    handleChangeLanguage(language.code);
    trackingLanguageChange('select_language');
  };

  return (
    <div className="selectLanguageContainer">
      <div className="language" role="presentation" onClick={handleLanguageContainer}>
        <div className="languageName">{currentLanguage.name}</div>
        <div className="languageDetails">
          <div className="languageArrow">
            {display ? <IonIcon name="chevron-up-outline" /> : <IonIcon name="chevron-down-outline" />}
          </div>
        </div>
      </div>

      <div className={`languagesContainer ${display ? 'is-active' : ''}`}>
        {displayLanguages.map((language, index) => {
          const idx = index;

          return (
            <div key={idx} role="presentation" className="language" onClick={handleLanguageChange(language)}>
              <div className="languageName">{language.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

SelectLanguage.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
};

export default SelectLanguage;
