import React from 'react';
import PropTypes from 'prop-types';

const RadioBox = ({
  addressId,
  fullname,
  address,
  address2 = '',
  zipcode,
  city,
  addressSelection,
  selectedAddress,
}) => {
  const isChecked = selectedAddress === addressId;

  const handleChange = (e) => {
    addressSelection(e.target.value);
  };

  return (
    <div className="radioBoxWrapper">
      <label className={`addressCheckBox ${isChecked ? 'checked' : ''}`} htmlFor={addressId}>
        <input
          type="radio"
          id={addressId}
          name="addressSelection"
          value={addressId}
          onChange={handleChange}
          checked={isChecked}
        />
        <div className="addressDetails">
          <div className="name">
            <p style={{ margin: 0 }}>{fullname}</p>
          </div>
          <div className="address">{address}</div>
          <div className="address2">{address2}</div>
          <div className="zipcode">{zipcode}</div>
          <div className="city">{city}</div>
        </div>
      </label>
    </div>
  );
};

RadioBox.propTypes = {
  selectedAddress: PropTypes.string.isRequired,
  addressSelection: PropTypes.func.isRequired,
  addressId: PropTypes.string.isRequired,
  fullname: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  address2: PropTypes.string,
  zipcode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
};

export default RadioBox;
