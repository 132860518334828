import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../utils/CurrencyUtils';
import './Invoices.scss';
import orderActions from '../../actions/orderActions';
import Invoice from './Invoice';
import i18next from '../../services/i18n';

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredOrders: [],
      selectedOrder: undefined,
      loadedPayments: false,
      userId: this.props.userId, // Initialize userId from props
    };
  }
  componentDidMount() {
    this.fetchOrders();
  }

  async fetchOrders() {
    const { userId } = this.state; // Use userId from state

    try {
      const ordersResponse = await orderActions.getOrders(userId);
      const orders = ordersResponse.status ? [ordersResponse] : ordersResponse;

      const filteredOrders = orders
        .filter((order) => order.status === 'fulfilled' && order.totalToCharge > 0)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      this.setState({ filteredOrders, loadedPayments: true });
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }

  selectOrder(index) {
    this.setState({ selectedOrder: index });
  }

  closeWindow() {
    this.setState({ selectedOrder: undefined });
  }

  renderInvoiceModal() {
    const { filteredOrders, selectedOrder } = this.state;
    const selectedOrderData = selectedOrder !== undefined ? filteredOrders[selectedOrder] : {};
    console.log({ selectedOrderData, selectedOrder, filteredOrders });

    return (
      <div className="invoiceLayout">
        <div className="buttons">
          <div className="close" onClick={this.closeWindow} role="button" tabIndex={0}>
            &times;
          </div>
          <div className="button fixed" onClick={window.downloadAsPdf} role="button" tabIndex={0}>
            {i18next.t('Invoices.invoice.download')}
          </div>
        </div>
        <Invoice order={selectedOrderData} />
      </div>
    );
  }

  render() {
    const { filteredOrders, selectedOrder, loadedPayments } = this.state;

    return (
      <div className="invoicesContainer">
        {selectedOrder === undefined ? (
          <div className="invoices">
            {!loadedPayments && <p>Loading...</p>}
            {filteredOrders.length === 0 && loadedPayments && <p>No Successful payments found</p>}
            {filteredOrders.length > 0 && (
              <div>
                <h2>{i18next.t('Invoices.title')}</h2>
                <p>{i18next.t('Invoices.text')}</p>
                <table>
                  <thead>
                    <tr>
                      <th>{i18next.t('Invoices.invoice.date')}</th>
                      <th>{i18next.t('Invoices.amount')}</th>
                      <th>{i18next.t('Invoices.reference')}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.map((order, index) => (
                      <tr key={order.id} className="invoice" onClick={() => this.selectOrder(index)}>
                        <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                        <td>{formatPrice(order.totalToCharge, order.currency)}</td>
                        <td>{order.id || order.orderId}</td>
                        <td>{i18next.t('Invoices.view')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ) : (
          this.renderInvoiceModal()
        )}
      </div>
    );
  }
}

Invoices.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Invoices;
