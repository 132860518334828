import './header.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Menu from '../Menu/Menu';
import LogoAnimation from '../../helpers/LogoAnimation/LogoAnimation';

const AppHeader = ({ authenticated, changeLanguage }) => (
  <span>
    {' '}
    {authenticated && (
      <header>
        <div className="headerContainer">
          <Link to={`/${global.language}/`} className="logo">
            <LogoAnimation />
          </Link>
          <Menu changeLanguage={changeLanguage} />
        </div>
      </header>
    )}
  </span>
);
AppHeader.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};
export default AppHeader;
