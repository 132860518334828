import './AddressBook.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import addressActions from '../../actions/addressActions';
import userActions from '../../actions/userActions';
import Spinner from '../../helpers/Spinner/Spinner';
import RadioBox from './RadioBox';
import { add } from 'lodash';

class AddressBookSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userId: '',
      addresses: [],
      addressSelection: '',
      metadata: {
        platform: 'web',
        version: process.env.REACT_APP_VERSION,
        name: '',
      },
      redirect: false,
      showCheckBoxes: false,
    };
  }

  async componentDidMount() {
    const { language } = global;
    const { user } = this.props;
    const { userId } = user;
    this.setState({
      userId,
    });
    try {
      const addresses = (await addressActions.getAddresses(userId)) || [];
      const activeAddress = await userActions.getUser(userId).then((r) => (r.metadata || {}).activeAddress);
      this.setState({
        addressSelection: activeAddress,
        addresses,
        activeAddress,
        showCheckBoxes: true,
        loading: false,
      });
      this.props.changeAddressId(activeAddress);
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  selectAddress = (addressId) => {
    this.setState({ addressSelection: addressId });
    this.props.changeAddressId(addressId);
  };

  render() {
    const { addresses, orderId, showCheckBoxes, addressSelection, redirect, loading } = this.state;
    if (redirect) {
      return <Redirect to={`/${global.language}/`} />;
    }
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="addressBook addressBookSelector">
        <div className="bookSelector">
          {showCheckBoxes && addresses.length === 0 && <>Please add your address.</>}
          {showCheckBoxes &&
            Object.entries(addresses).map(([key, address]) => (
              <RadioBox
                {...address}
                key={key}
                addressSelection={this.selectAddress}
                selectedAddress={addressSelection}
              />
            ))}
        </div>
        <Link
          className="addAddressBtn addAddress"
          to={{
            pathname: `/${global.language}/addressbook`,
            search: '?new=true',
          }}
        >
          <div className="circle">+</div>
          <span>Add address</span>
        </Link>
      </div>
    );
  }
}
AddressBookSelector.propTypes = {
  user: PropTypes.object.isRequired,
  changeAddressId: PropTypes.func.isRequired,
};
export default AddressBookSelector;
