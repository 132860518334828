import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

type Props = {
  // children: Element[];
  to: string;
  className?: string;
  onClick: () => void;
  children?: React.ReactNode; // This line allows passing children to the component
};

// Regular link just that it has global.language added to the path
const TranslatedLink = (props: Props) => {
  const { to, ...otherProps } = props;

  let trimmedToLink = to;

  if (trimmedToLink.startsWith('/')) {
    trimmedToLink = trimmedToLink.substr(1);
  }

  const translatedTo = `/${global.language}/${trimmedToLink}`;

  return <Link to={translatedTo} {...otherProps} />;
};

TranslatedLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default TranslatedLink;
