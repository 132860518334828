import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { map as _map } from 'lodash';
import { Element } from 'react-scroll';
import i18next from '../../services/i18n';
import Preview from './Preview/Preview';
import SelectSetButton from './SelectSetButton';

const MemoizedPreview = React.memo(Preview);
const MemoizedSelectSetButton = React.memo(SelectSetButton);

function ProductPackage({
  start,
  pictures: initialPictures,
  userId,
  orderId,
  previewMode,
  sku,
  loadPhotoSet,
  changeUploadingCount,
}) {
  const [month, setMonth] = useState(null);
  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    const date = new Date(start);
    const userLanguage = global.language;
    setMonth(date.toLocaleDateString(userLanguage, { month: 'long' }));
    setPictures(initialPictures);
  }, [start, initialPictures]);

  const renderProductType = useCallback(
    (template, pictures) => {
      return (
        <div className={`${template}-view`}>
          {pictures &&
            pictures.map((picture, idx) => (
              <div className="slide" key={idx}>
                <MemoizedPreview
                  photoNumber={idx}
                  userId={userId}
                  orderId={orderId}
                  orderPicture={picture}
                  templateId={picture.templateId}
                  disabled={previewMode}
                  changeUploadingCount={changeUploadingCount}
                />
              </div>
            ))}
        </div>
      );
    },
    [previewMode, userId, orderId, changeUploadingCount]
  );

  return (
    <div className="product-package">
      <div className="introMonthTitle">
        <h1>{month}</h1>
        {!previewMode && sku !== '901' && (
          <div className="photoSet">
            <p>{i18next.t('SelectSetButton.label.text')}</p>
            <MemoizedSelectSetButton currentSku={sku} userId={userId} orderId={orderId} loadPhotoSet={loadPhotoSet} />
          </div>
        )}
      </div>
      {pictures &&
        _map(pictures, (picturesByTemplate, template) => {
          return (
            <Element name={template} template={template} key={template}>
              <section className={`slide-${template}`}>
                <div className="section-header">
                  <h2>{i18next.t(`common:product.${template}.name`)}</h2>
                  {!previewMode && <small>{i18next.t(`common:product.${template}.description`)}</small>}
                </div>
                {renderProductType(template, picturesByTemplate)}
                <div className="border" />
              </section>
            </Element>
          );
        })}
    </div>
  );
}

ProductPackage.propTypes = {
  pictures: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  sku: PropTypes.string,
  previewMode: PropTypes.bool,
  loadPhotoSet: PropTypes.func,
  start: PropTypes.number.isRequired,
  changeUploadingCount: PropTypes.func,
};

ProductPackage.defaultProps = {
  previewMode: false,
  loadPhotoSet: null,
  changeUploadingCount: null,
  sku: '200',
};

export default React.memo(ProductPackage);
