export const templateSizes = (templateId) => {
  const sizes = {
    xl: {
      height: 152,
      width: 203,
    },
    medium: {
      height: 102,
      width: 152,
    },
    mini: {
      height: 102,
      width: 102,
    },
    strip: {
      height: 52,
      width: 52,
    },
    retroSmall: {
      height: 102,
      width: 82,
    },
  };

  return sizes[templateId];
};

// TODO: use Uri's + missmatch dimensions
const templates = {
  medium: {
    background: '#eee',
    dimension: {
      height: 103,
      width: 152,
    },
    objects: [
      {
        angle: 0,
        backgroundColor: '',
        excludeFromExport: true,
        fill: 'rgba(0,0,0,0)',
        fillRule: 'nonzero',
        flipX: false,
        flipY: false,
        globalCompositeOperation: 'source-over',
        height: 990,
        id: 'border',
        left: 0,
        opacity: 1,
        originX: 'left',
        originY: 'top',
        rx: 0,
        ry: 0,
        scaleX: 1,
        scaleY: 1,
        skewX: 0,
        skewY: 0,
        stroke: '#fff',
        strokeLineCap: 'butt',
        strokeLineJoin: 'miter',
        strokeMiterLimit: 10,
        strokeWidth: 30,
        top: 0,
        type: 'rect',
        visible: true,
        width: 1490,
      },
    ],
  },
  mini: {
    description:
      'Deze foto is 10x10cm. Wanneer je het kader van de foto wilt aanpassen kun je met behulp van je muis of je vingers de foto verplaatsen. ',
    name: "Jouw 3 Mini Foto's",
    background: '#eee',
    dimension: {
      height: 102,
      width: 102,
    },
    objects: [
      {
        angle: 0,
        backgroundColor: '',
        excludeFromExport: true,
        fill: 'rgba(0,0,0,0)',
        fillRule: 'nonzero',
        flipX: false,
        flipY: false,
        globalCompositeOperation: 'source-over',
        height: 990,
        id: 'border',
        left: 0,
        opacity: 1,
        originX: 'left',
        originY: 'top',
        rx: 0,
        ry: 0,
        scaleX: 1,
        scaleY: 1,
        skewX: 0,
        skewY: 0,
        stroke: '#fff',
        strokeLineCap: 'butt',
        strokeLineJoin: 'miter',
        strokeMiterLimit: 10,
        strokeWidth: 30,
        top: 0,
        type: 'rect',
        visible: true,
        width: 990,
      },
    ],
  },
  strip: {
    combineInContainerCanvas: true,
    container: {
      height: 1882.5,
      width: 627.5,
      objects: [
        {
          angle: 0,
          backgroundColor: '',
          excludeFromExport: true,
          fill: 'rgba(0,0,0,0)',
          fillRule: 'nonzero',
          flipX: false,
          flipY: false,
          globalCompositeOperation: 'source-over',
          height: 30,
          left: 0,
          opacity: 1,
          originX: 'left',
          originY: 'top',
          rx: 0,
          ry: 0,
          scaleX: 1,
          scaleY: 1,
          skewX: 0,
          skewY: 0,
          src: 'data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/7gAOQWRvYmUAZMAAAAAB/9sAhAAGBAQEBQQGBQUGCQYFBgkLCAYGCAsMCgoLCgoMEAwMDAwMDBAMDg8QDw4MExMUFBMTHBsbGxwfHx8fHx8fHx8fAQcHBw0MDRgQEBgaFREVGh8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx//wAARCAAeASwDAREAAhEBAxEB/8QAgwABAAICAwEAAAAAAAAAAAAAAAUGAgcBAwQIAQEAAgMBAAAAAAAAAAAAAAAABAUBAwYHEAABBAICAQMCAwkBAAAAAAABAAIDBBEFEgYhMRMHIhQjJBVBUWFxsTMldRYIEQEAAgECBQUBAAAAAAAAAAAAAQIDEQQxQVEFBvBh0RIiQv/aAAwDAQACEQMRAD8A+qUBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBBhBYgsRCWCRk0RJAkjcHNy0lrhkePBGCg4jsV5JJI45WPkhIbMxrgXMcQHAOA9CQc+UCexXrxGWxKyGJpAdJI4NaC4ho8nA8k4QdhIaCScAeST6AIMIJ4Z4WTQSNlhkAdHIwhzXNPkFrh4IQZoCAgICAgICAgICAgICAgICAgICAgICAgICAgICAg0Xv/nDtur7Ht6JpQNhgfJXqwyNcHxuY7DJXEH68jzj0/crnF2+lqROr0fZeI7XNt8d/tbWYiZmOfWPZsL4q7buOz9adf2kLY5453wtmY3iyVrQDyDST6E8Tjx4UHeYa476Vcv5H23Fs9x9MU6xNddOnriuSiKBqb4L7ZrZNJW6tSjkt3qs+2sbWWENMNEP2lkwR2HkjEs4JLI25dxHIgDBITXQfHe/kk+n+Up+R/qqyCud23ke5/wDO7Ny67Nbis/ps7796OCtK5o2cBMkzIT7MeAPPHwAgv2r7TS7N13Z7DXQy/prffhpXZA0R22RswZ6+CXOhLsta4gcsZH04JCqfAvbtbtOkaHSa5kloajT0m7HYx8TVitOib+T58suna36ntaCGeA4gnCCxdd7bSfS7XsLl+xNT0Ozuw2pLMMUYrx1YmSvjiEGTJExpy1z/AKz5z+xB4OhfK8PbtvY1w01rXhldl2rZe+GxE6CQjg2d1Z8ra1ghwd7Eh5cfKC+ICAgICAgICAgICAgICAgICAgICAgICAgICAgIKh3X4x0Ha7dS5b5V7Nd7RPLEAHTQj1ief6O9QpWDd2xxMQve1eQZ9lS1K/qtuET/ADPWPjmtNOnVpVYqlSJsFaBoZDEwYa1o9AAo1rTM6ypsuW2S02tOtp4y7JHlkbnhpeWgkMbjJwPQZx5WGtqbrPXtV1vY09lqOs9jr24fuxddw1/51l2d9jjcxMPc9iSQmFww5o8ZIJyE5qLrdXtt3s6/WN+6xv54rNxsjKRY18NdlZojAsAgcIgTknygiH6TWP8AjuDoUnWexv0sDIIw9zaBmcyvO2doeTN7ZDnMw76PIQSHX3RaGrs6Ou612CPWbGaSxHr+FH2ajpx+MyqBODHG9+X8MkNcTxwPCCP6dpdb0+evJo+s9hrxR0Iddare3rxFa+3/ALVqdrJmZsgZaZG45A/UDgYCR1X2evq72p/ye+tVexXLN7ZQ2GUnNLrcbYpY2hthv4Zaz0OT/FB4+mVpupMMNPWdsu66KBtWhrLf6c6vVhjcXNZC2OSJxPnjzkc5xGMlBs+GQyQskLHRl7Q4xvxybkZ4uwSMj+aDJAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEH//Z',
          strokeWidth: 0,
          top: 0,
          type: 'image',
          visible: true,
          width: 300,
        },
      ],
    },
    background: '#eee',
    dimension: {
      height: 52,
      width: 52,
    },
    objects: [
      {
        angle: 0,
        backgroundColor: '',
        excludeFromExport: true,
        fill: 'rgba(0,0,0,0)',
        fillRule: 'nonzero',
        flipX: false,
        flipY: false,
        globalCompositeOperation: 'source-over',
        height: 30,
        left: 0,
        opacity: 1,
        originX: 'left',
        originY: 'top',
        rx: 0,
        ry: 0,
        scaleX: 1,
        scaleY: 1,
        skewX: 0,
        skewY: 0,
        src: 'data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/7gAOQWRvYmUAZMAAAAAB/9sAhAAGBAQEBQQGBQUGCQYFBgkLCAYGCAsMCgoLCgoMEAwMDAwMDBAMDg8QDw4MExMUFBMTHBsbGxwfHx8fHx8fHx8fAQcHBw0MDRgQEBgaFREVGh8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx//wAARCAAeASwDAREAAhEBAxEB/8QAgwABAAICAwEAAAAAAAAAAAAAAAUGAgcBAwQIAQEAAgMBAAAAAAAAAAAAAAAABAUBAwYHEAABBAICAQMCAwkBAAAAAAABAAIDBBEFEgYhMRMHIhQjJBVBUWFxsTMldRYIEQEAAgECBQUBAAAAAAAAAAAAAQIDEQQxQVEFBvBh0RIiQv/aAAwDAQACEQMRAD8A+qUBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBBhBYgsRCWCRk0RJAkjcHNy0lrhkePBGCg4jsV5JJI45WPkhIbMxrgXMcQHAOA9CQc+UCexXrxGWxKyGJpAdJI4NaC4ho8nA8k4QdhIaCScAeST6AIMIJ4Z4WTQSNlhkAdHIwhzXNPkFrh4IQZoCAgICAgICAgICAgICAgICAgICAgICAgICAgICAg0Xv/nDtur7Ht6JpQNhgfJXqwyNcHxuY7DJXEH68jzj0/crnF2+lqROr0fZeI7XNt8d/tbWYiZmOfWPZsL4q7buOz9adf2kLY5453wtmY3iyVrQDyDST6E8Tjx4UHeYa476Vcv5H23Fs9x9MU6xNddOnriuSiKBqb4L7ZrZNJW6tSjkt3qs+2sbWWENMNEP2lkwR2HkjEs4JLI25dxHIgDBITXQfHe/kk+n+Up+R/qqyCud23ke5/wDO7Ny67Nbis/ps7796OCtK5o2cBMkzIT7MeAPPHwAgv2r7TS7N13Z7DXQy/prffhpXZA0R22RswZ6+CXOhLsta4gcsZH04JCqfAvbtbtOkaHSa5kloajT0m7HYx8TVitOib+T58suna36ntaCGeA4gnCCxdd7bSfS7XsLl+xNT0Ozuw2pLMMUYrx1YmSvjiEGTJExpy1z/AKz5z+xB4OhfK8PbtvY1w01rXhldl2rZe+GxE6CQjg2d1Z8ra1ghwd7Eh5cfKC+ICAgICAgICAgICAgICAgICAgICAgICAgICAgIKh3X4x0Ha7dS5b5V7Nd7RPLEAHTQj1ief6O9QpWDd2xxMQve1eQZ9lS1K/qtuET/ADPWPjmtNOnVpVYqlSJsFaBoZDEwYa1o9AAo1rTM6ypsuW2S02tOtp4y7JHlkbnhpeWgkMbjJwPQZx5WGtqbrPXtV1vY09lqOs9jr24fuxddw1/51l2d9jjcxMPc9iSQmFww5o8ZIJyE5qLrdXtt3s6/WN+6xv54rNxsjKRY18NdlZojAsAgcIgTknygiH6TWP8AjuDoUnWexv0sDIIw9zaBmcyvO2doeTN7ZDnMw76PIQSHX3RaGrs6Ou612CPWbGaSxHr+FH2ajpx+MyqBODHG9+X8MkNcTxwPCCP6dpdb0+evJo+s9hrxR0Iddare3rxFa+3/ALVqdrJmZsgZaZG45A/UDgYCR1X2evq72p/ye+tVexXLN7ZQ2GUnNLrcbYpY2hthv4Zaz0OT/FB4+mVpupMMNPWdsu66KBtWhrLf6c6vVhjcXNZC2OSJxPnjzkc5xGMlBs+GQyQskLHRl7Q4xvxybkZ4uwSMj+aDJAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEH//Z',
        strokeWidth: 0,
        top: 0,
        type: 'image',
        visible: true,
        width: 300,
      },
    ],
  },
  xl: {
    description: "Deze foto is 15x20cm. Houd tijdens het uploaden rekening met de kwaliteit van je foto's.",
    name: 'Jouw XL Foto',
    background: '#eee',
    dimension: {
      height: 153,
      width: 204,
    },
    objects: [
      {
        angle: 0,
        backgroundColor: '',
        excludeFromExport: true,
        fill: 'rgba(0,0,0,0)',
        fillRule: 'nonzero',
        flipX: false,
        flipY: false,
        globalCompositeOperation: 'source-over',
        height: 1490,
        id: 'border',
        left: 0,
        opacity: 1,
        originX: 'left',
        originY: 'top',
        rx: 0,
        ry: 0,
        scaleX: 1,
        scaleY: 1,
        skewX: 0,
        skewY: 0,
        stroke: '#fff',
        strokeLineCap: 'butt',
        strokeLineJoin: 'miter',
        strokeMiterLimit: 10,
        strokeWidth: 30,
        top: 0,
        type: 'rect',
        visible: true,
        width: 1990,
      },
    ],
  },
};

export const getTemplate = (templateId) => templates[templateId];

export const orderHasUnsupportedFormat = (order) => {
  if (!order || !order.pictures) {
    return false;
  }
  const notSupportedFormat = ['collage'];
  const pictures = Object.values(order.pictures);

  if (!Array.isArray(pictures)) {
    return false;
  }

  const picturesTemplates = pictures.filter((picture) => notSupportedFormat.includes(picture.templateId));

  return picturesTemplates.length > 0;
};
