import './SubmitSlide.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Element } from 'react-scroll';
import Arrow from '../../helpers/Arrow/Arrow';
import i18next from '../../services/i18n';
import paymentActions from '../../actions/paymentActions';
import { trackingClick } from '../../actions/trackingActions';
import orderActions from '../../actions/orderActions';

class SubmitSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderPaid: true,
    };
    this.sendButton = React.createRef();
    this.sendToNewAddressButton = React.createRef();
    this.sendOrder = this.sendOrder.bind(this);
    this.sendToNewAddress = this.sendToNewAddress.bind(this);
    this.canUpload = this.canUpload.bind(this);
    this.paymentIssuesContent = this.paymentIssuesContent.bind(this);
  }

  async componentDidMount() {
    try {
      const { orderId } = this.props;
      const id = orderId.includes('-') ? orderId.split('-')[0] : orderId;
      const { statusPayment } = await orderActions.getOrder(id);

      if (this.canUpload()) return;
    } catch (error) {
      console.error(error);
    }
  }

  async orderIsPaid() {
    const { orderId } = this.props;
    // member get member picture orders lack an order / payment
    if (orderId.startsWith('mgm')) {
      return true;
    }
    // pictureOrderId includes the order Id or is the order Id
    const id = orderId.split('-')[0];
    const { statusPayment } = await orderActions.getOrder(id);
    return statusPayment === 'fulfilled';
  }

  payDunningOrder() {
    const { orderId } = this.props;
    paymentActions.gotoCheckOut(orderId);
  }

  paymentIssuesContent() {
    return (
      <div className="flex-container">
        <h2>{i18next.t('SubmitSlide.paymentIssuesContent.h1.text')}</h2>
        <p>{i18next.t('SubmitSlide.paymentIssuesContent.body.text')}</p>
        <button type="button" className="button" onClick={this.payDunningOrder}>
          {i18next.t('SubmitSlide.paymentIssuesContent.profile.link.text')}
        </button>
      </div>
    );
  }

  sendOrder() {
    this.sendToNewAddress();
  }

  sendToNewAddress() {
    const { orderId, history } = this.props;
    trackingClick('current_order', 'share_order');
    history.push(`/${global.language}/addressbook/${orderId}`);
  }

  static uploadingContent() {
    return (
      <div className="flex-container">
        <Arrow up />
        <h2>{i18next.t('SubmitSlide.uploadingCurrent.title.text')}</h2>
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: i18next.t('SubmitSlide.uploadingCurrent.body.text'),
            }}
          />
        </p>
      </div>
    );
  }

  uploadReadyContent() {
    const { uploadingCount } = this.props;
    return (
      <div className="uploadFooter">
        <button
          id="sendOrderBt"
          type="button"
          className="button"
          disabled={uploadingCount > 0}
          ref={this.sendButton}
          onClick={this.sendOrder}
        >
          {i18next.t('SubmitSlide.send.button.text')}
        </button>
      </div>
    );
  }

  canUpload() {
    const { uploadedTenPictures } = this.props;
    return !!uploadedTenPictures;
  }

  render() {
    const canUpload = this.canUpload();
    const { orderPaid } = this.state;
    return (
      <Element name={canUpload ? 'intro' : 'last'}>
        <section className="submit-slide">
          <div className="flex-wrapper">
            {!canUpload && orderPaid
              ? SubmitSlide.uploadingContent()
              : orderPaid
                ? this.uploadReadyContent()
                : this.paymentIssuesContent()}
          </div>
          <div className="border" />
        </section>
      </Element>
    );
  }
}
SubmitSlide.propTypes = {
  orderId: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  uploadedTenPictures: PropTypes.bool.isRequired,
  uploadingCount: PropTypes.number.isRequired,
};

export default SubmitSlide;
