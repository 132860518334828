import i18next from '../services/i18n';

export default function translateError(error) {
  let translation = i18next.t(error);

  if (translation === error) {
    translation = error;
  }
  return translation;
}
