import React, { useMemo, useRef } from 'react';
import ProductPresentational from './ProductPresentational';
import { useTranslation } from 'react-i18next';
/**
 * Empty products to show while the content is loading
 */
// TODO: move this to localStorageActions
import ProductIconImage from '../../../img/icons/product_icon.png';
import CouponIconImage from '../../../img/icons/coupon_icon.png';
import PicturesIconImage from '../../../img/icons/pictures_icon.png';
import UnderlineImage from '../../../img/ShopTitleUnderscore.png';
import WallArtImage from '../../../img/icons/wall_icon.png';
import PostCardImage from '../../../img/icons/postcard_icon.png';
import { Product } from '../types';

const ProductsContainer = (props: { shopData: any }) => {
  const { shopData } = props;
  const categoryRefs = useRef<any>({});
  const trackedPage = 'products';
  const { t } = useTranslation();

  const handleCategorySelect = (categoryId: string) => {
    const categoryRef = categoryRefs.current[categoryId];
    if (categoryRef) {
      const offset = 60; // Adjust this value as needed for your layout
      const elementPosition = categoryRef.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  const renderCategories = useMemo(() => {
    return Object.entries(shopData).map(([key, category]) => {
      const { title } = category as { title: string };

      let image;
      switch (title) {
        case 'pictures': {
          image = PicturesIconImage;
          break;
        }
        case 'coupon': {
          image = CouponIconImage;
          break;
        }
        case 'product': {
          image = ProductIconImage;
          break;
        }
        case 'wall': {
          image = WallArtImage;
          break;
        }
        case 'card': {
          image = PostCardImage;
          break;
        }
        default:
          image = ProductIconImage;
          break;
      }
      return (
        <div className="category" key={key} onClick={() => handleCategorySelect(key)}>
          <div className="image-container">
            <img src={image} />
          </div>
          <div className="categoryTitle">
            <p className="title">{t(`shop:category.${title}.title`)}</p>
          </div>
        </div>
      );
    });
  }, [shopData]);

  const renderCategoryTitle = (title: string) => {
    return (
      <div className="categoryTitle">
        <p>{t(`shop:category.${title}.title`)}</p>
        <div className="underlineContainer">
          <img src={UnderlineImage} alt="category" className="underline" />
        </div>
      </div>
    );
  };

  const renderProductsList = (products: Product[]) => {
    return (
      <div className="products">
        {Object.entries(products).map(([index, product]) => (
          <ProductPresentational key={index} product={product} trackedPage={trackedPage} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="shop-categories-section">{renderCategories}</div>

      <div className="productsCategoryWrapper">
        {Object.entries(shopData).map(([key, category]) => {
          const { title, data: products } = category as { title: string; data: Product[] };
          return (
            <div key={key} ref={(el) => (categoryRefs.current[key] = el)}>
              {renderCategoryTitle(title)}
              {renderProductsList(products)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductsContainer;
