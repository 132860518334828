import React, { Component } from 'react';
import './CheckOut.scss';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import subscriptionActions from '../../actions/subscriptionActions';
import addressActions from '../../actions/addressActions';
import userActions from '../../actions/userActions';

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      pathLanguage: '',
      id: '',
      address: {},
      countryCode: '',
    };
  }

  // startDate
  componentDidMount = async () => {
    const {
      userId,
      match: {
        params: { id },
      },
    } = this.props;
    const subscription = await subscriptionActions.getSubscription(userId);
    const {
      profile: { countryCode },
      metadata: { activeAddress },
    } = await userActions.getUser(userId);
    const address = await addressActions.getAddress(userId, activeAddress);
    const startDate = await this.getStartDate(subscription.monthlyRecurrenceDay);
    this.setState({
      startDate,
      id,
      address,
      countryCode,
    });
  };

  getStartDate = (monthlyRecurrenceDay) => {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth(); // Note: January is 0, December is 11
    let day = monthlyRecurrenceDay;

    // Check if the day has already passed in the current month
    if (today.getDate() > monthlyRecurrenceDay) {
      month++; // Move to the next month
      if (month > 11) {
        month = 0; // Reset to January
        year++; // Increment the year
      }
    }

    // Adjust the day if it's more than the last day of the month
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    if (day > lastDayOfMonth) {
      day = lastDayOfMonth;
    }

    // Construct the next subscription date
    const nextSubscriptionDate = new Date(year, month, day);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return nextSubscriptionDate.toLocaleDateString(this.countryCode, options);
  };

  render() {
    const { startDate, id, address } = this.state;
    return (
      <div className="checkOut">
        <img
          src="https://media.giphy.com/media/R6gvnAxj2ISzJdbA63/giphy-downsized-large.gif"
          alt="thank you"
          className="gifAnimation"
        />
        <h2>{i18next.t('paymentSuccess.title')}</h2>
        <p>
          {i18next.t('paymentSuccess.text', { finalPrepaidUntil: startDate })}
          <br />
          <br />
          {id === '3' && ` ${i18next.t('paymentSuccess.deliveryAddress')}`}
        </p>
        {id === '3' && (
          <div className="successAddress">
            <p>{address.fullname}</p>
            <p>{`${address.address} ${address.address2 || ''}`}</p>
            <p>
              {address.zipcode} {address.city}
            </p>
          </div>
        )}
        <Link to={`/${global.language}/`} className="buttonHolder">
          <button type="button" className="button">
            {i18next.t('paymentSucces.backButton')}
          </button>
        </Link>
        <small>{i18next.t('paymentSuccess.disclaimer')}</small>
      </div>
    );
  }
}
Success.propTypes = {
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
export default Success;
