import './Spinner.scss';
import React from 'react';
import LottiePlayer from 'lottie-react';
import animation from './SpinnerAnimation.json';

const Spinner = () => (
  <div className="spinner">
    <div className="animation">
      <LottiePlayer animationData={animation} loop />
    </div>
  </div>
);
export default Spinner;
