import { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';
import { Font, fontsList } from '../../BitmapEditor/fonts';
import { ILimitedIText } from '../../BitmapEditor/objects/BitmapEditor.TextObject';


const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};
export const FontMenu = ({ textObject }: { textObject: ILimitedIText }) => {
  const [selectedFont, setSelectedFont] = useState<Font['name']>(textObject.fontFamily || '');
  const fontListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!textObject.fontFamily) return;
    setSelectedFont(textObject.fontFamily);
  }, [textObject.fontFamily]);

  const fixPosition = useCallback(() => {
    if(isDesktop) return;
    const fontListWrapper = fontListRef.current;
    if (fontListWrapper) {
      const vv = window.visualViewport;
      if (vv) {
        let scrollY = window.scrollY || window.pageYOffset;
        scrollY = Math.min(scrollY, 370);
        fontListWrapper.style.top = `${vv.height + scrollY}px`;
      }
    }
  }, []);

  const debouncedFixPosition = useCallback(debounce(fixPosition, 100), [fixPosition]);

  useEffect(() => {
    const vv = window.visualViewport;
    if (vv) {
      vv.addEventListener('resize', debouncedFixPosition);
      window.addEventListener('scroll', debouncedFixPosition, { passive: true });
      debouncedFixPosition();

      return () => {
        vv.removeEventListener('resize', debouncedFixPosition);
        window.removeEventListener('scroll', debouncedFixPosition);
      };
    }
  }, [debouncedFixPosition]);

  const handleFontClick = (fontName: Font['name']) => {
    setSelectedFont(fontName);
    textObject.changeFont(fontName);
  };

  const deviceClass = classNames('font-list-wrapper', {
    ios: isIOS,
    android: isAndroid,
    desktop: isDesktop
  });

  return (
    <div className={deviceClass} ref={fontListRef}>
      {fontsList.map((font) => (
        <div
          onClick={() => handleFontClick(font.name)}
          className="font-list-item-wrapper"
          key={font.name}
        >
          <div className={`font-list-item ${font.name === selectedFont ? 'active' : ''}`} style={{ fontFamily: font.name, fontSize: 12 * font.resize }}>
            {font.name}
          </div>
        </div>
      ))}
    </div>
  );
};