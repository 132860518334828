import { DateTimeFormat } from 'intl';

/**
 * Get long month based on locale
 *
 * 'January' for locale:'en', monthIndex: 0
 *
 * @param locale
 * @param monthIndex
 */
export const getLocaleLongMonth = (monthIndex, locale) => {
  const objDate = new Date().setMonth(monthIndex);
  const intlDateFormat = new DateTimeFormat(locale, { month: 'long' });

  return intlDateFormat.format(objDate);
};

/**
 * Get long month based on locale
 *
 * 'January' for locale:'en', monthIndex: 0
 *
 * @param locale
 * @param monthIndex
 */
export const getLocaleLongMonthFromTimestamp = (timestamp, locale) => {
  const objDate = new Date(timestamp);
  const intlDateFormat = new DateTimeFormat(locale, { month: 'long' });

  return intlDateFormat.format(objDate);
};

/**
 * Takes a timestamp and an optional locale string, and returns the date formatted for that locale
 *
 * rawTimestamp: 1546853398, locale: "en" => "07 Junuary 2019"
 *
 * @param rawTimestamp
 * @param locale
 */
export const getLocaleFormattedDate = (rawTimestamp, locale = 'nl') => {
  const timestamp = Number(rawTimestamp);
  const date = new Date(timestamp);

  const dayIndex = date.getDate();
  const monthIndex = date.getMonth();
  const yearIndex = date.getFullYear();

  const monthString = getLocaleLongMonth(monthIndex, locale);

  return `${dayIndex} ${monthString} ${yearIndex}`;
};

/**
 * Calculates the date for a package
 * @param {*} startDate
 * @param {*} months
 * @returns date with added months
 */
export const addMonthsToDate = (startDate, months) => {
  const date = new Date(startDate); // create a Date object from the timestamp
  const numberOfMonthsToAdd = months || '1'; // number of months to add to the date

  date.setMonth(date.getMonth() + numberOfMonthsToAdd); // add the number of months to the date
  return date.getTime();
};
