import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import i18next from '../../services/i18n';
import Notify from './Notify';
import './AddToHomeScreen.scss';

class AddToHomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInstallMessage: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    if (localStorage.getItem('showAddToHomeScreen') === 'false') {
      return;
    }
    // Detects if device is on iOS
    // TODO: check if `window` is ok to solve windows not deffined error
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ showInstallMessage: true });
      this.timer = setTimeout(this.clickHandler.bind(this), 7000);
    }
  }

  clickHandler() {
    this.setState({ showInstallMessage: false });
    clearTimeout(this.timer);
    localStorage.setItem('showAddToHomeScreen', 'false');
  }

  render() {
    const { authenticated } = this.props;
    const { showInstallMessage } = this.state;
    return (
      <div onClick={this.clickHandler.bind(this)} role="presentation">
        {authenticated && showInstallMessage && (
          <Notify
            text={[
              i18next.t('AddToHomeScreen.line1.text'),
              <br key="break" />,
              i18next.t('AddToHomeScreen.line2.text'),
              <span key="notify-icon-1" className="icon ion-ios-upload-outline icon-ios" />,
              i18next.t('AddToHomeScreen.line3.text'),
              i18next.t('AddToHomeScreen.line4.text'),
            ]}
          />
        )}
      </div>
    );
  }
}

AddToHomeScreen.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

export default AddToHomeScreen;
