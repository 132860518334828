import React from 'react';
import Lottie from 'lottie-react';
import animation from './klikkie.json';

const LogoAnimation = () => (
  <div className="saveAnimation">
    <Lottie animationData={animation} loop={false} />
  </div>
);
export default LogoAnimation;
