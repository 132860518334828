import fetch from 'isomorphic-fetch';

// require('es6-promise').polyfill();
// import { request } from 'https';

export const buildRequestObject = (requestObj) => {
  const { method, headers, body, cache } = requestObj;

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const fetchQueryObj = {};

  //  method
  fetchQueryObj.method = typeof method === 'undefined' ? 'get' : method;

  // headers
  fetchQueryObj.headers = typeof headers === 'undefined' ? defaultHeaders : { ...defaultHeaders, ...headers };

  //  body
  if (typeof body !== 'undefined') {
    if (body instanceof FormData) {
      fetchQueryObj.body = body;
      delete fetchQueryObj.headers['Content-Type'];
    } else {
      fetchQueryObj.body = JSON.stringify(body);
      fetchQueryObj.headers['Content-Type'] = 'application/json';
    }
  }

  // cache
  if (typeof cache !== 'undefined') {
    fetchQueryObj.cache = cache;
  }

  return fetchQueryObj;
};

export default async (url, requestObj = {}) => {
  const request = Object.assign({}, requestObj);
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  request.headers = Object.assign({}, defaultHeaders, requestObj.headers);

  try {
    const response = await fetch(url, request);
    if (response.status >= 400) {
      throw new Error('Bad response from server');
    }
    if (response.status === 204) {
      return null;
    }
    const data = await response.json();
    return data.data || data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
