import React, { Component } from 'react';
import IonIcon from '@reacticons/ionicons';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import userActions from '../../actions/userActions';

class DeleteMyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteReady: false,
      value: i18next.t('app_screens:DeleteUserInfo_delete').toUpperCase(),
    };
  }
  deleteUser = async () => {
    const { userId, history } = this.props;
    if (this.state.deleteReady) {
      console.log(userId);
      try {
        await userActions.deleteUser(userId);
        history.push(`/${global.language}/login`);
      } catch (error) {
        console.error(error);
      }
    }
  };
  handleUserInput = (event) => {
    const { name, value } = event.target;
    const compareValue = value.toUpperCase();

    this.setState({ deleteReady: compareValue === this.state.value });
  };
  gotoPauseHandler = () => {
    const { userId, history } = this.props;

    history.push(`/${global.language}/subscription/`);
  };
  render() {
    const { value, deleteReady } = this.state;
    return (
      <div className="profile deleteAccount">
        <div className="details">
          <div className="detailsSection">
            <h2 className="iconHeader">{i18next.t('app_screens:DeleteUserInfo_data_removal_title')}</h2>
            <p>
              {i18next.t('app_screens:DeleteUserInfo_data_removal_body', {
                value,
              })}
            </p>
          </div>
          <div className="detailsSection">
            <p>{i18next.t('app_screens:DeleteUserInfo_data_subscription_pause_reminder')}</p>
            <button className="button" onClick={this.gotoPauseHandler}>
              {' '}
              {i18next.t('app_screens:DeleteUserInfo_textPuseAccount')}
            </button>
          </div>
        </div>
        <div className="details">
          <div className="detailsSection">
            <fieldset>
              <label htmlFor="">{i18next.t('app_screens:DeleteUserInfo_textTypeDelete', { value })}</label>
              <input
                type="text"
                name="deleteInput"
                id="deleteInput"
                className="deleteInput"
                onChange={this.handleUserInput}
              />
            </fieldset>

            <button className="deleteLink" onClick={this.deleteUser}>
              {' '}
              {i18next.t('app_screens:DeleteUserInfo_textDeleteMembership')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
DeleteMyAccount.propTypes = {
  userId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
export default DeleteMyAccount;
