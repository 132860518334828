import './Login.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import i18next from '../services/i18n';
import { getLinksfromLocize } from '../config';
import { login, logout, socialSignIn } from '../actions/authActions';
import loginImage from '../img/login.jpg';
import CircleLogoAnimation from '../helpers/LogoAnimation/CircleLogoAnimation';
import { trackingClick } from '../actions/trackingActions';

function loginButtonHandler() {
  trackingClick('account', 'login');
}
class Login extends Component {
  constructor(props) {
    super(props);
    this.onFocus = this.onFocus.bind(this);
    this.authWithEmailPassword = this.authWithEmailPassword.bind(this);
    this.state = {};
  }

  onFocus() {
    this.setState({
      message: '',
    });
  }

  socialSignIn2 = async (event, provider) => {
    event.preventDefault();
    const { setCurrentUser, history } = this.props;

    try {
      const user = await socialSignIn(provider);
      setCurrentUser(user, (profileLanguage, error) => {
        if (error) {
          this.setState({ message: error.message });
          return logout();
        }
        this.loginForm.reset();
        history.push(
          `/${profileLanguage}${this.state.redirect && '/' + this.state.redirect}${
            this.state.search && '/' + this.state.search
          }`
        );
      });
    } catch (error) {
      trackingClick('Error', error);
      this.setState({ message: error.message });
    }
  };

  resetPasswordButtonHandler = () => {
    const _this = this;
    trackingClick('account', 'forgot_password');
  };

  registerAccountButtonHandler = () => {
    const _this = this;
    trackingClick('account', 'become_member');
  };
  componentDidMount() {
    const that = this;
    const search = (location && location.search) || '';
    const searchParams = new URLSearchParams(search);
    this.setState({ redirect: searchParams.get('redirect') || '', search });
  }

  async authWithEmailPassword(event) {
    event.preventDefault();
    try {
      const email = this.emailInput.value;
      const password = this.passwordInput.value;
      const { setCurrentUser, history } = this.props;

      const { user } = await login(email, password);
      if (!user || !user.email) return false;
      setCurrentUser(user, (profileLanguage, error) => {
        if (error) {
          this.setState({ message: error.message });
          return logout();
        }
        this.loginForm.reset();
        history.push(
          `/${profileLanguage}${this.state.redirect && '/' + this.state.redirect}${
            this.state.search && '/' + this.state.search
          }`
        );
      });
    } catch (error) {
      trackingClick('Error', error);

      this.setState({ message: error.message });
    }
    return event;
  }

  render() {
    const { message } = this.state;
    return (
      <div className="login-wrapper">
        <div className="imageContainer">
          <img src={loginImage} alt="" />{' '}
        </div>
        <div className="login-container">
          <Link to={`/${global.language}/`} className="logo center">
            <CircleLogoAnimation />
          </Link>
          <h2>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: i18next.t('Login.body.text') }}
            />
          </h2>
          <button className="button lined google" onClick={(e) => this.socialSignIn2(e, 'google')}>
            google sign in
          </button>
          <button className="button lined apple" onClick={(e) => this.socialSignIn2(e, 'apple')}>
            Apple sign in
          </button>
          <form
            onSubmit={(event) => this.authWithEmailPassword(event)}
            ref={(form) => {
              this.loginForm = form;
            }}
          >
            <div className="hr">
              <p> or</p>
              <div></div>
            </div>
            <fieldset>
              <label htmlFor="loginEmail">{i18next.t('Login.email.placeholder')}</label>
              <input
                id="loginEmail"
                name="email"
                type="email"
                ref={(input) => {
                  this.emailInput = input;
                }}
                onFocus={this.onFocus.bind(this)}
                placeholder={i18next.t('Login.email.placeholder')}
              />
            </fieldset>
            <fieldset>
              <label htmlFor="loginPassword">{i18next.t('Login.password.placeholder')}</label>
              <input
                id="loginPassword"
                name="password"
                type="password"
                ref={(input) => {
                  this.passwordInput = input;
                }}
                onFocus={this.onFocus.bind(this)}
                placeholder={i18next.t('Login.password.placeholder')}
              />
              {message && <p className="errorMessage">{message}</p>}
            </fieldset>
            <fieldset>
              <input
                id="loginSubmit"
                type="submit"
                className="button"
                onClick={loginButtonHandler}
                value={i18next.t('Login.button.text')}
              />
            </fieldset>
          </form>
          <Link to={`/${global.language}/reset`} onClick={this.resetPasswordButtonHandler}>
            {i18next.t('Login.reset.button.text')}
          </Link>
          <a
            href={getLinksfromLocize().site}
            rel="noopener noreferrer"
            target="_blank"
            onClick={this.registerAccountButtonHandler}
          >
            {i18next.t('Login.noAccount.button.text')}
          </a>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};
export default Login;
