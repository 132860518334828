import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export const firebase = Firebase.initializeApp(config);

export const auth = firebase.auth();
export const googleProvider = new Firebase.auth.GoogleAuthProvider();
export const appleProvider = new Firebase.auth.OAuthProvider('apple.com');

export const database = firebase.database();
export const storage = firebase.storage();
// export const analytics = firebase.analytics(); // TODO: Setup firebase analytics
