import { apiBaseUrl, paymentsApiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

const API_URL = `${apiBaseUrl}/payments`;
export default {
  /* TODO: migrate to klikkie payments */
  orderProductWithRecurrentDetails(userId, packageId, addressId) {
    console.log(userId, packageId, addressId);
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          packageId,
          addressId,
        }),
      };
      return fetch(`${API_URL}/${userId}/order`, requestBody);
    });
  },
  async getUserPayment(orderId) {
    const token = await getUserBearer();
    const headers = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${paymentsApiBaseUrl}/payments/order/${orderId}`, headers);
  },
  // send to checkout
  gotoCheckOut(orderId) {
    const env = process.env.REACT_APP_ENV === 'development' ? 'env=dev' : '';
    const url = `https://www.klikkie.com/membership/checkout?id=${orderId}&${env}`;
    window.location.replace(url);
    /* for testing purposes
     window.open(url, '_blank');
     */
    return null;
  },
};
