import React from 'react';
import PropTypes from 'prop-types';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { matomoInstance } from './matomoInstance';

const Matomo = ({ children }) => <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>;

Matomo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Matomo;
