import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from '../../services/i18n';

class Checkbox extends Component {
  // Checkbox functions
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { defaultCheck, addAddress, addressId } = this.props;
    if (defaultCheck === addressId) {
      this.setState({ checked: true });
      addAddress(addressId);
    }
  }

  handleChange = (value, checked) => {
    const { addAddress, removeAddress } = this.props;

    if (checked) {
      this.setState({ checked: true });
      addAddress(value);
    } else {
      this.setState({ checked: false });
      removeAddress(value);
    }
  };

  clickHandler = (e) => {
    this.handleChange(e.target.value, e.target.checked);
  };

  render() {
    const { addressId, fullname, address, address2, zipcode, city, price, addressSelection } = this.props;

    // let regionNames = new Intl.DisplayNames(["en"], { type: "region" });

    const { checked } = this.state;
    return (
      <div className="checkBoxWrapper">
        <label className={`addressCheckBox ${checked}`} htmlFor={addressId}>
          <input
            type="checkbox"
            id={addressId}
            value={addressId}
            onChange={this.clickHandler}
            defaultChecked={checked}
          />
          <div className="addressDetails">
            <div className="name">{fullname}</div>
            <div className="address">{address}</div>
            <div className="address2">{address2}</div>
            <div className="zipcode">{zipcode}</div>
            <div className="city">{city}</div>
            {/* <div className="country">{regionNames.of(countryCode)}</div> */}
            <div className="price">
              {addressId === addressSelection[0] ? i18next.t('AddressBook.text.free') : price}
            </div>
          </div>
        </label>
      </div>
    );
  }
}
Checkbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  addressSelection: PropTypes.array.isRequired,
  addAddress: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
  addressId: PropTypes.string.isRequired,
  defaultCheck: PropTypes.string.isRequired,
  fullname: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  address2: PropTypes.string,
  zipcode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};
Checkbox.defaultProps = {
  address2: '',
};
export default Checkbox;
