import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import './OrderHistory.scss';
import moment from 'moment';
import Spinner from '../../helpers/Spinner/Spinner';
import useProduct from '../../utils/hooks/useProduct';
import { formatPrice } from '../../utils/CurrencyUtils';
import useOrder from '../../utils/hooks/useOrder';
import addressActions from '../../actions/addressActions';
import Invoice from '../Invoices/Invoice';
import { useTranslation } from 'react-i18next';
import orderActions from '../../actions/orderActions';
import i18n from '../../services/i18n';

const OrderDetails = (props: { userId: string; orderId: string; history: any }) => {
  const { userId, orderId } = props;
  const { order, error, isLoading } = useOrder(props.orderId);
  const { products, error: errProduct, isLoading: isLoadingProduct } = useProduct();
  const [address, setAddress] = useState<any | undefined>();
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [paymentMethodUsed, setPaymentMethodUsed] = useState('');
  const { t } = useTranslation();

  const getPaymentMethod = async () => {
    const paymentOrder = await orderActions.getPaymentsByOrderId(orderId);
    if (paymentOrder) {
      const { paymentMethod } = paymentOrder;
      if (paymentMethod) {
        setPaymentMethodUsed(paymentMethod ? paymentMethod : '');
      }
    }
  };

  useEffect(() => {
    getPaymentMethod();
  }, [orderId]);

  const orderDetails = useMemo(() => {
    if (!order) return null;
    const { createdAt } = order as any;

    return (
      <div className="order-header">
        <div className="order-date">{moment(createdAt).format('DD MMMM YYYY')}</div>
        <div>{t('shop:OrderStatus.paymentWith.label', { paymentMethod: paymentMethodUsed })}</div>
      </div>
    );
  }, [paymentMethodUsed, order]);

  useEffect(() => {
    if (!userId || !order || !(order as any).addressId) return;
    const { addressId } = order;
    addressActions
      .getAddress(userId, addressId)
      .then((res) => {
        setAddress(res);
      })
      .catch((e) => console.log);
  }, [userId, order]);

  const getFirstImage = (sku: any) => {
    if (!products || !products[sku] || !products[sku].imageSrc) return null;

    const image = products[sku].imageSrc;
    if (typeof image === 'string') {
      return image;
    } else if (Array.isArray(image) && image.length > 0) {
      return image[0];
    } else {
      return null;
    }
  };

  const renderInvoiceModal = () => {
    return (
      <div className="invoiceLayout">
        <div className="buttons">
          <div className="close" onClick={() => setSelectedOrderData(null)} role="button" tabIndex={0}>
            &times;
          </div>
          <div className="button fixed" onClick={(window as any).downloadAsPdf} role="button" tabIndex={0}>
            {t('Invoices.invoice.download')}
          </div>
        </div>
        <Invoice order={selectedOrderData} />
      </div>
    );
  };

  const goToSmartSupport = () => {
    props.history.push(`/${global.language}/orderHistory/KickSmartSupport/${orderId}`);
  };

  if (error) return <>we have an error, refresh the page or contact support</>;
  if (isLoading || isLoadingProduct || !order) return <Spinner />;
  if (selectedOrderData) return renderInvoiceModal();

  const {
    products: orderProducts,
    createdAt,
    id,
    totalShipping,
    totalToCharge,
    currency,
    totalDiscount,
    totalVoucher,
  } = order as any;

  const renderLineItem = (product: any) => {
    const { sku, total, units } = product;
    const formattedPrice = formatPrice(total, currency, 'currency');

    if (sku === '0') return null;
    let description = '';
    if (i18n.exists(`products:${sku}.shortDescription`)) {
      description = t(`products:${sku}.shortDescription`);
    }

    return (
      <div className="line-item">
        <div className="product-image">
          <img className="product-image-item" src={getFirstImage(sku) || '/img/product-fallback.png'} />
        </div>
        <div className="product-item">
          <div className="product-name">
            {units}x {t(`products:${sku}.title`)}
          </div>

          <div className="product-description">{description}</div>
        </div>
        <div className="product-price">
          <div className="price">{formattedPrice}</div>
        </div>
      </div>
    );
  };

  const renderOrderLineItems = () => {
    return (
      <div className="order-line-items">
        {orderProducts.length > 0 &&
          orderProducts.map((product: any, index: number) => <div key={index}>{renderLineItem(product)}</div>)}
      </div>
    );
  };

  const renderShippingLineItem = () => {
    const formattedPrice = formatPrice(totalShipping, currency, 'currency');
    return (
      <div className="shipping-cost-row">
        <span>
          <strong>{t('shop:OrderStatus.totalShipping.label')}</strong>
        </span>
        <span>
          <strong>
            <div className="price">{formattedPrice}</div>
          </strong>
        </span>
      </div>
    );
  };

  const renderTotalCostLineItem = () => {
    const formattedPrice = formatPrice(totalToCharge, currency, 'currency');
    return (
      <div className="total-cost-row">
        <span>
          <h2 className="total">{t('shop:OrderStatus.totalCost.label')}</h2>
        </span>
        <span>
          <strong>
            <h2 className="price">{formattedPrice}</h2>
          </strong>
        </span>
      </div>
    );
  };

  const renderShippingAddressLineItem = () => {
    if (!address) return null;
    return (
      <div className="shipping-wrapper">
        <div className="shipping-header">{t('SmartSupport.shippedTo')}:</div>
        <div className="address-box">
          {address.address}
          <br />
          {address.city}
          <br />
          {address.countryCode}
          <br />
          {address.zipcode}
          <br />
        </div>
      </div>
    );
  };

  const renderDiscountLineItem = () => {
    const discountValue = totalDiscount + totalVoucher;

    if (discountValue <= 0) return null;

    const formattedPrice = formatPrice(discountValue, currency, 'currency');
    return (
      <div className="shipping-cost-row">
        <span>
          <strong>{t('shop:OrderStatus.discount.label')}</strong>
        </span>
        <span>
          <strong>
            <div className="price">- {formattedPrice}</div>
          </strong>
        </span>
      </div>
    );
  };

  const renderSupportButton = () => {
    return (
      <span style={{ width: '100%' }}>
        <p
          className="bold"
          dangerouslySetInnerHTML={{
            __html: t('shop:OrderStatus.questionsAboutOrder'),
          }}
        />
        <button
          id="confirmBuyLink"
          className="button buyButton mt-0 mb-2"
          type="button"
          disabled={false}
          onClick={goToSmartSupport}
        >
          {t('shop:OrderStatus.contactUs.label')}
        </button>
      </span>
    );
  };

  const renderDownloadInvoiceButton = () => {
    return (
      <span style={{ width: '100%' }}>
        <button
          id="confirmBuyLink"
          className="button buyButton mt-0 mb-2"
          type="button"
          disabled={false}
          onClick={() => setSelectedOrderData(order)}
        >
          {t('shop:OrderStatus.button.DownloadInvoice.label')}
        </button>
      </span>
    );
  };

  return (
    <section className="orderHistory">
      <div className="flex-wrapper">
        <div className="flex-container order-details">
          <h2 className="title">
            {t('SmartSupport.MyOrders.title.text')} {props.orderId}
          </h2>
          {orderDetails}

          {renderOrderLineItems()}

          {renderShippingLineItem()}
          {renderDiscountLineItem()}
          {renderTotalCostLineItem()}

          {renderShippingAddressLineItem()}

          {renderSupportButton()}
          {renderDownloadInvoiceButton()}
        </div>
      </div>
    </section>
  );
};
OrderDetails.propTypes = {
  userId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
export default OrderDetails;
