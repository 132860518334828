import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './styles.css';
import heic2any from 'heic2any';
import fileTypeChecker from 'file-type-checker';
import { trackingClick } from '../../actions/trackingActions';
import { useTranslation } from 'react-i18next';
import Portal from './Portal';
import ModalQualityInfo from '../ProductPackage/ModalQualityInfo';

interface CropDemoProps {
  src: string;
  width: number;
  height: number;
  cropModalVisible: boolean;
  setCropModalVisible: (value: boolean) => any;
  onCropComplete: (croppedImage: string) => void;
}

export default function Cropper({
  src,
  width,
  height,
  cropModalVisible,
  setCropModalVisible,
  onCropComplete,
}: CropDemoProps) {
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | PercentCrop | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [myNewImage, setMyNewImage] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string>(src);
  const [loading, setLoading] = useState<boolean>(false);
  const [showQualityMessage, setShowQualityMessage] = useState(false);
  const [showModalQualityInfo, setShowModalQualityInfo] = useState(false)

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const convertHeicToJpeg = async (src: string) => {
      const base64Data = src.split(',')[1];
      const binaryString = atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const detectedType = fileTypeChecker.detectFile(bytes);

      if (detectedType && detectedType.extension === 'heic') {
        try {
          const blob = new Blob([bytes], { type: 'image/heic' });
          const convertedBlob = await heic2any({
            blob,
            toType: 'image/jpeg',
          });

          let url;
          if (Array.isArray(convertedBlob)) {
            url = URL.createObjectURL(convertedBlob[0]);
          } else {
            url = URL.createObjectURL(convertedBlob);
          }

          setImageSrc(url);
        } catch (error) {
          console.error('Error converting HEIC to JPEG:', error);
        }
      } else {
        setImageSrc(src);
      }
      setLoading(false);
    };

    convertHeicToJpeg(src);
  }, [src]);

  const checkImageResolution = (img: HTMLImageElement, canvasWidth: number, canvasHeight: number, zoomRatio = 1) => {
    const minResolution = 0.25;
    const minWidth = canvasWidth * minResolution;
    const minHeight = canvasHeight * minResolution;
  
    const naturalWidth = img.naturalWidth;
    const naturalHeight = img.naturalHeight;
  
    const lowResolution = naturalWidth < minWidth || naturalHeight < minHeight;
  
    const zoomedImageWidth = img.width / zoomRatio;
    const zoomedImageHeight = img.height / zoomRatio;
  
    const properResolution = zoomedImageWidth >= minWidth && zoomedImageHeight >= minHeight;
  
    return { lowResolution, properResolution };
  };

  const onImageLoaded = useCallback(
    (img: HTMLImageElement) => {
      imageRef.current = img;

      const aspect = width / height;
      const displayedWidth = img.width;
      const displayedHeight = img.height;

      const { lowResolution } = checkImageResolution(img, width, height);
      setShowQualityMessage(lowResolution);

      let cropWidth, cropHeight;

      if (displayedWidth / aspect <= displayedHeight) {
        cropWidth = displayedWidth;
        cropHeight = displayedWidth / aspect;
        console.log('v1');
      } else {
        cropHeight = displayedHeight;
        cropWidth = displayedHeight * aspect;
      }

      const initialCrop: PixelCrop = {
        unit: 'px',
        width: cropWidth,
        height: cropHeight,
        x: (displayedWidth - cropWidth) / 2,
        y: (displayedHeight - cropHeight) / 2,
      };

      setCompletedCrop(initialCrop);
      setCrop({
        unit: '%',
        width: (cropWidth / displayedWidth) * 100,
        height: (cropHeight / displayedHeight) * 100,
        x: (initialCrop.x / displayedWidth) * 100,
        y: (initialCrop.y / displayedHeight) * 100,
      });
    },
    [width, height]
  );

  const onCropCompleteHandler = useCallback((crop: PixelCrop) => {
    setCompletedCrop(crop);
  }, []);

  const getCroppedImage = async () => {
    if (!completedCrop || !imageRef.current || !canvasRef.current) {
      console.error('Missing required elements', {
        completedCrop,
        imageRef: imageRef.current,
        canvasRef: canvasRef.current,
      });
      return;
    }

    const MAX_CANVAS_AREA = 16777216;

    const image = imageRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }

    const pixelRatio = window.devicePixelRatio;
    let cropWidth = crop.width * scaleX;
    let cropHeight = crop.height * scaleY;

    const canvasArea = cropWidth * cropHeight * pixelRatio * pixelRatio;
    if (canvasArea > MAX_CANVAS_AREA) {
      const scalingFactor = Math.sqrt(MAX_CANVAS_AREA / canvasArea);
      cropWidth *= scalingFactor;
      cropHeight *= scalingFactor;
    }

    canvas.width = cropWidth * pixelRatio;
    canvas.height = cropHeight * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    await new Promise((resolve) => setTimeout(resolve, 100));

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      cropWidth,
      cropHeight
    );

    return new Promise<string>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const fileUrl = URL.createObjectURL(blob);
          resolve(fileUrl);
        } else {
          console.error('Canvas is empty, blob is null');
          reject(new Error('Canvas is empty'));
        }
      }, 'image/jpeg');
    });
  };

  const handleCrop = async () => {
    try {
      const croppedImageUrl = await getCroppedImage();
      if (croppedImageUrl) {
        onCropComplete(croppedImageUrl);
        setMyNewImage(croppedImageUrl);
      }
      trackingClick('bitmapEditor', 'cropComplete');
    } catch (error) {
      console.error('Error cropping image', error);
    }
    closeModal();
  };

  const closeModal = () => {
    setCropModalVisible(false);
    trackingClick('bitmapEditor', 'closeCropModal');
  };

  return (
    <div>
      {cropModalVisible && (
        <Portal>
            <div className="modal modal-cropper">
              <div className="modal-content">
                {showQualityMessage && (
                  <div className="qualityMessage">
                    <div className="close-button" onClick={() => {
                      setShowQualityMessage(false);
                    }}>
                      &times;
                    </div>
                    <p className="u-m0">
                      {t('PhotoEditor.moreInformation.body.text')}
                      <button className="u-pointer" onClick={() => {
                        setShowModalQualityInfo(true);
                      }} >
                        {' '}
                        {t('PhotoEditor.moreInformation.button.text')}{' '}
                      </button>
                    </p>
                  </div>
                )}
                <ModalQualityInfo open={showModalQualityInfo} onClose={() => {
                      setShowModalQualityInfo(false);
                }} />
                <div className="modal-header modal-header-cropper">
                  <button className="back-btn" onClick={closeModal}></button>
                  <p>{t('BitmapEditor.cropper.title')}</p>
                  <button className="btn" onClick={handleCrop}>
                    {t('BitmapEditor.cropper.save')}
                  </button>
                </div>
                {loading && (
                  <div className="loading-wrapper">
                    <p>{t('BitmapEditor.cropper.wait')}</p>
                  </div>
                )}
                {!loading && (
                  <div className="cropper-wrapper">
                    <ReactCrop
                      aspect={width / height}
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      onComplete={onCropCompleteHandler}
                    >
                      <img
                        ref={imageRef}
                        src={imageSrc}
                        onLoad={(e) => onImageLoaded(e.currentTarget)}
                        className="crop-image"
                        crossOrigin="anonymous"
                      />
                    </ReactCrop>
                  </div>
                )}
            </div>
          </div>
        </Portal>
      )}
      {myNewImage && <img src={myNewImage} alt="Cropped" />}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
}
