import './Subscription.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import checkmarkIcon from '../../img/icons/checkmark.png';

const SubscriptionBenefits = () => {
  const { t } = useTranslation();

  return (
    <div className="SubscriptionBenefitsContainer">
      <div>
        <h2>{t('app_manageSubscription:Subscription.membershipBenefits.title')}</h2>
      </div>
      <div className="lineItem">
        <div className="iconImage">
          <img src={checkmarkIcon} />
        </div>
        <div>
          <p>{t('app_manageSubscription:Subscription.membershipBenefits.benefits_1')}</p>
        </div>
      </div>
      <div className="lineItem">
        <div className="iconImage">
          <img src={checkmarkIcon} />
        </div>
        <div>
          <p>{t('app_manageSubscription:Subscription.membershipBenefits.benefits_2')}</p>
        </div>
      </div>
      <div className="lineItem">
        <div className="iconImage">
          <img src={checkmarkIcon} />
        </div>
        <div>
          <p>{t('app_manageSubscription:Subscription.membershipBenefits.benefits_3')}</p>
        </div>
      </div>
      <div className="lineItem">
        <div className="iconImage">
          <img src={checkmarkIcon} />
        </div>
        <div>
          <p>{t('app_manageSubscription:Subscription.membershipBenefits.benefits_4')}</p>
        </div>
      </div>
      <div className="lineItem">
        <div className="iconImage">
          <img src={checkmarkIcon} />
        </div>
        <div>
          <p>{t('app_manageSubscription:Subscription.membershipBenefits.benefits_5')}</p>
        </div>
      </div>
      <div className="lineItem">
        <div className="iconImage">
          <img src={checkmarkIcon} />
        </div>
        <div>
          <p>{t('app_manageSubscription:Subscription.membershipBenefits.benefits_6')}</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBenefits;
