import './PauseMembership.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import subscriptionActions from '../../actions/subscriptionActions';
import { ISubscriptionPauseComplete } from '@klikkie/klikkie-schemas/lib/interfaces/Subscription';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CancelReason } from '../Cancel/CancelSubscription';

interface Props {
  userId: string;
  reason: CancelReason | undefined;
  pauseSuccessHandler: (pauseDuration: number) => void;
}

interface PauseDialogWithDuration {
  show: boolean;
  duration: string | undefined;
}

const PauseMembership = (props: Props) => {
  const { pauseSuccessHandler, userId, reason } = props;
  const { t } = useTranslation();
  const [selectedPauseDuration, setPauseDuration] = useState<string | undefined>(undefined);
  const [showPauseDialog, setShowPauseDialog] = useState<PauseDialogWithDuration>({ show: false, duration: undefined });

  const pauseHandler = async () => {
    let pauseDuration;

    if (selectedPauseDuration) {
      pauseDuration = parseInt(selectedPauseDuration, 10);
    }

    if (pauseDuration) {
      try {
        const pauseBody: ISubscriptionPauseComplete = {
          reason: reason || '',
          comment: '',
          pauseDuration,
        };
        await subscriptionActions.pauseSubscription(userId, pauseBody);
        await pauseSuccessHandler(pauseDuration);
      } catch (error) {
        console.log(error);
        // TODO: handle if something goes wrong
      }
    }
  };

  const pauseButtonHandler = () => {
    let pauseDuration;

    if (selectedPauseDuration) {
      pauseDuration = parseInt(selectedPauseDuration, 10);
      setShowPauseDialog({ show: true, duration: selectedPauseDuration });
    }
  };

  const onOptionChange = (e: any) => {
    const { id } = e.target;
    setPauseDuration(id);
  };

  const handleDialogClose = () => {
    setShowPauseDialog({ show: false, duration: undefined });
  };

  return (
    <div className="pauseContainer">
      <div>
        <h2> {t('app_manageSubscription:PauseCard.title')}</h2>
      </div>

      <div>
        <p>{t('app_manageSubscription:PauseCard.description')}</p>
      </div>

      <div>
        <div className="selectionOption" onClick={onOptionChange}>
          <input type="radio" name="pauseDuration" value={30} id="30" checked={selectedPauseDuration == '30'} />
          <label id="30" htmlFor="regular">
            {t('app_manageSubscription:PauseCard.duration.30_days')}
          </label>
        </div>

        <div className="selectionOption" onClick={onOptionChange}>
          <input type="radio" name="pauseDuration" value={60} id="60" checked={selectedPauseDuration == '60'} />
          <label id="60" htmlFor="medium">
            {t('app_manageSubscription:PauseCard.duration.60_days')}
          </label>
        </div>

        <div className="selectionOption" onClick={onOptionChange}>
          <input type="radio" name="pauseDuration" value={90} id="90" checked={selectedPauseDuration == '90'} />
          <label id="90" htmlFor="medium">
            {t('app_manageSubscription:PauseCard.duration.90_days')}
          </label>
        </div>
      </div>

      <button
        id="sendOrderBt"
        className="button lined"
        type="submit"
        onClick={pauseButtonHandler}
        disabled={!selectedPauseDuration}
      >
        {t('app_manageSubscription:PauseCard.pause.button')}
      </button>

      <Dialog
        open={showPauseDialog.show}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('app_alerts:Subscription.Pause.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('app_alerts:Subscription.Pause.message', { pauseDuration: showPauseDialog.duration })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('app_alerts:Common.cancel.button')}
          </Button>
          <Button onClick={pauseHandler}>{t('app_alerts:Subscription.Pause.confirm.button')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PauseMembership;
