import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from '../../services/i18n';
import orderActions from '../../actions/orderActions';
import './OrderHistory.scss';
import moment from 'moment';
import Spinner from '../../helpers/Spinner/Spinner';
import useProduct from '../../utils/hooks/useProduct';
import { formatPrice } from '../../utils/CurrencyUtils';
import { Link } from 'react-router-dom';
import Invoice from '../Invoices/Invoice';
import { useTranslation } from 'react-i18next';
import i18n from '../../services/i18n';

const OrderHistory = (props: any) => {
  const { userId } = props;
  const [orders, setOrders] = useState<any[]>([]);
  const { products, error, isLoading } = useProduct();
  const [selectedOrderData, setSelectedOrderData] = useState<any>(null);
  const { t } = useTranslation();

  const getFirstImage = (sku: any) => {
    if (products && products[sku] && products[sku].imageSrc) {
      const image = products[sku].imageSrc;
      if (typeof image === 'string') {
        return image;
      } else if (Array.isArray(image) && image.length > 0) {
        return image[0];
      } else {
        return '/img/product-fallback.png';
      }
    }

    return '/img/product-fallback.png';
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await orderActions.getOrders(userId);
        const filteredOrders = res
          .filter((order: any) => order.status === 'fulfilled')
          .filter((order: any) => {
            // Filter out orders that are only for a payment method update
            if (order.products.length === 1 && order.products[0].sku === '0') {
              return;
            }

            return order;
          })
          .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setOrders(filteredOrders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrders();
  }, [userId]);

  const renderInvoiceModal = () => {
    return (
      <div className="invoiceLayout">
        <div className="buttons">
          <div className="close" onClick={() => setSelectedOrderData(null)} role="button" tabIndex={0}>
            &times;
          </div>
          <div className="button fixed" onClick={(window as any).downloadAsPdf} role="button" tabIndex={0}>
            {t('Invoices.invoice.download')}
          </div>
        </div>
        <Invoice order={selectedOrderData} />
      </div>
    );
  };

  if (error) return <>we have an error, refresh the page or contact support</>;
  if (isLoading || !orders) return <Spinner />;
  if (selectedOrderData) return renderInvoiceModal();

  const renderProductItem = ({ order, product }: { order: any; product: any }) => {
    const { sku, total, units } = product;
    const { currency, status } = order;
    const formattedPrice = formatPrice(total, currency, 'currency');

    if (sku === '0') return null;

    let description = '';
    if (i18n.exists(`products:${sku}.shortDescription`)) {
      description = t(`products:${sku}.shortDescription`);
    }

    return (
      <div style={{ display: 'flex', textAlign: 'start' }}>
        <div className="product-image">
          <img className="product-image-item" src={getFirstImage(sku)} />
        </div>
        <div className="product-item">
          <div className="product-name">
            {units}x {t(`products:${sku}.title`)}
          </div>
          <div className="product-description">{description}</div>
          <div className="product-status">{status === 'fulfilled' ? i18next.t('SmartSupport.paid') : status}</div>
        </div>
        <div className="product-price">
          <p className="price">{formattedPrice}</p>
        </div>
      </div>
    );
  };

  return (
    <section className="orderHistory">
      <div className="flex-wrapper">
        <div className="flex-container">
          <h2 className="title">{t('SmartSupport.YourOrders.title.text')}</h2>
          <ul className="order-list">
            {orders &&
              orders.map((order: { id: string; products: [any]; createdAt: any }) => (
                <li key={order.id} className="order-item">
                  <div className="order-header">
                    <div className="order-date">{moment(order.createdAt).format('DD MMMM YYYY')}</div>
                    <div className="order-id pointer" onClick={() => setSelectedOrderData(order)}>
                      {`Order ${order.id}`}
                    </div>
                  </div>
                  <Link to={`/${global.language}/orderDetails/${order.id}`}>
                    <div className="order-detail">
                      {order.products.length > 0 &&
                        order.products.map((product: any, index: React.Key | null | undefined) => (
                          <div key={index}>{renderProductItem({ order, product })}</div>
                        ))}
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
OrderHistory.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default OrderHistory;
