import React, { Component } from 'react';
import IonIcon from '@reacticons/ionicons';
import './Profile.scss';
import SaveAnimation from '../../helpers/Spinner/SaveAnimation';
import userActions from '../../actions/userActions';
import { logout } from '../../actions/authActions';
import i18next from '../../services/i18n';

class ChangePassword extends Component {
  state = {
    password: '',
    saving: false,
  };

  validateField = (fieldName, value) => this.true;

  updateAuth = (e) => {
    e.preventDefault();
    this.setState({ saving: true });
    const { password } = this.state;
    const update = { password };
    userActions
      .updateAuth(update)
      .then(() => {
        this.setState({ saving: false });
      })
      .then(() => logout());
  };

  handleUserInput = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  render() {
    const { saving, password } = this.state;
    return (
      <form onSubmit={this.updateAuth}>
        <fieldset>
          <h2 className="iconHeader">
            <IonIcon className="ion-icon" name="lock-closed" />
            {i18next.t('myDetails.changePassword.text')}
          </h2>
          {saving && <SaveAnimation />}
          <p>{i18next.t('myDetails.changePassword.description')}</p>
          <label htmlFor="password">{i18next.t('myDetails.changePassword.label')}</label>
          <div className="input-wrapper">
            <input
              onChange={this.handleUserInput}
              name="password"
              id="password"
              className="name"
              type="password"
              value={password}
              required
            />
            {/* <IonIcon name="checkmark" className="done" /> */}
          </div>
          <div className="message">{i18next.t('SendToNewAddress.incomplete.text')}</div>
        </fieldset>
        <fieldset className="buttonContainer">
          <button id="sendOrderBt" className="button lined  " type="submit">
            {i18next.t('myDetails.button')}
          </button>
        </fieldset>
      </form>
    );
  }
}
export default ChangePassword;
