import React, { Component } from 'react';
import './Profile.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import productsActions from '../../actions/productsActions';
import userActions from '../../actions/userActions';
import { formatPrice } from '../../utils/CurrencyUtils';
import IonIcon from '@reacticons/ionicons';

const Arrow = () => (
  <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.923 12.4326C27.5088 11.8469 27.5088 10.8971 26.923 10.3113L17.377 0.76538C16.7912 0.179593 15.8415 0.179593 15.2557 0.76538C14.6699 1.35117 14.6699 2.30091 15.2557 2.8867L23.741 11.372L15.2557 19.8573C14.6699 20.443 14.6699 21.3928 15.2557 21.9786C15.8415 22.5644 16.7912 22.5644 17.377 21.9786L26.923 12.4326ZM0.194642 12.872L25.8623 12.872L25.8623 9.87198L0.194642 9.87198L0.194642 12.872Z"
      fill="white"
    />
  </svg>
);
class SubscriptionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { packageIds: [3, 4, 7], products: [] };
  }

  componentDidMount = async () => {
    try {
      const { userId } = this.props;
      const { packageIds } = this.state;
      const {
        profile: { countryCode },
      } = await userActions.getUser(userId);
      const product = await productsActions.getProducts();
      const products = {};
      packageIds.forEach((value) =>
        product[value].activeCountry[countryCode]
          ? (products[value] = formatPrice(product[value].price[countryCode], product[value].currency[countryCode]))
          : ''
      );

      this.setState({ products });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { products } = this.state;
    return (
      <span>
        <div className="details">
          <div className="detailsSection">
            <h2 className="iconHeader">
              <IonIcon name="heart-sharp" className="ion-icon" />
              {i18next.t('renewMembership.title')}
            </h2>
            <p> {i18next.t('renewMembership.text')}</p>
          </div>
          <div className="packages">
            {products[3] && (
              <Link className="package" to={{ pathname: `/${global.language}/checkout/3` }}>
                <div className="gradient" />
                <div className="banner">{i18next.t('renewMembership.package3.banner')}</div>
                <div className="packageContent">
                  <div className="packageHeader">
                    <h4>{i18next.t('renewMembership.package3.title')}</h4>
                    <p dangerouslySetInnerHTML={{ __html: i18next.t('renewMembership.package3.text') }} />
                  </div>
                  <div className="packageFooter">
                    <div className="priceWrapper">
                      <div className="strikethrough">142,95</div>
                      <div className="price">{products[3]}</div>
                    </div>
                    <div className="arrow">{Arrow()}</div>
                  </div>
                </div>
              </Link>
            )}
            <Link className="package color-1" to={{ pathname: `/${global.language}/checkout/7` }}>
              <div className="gradient" />
              <div className="banner"> {i18next.t('renewMembership.package7.banner')}</div>
              <div className="packageContent">
                <div className="packageHeader">
                  <h4>{i18next.t('renewMembership.package7.title')}</h4>
                  <p dangerouslySetInnerHTML={{ __html: i18next.t('renewMembership.package7.text') }} />
                </div>
                <div className="packageFooter">
                  <div className="priceWrapper">
                    <div className="strikethrough">107,40</div>
                    <div className="price">{products[7]}</div>
                  </div>
                  <div className="arrow">{Arrow()}</div>
                </div>
              </div>
            </Link>
            <Link className="package color-2" to={{ pathname: `/${global.language}/checkout/4` }}>
              <div className="gradient" />
              <div className="banner"> {i18next.t('renewMembership.package4.banner')}</div>
              <div className="packageContent">
                <div className="packageHeader">
                  <h4>{i18next.t('renewMembership.package4.title')}</h4>
                  <p dangerouslySetInnerHTML={{ __html: i18next.t('renewMembership.package4.text') }} />
                </div>
                <div className="packageFooter">
                  <div className="priceWrapper">
                    <div className="strikethrough">53,70</div>
                    <div className="price">{products[4]}</div>
                  </div>
                  <div className="arrow">{Arrow()}</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </span>
    );
  }
}
SubscriptionDetails.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default SubscriptionDetails;
