import './ErrorSlide.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import image from '../img/darnit.png';
import i18next from '../services/i18n';
import { trackingClick } from '../actions/trackingActions';

class ErrorSlide extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { message, location } = this.props;

    if (location.state) {
      this.setState({
        message: location.state.message || 'onbekend',
      });
    }
    trackingClick('Error Slide - no Active Order', `error: ${message}`);
  }

  render() {
    const { message } = this.state;
    return (
      <section className="fallback-slide">
        <div className="flex-wrapper">
          <div className="flex-container">
            <img src={image} alt="oops" />
            <h2>{i18next.t('ErrorSlide.title.text')}</h2>
            <p>
              {i18next.t('ErrorSlide.body.text')}
              <br />
            </p>
            {message && (
              <React.Fragment>
                {' '}
                {i18next.t('ErrorSlide.error.text')}
                <br />
                <div className="error-code"> {message}</div>
              </React.Fragment>
            )}
            <p>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: i18next.t('ErrorSlide.contact.text'),
                }}
              />
            </p>
          </div>
        </div>
      </section>
    );
  }
}

ErrorSlide.propTypes = {
  message: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default ErrorSlide;
