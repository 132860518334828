import { useEffect, useState } from 'react';
import orderActions from '../../actions/orderActions';

const useOrder = (orderId) => {
  const [order, setOrder] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    orderActions
      .getOrder(orderId)
      .then((res) => {
        setOrder(res);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  }, []);

  return { order, error, isLoading };
};

export default useOrder;
