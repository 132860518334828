import { orderApiBaseUrl, paymentsApiBaseUrl } from '../config';
import fetch from '../services/fetch';

import { getUserBearer } from './authActions';

const ORDER_URL = `${orderApiBaseUrl}`;
const KLIKKIE_PAYMENTS_URL = paymentsApiBaseUrl;
const userAgent = {
  source: 'klikkie-webapp',
  version: process.env.REACT_APP_VERSION,
};
export default {
  async getOrders(userId) {
    const token = await getUserBearer();
    const headers = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${ORDER_URL}/user/${userId}`, headers);
  },
  async getOrder(orderId) {
    const token = await getUserBearer();
    const headers = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${ORDER_URL}/${orderId}`, headers);
  },
  async createOrder(userId, countryCode, sku, addresses) {
    const orderRequest = {
      userId,
      countryCode,
      addressId: addresses.toString(),
      userAgent,
      products: {
        [sku]: 1,
      },
    };

    const token = await getUserBearer();
    const requestBody = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(orderRequest),
    };
    return fetch(`${ORDER_URL}`, requestBody);
  },
  async createReprintsOrder(userId, countryCode, sku, addresses, orderId) {
    const firstAddress = addresses.shift();

    let metadata = {
      reprint: [{ addresses, sku, printId: orderId }],
    };
    if (!Object.keys(addresses).length) {
      metadata = {};
    }
    console.info(metadata);
    const orderRequest = {
      userId,
      countryCode,
      addressId: firstAddress,
      metadata,
      userAgent,
      products: {
        [sku]: 1,
      },
    };

    const token = await getUserBearer();
    const requestBody = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(orderRequest),
    };
    return fetch(`${ORDER_URL}`, requestBody);
  },
  async getPaymentsByOrderId(orderId) {
    const token = await getUserBearer();
    const path = `${KLIKKIE_PAYMENTS_URL}/payments/order/${orderId}`;
    const headers = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(path, headers);
  },
};
