import React, { lazy, Suspense, Component } from 'react';
import PropTypes from 'prop-types';
import * as Utils from '../../../utils/Utils';
import { uploadImage } from '../../../config';
import { templateSizes } from '../../../utils/PictureTemplateUtils';
import './Preview.scss';
import i18next from '../../../services/i18n';
import storageActions from '../../../actions/storageActions';
import userPicturesActions from '../../../actions/userPicturesActions';
import Spinner from '../../../helpers/Spinner/Spinner';
import '../products.scss';

const PictureEditor = lazy(() => import('../PictureEditor/PictureEditor'));
const BACKGROUND_IMAGE = 'data:picture/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='; // this is transparent 1px gif to fix weird border in chrome
// TODO: comment with author
const areEqualShallow = (a, b) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};
class Preview extends Component {
  constructor(props) {
    super(props);
    this.pictureEditor = React.createRef();
    this.state = {
      borderOnCanvas: false,
      editor: false,
      borderWidth: 2,
      orientation: 'landscape',
      uploadLogoImage: uploadImage,
      picture: BACKGROUND_IMAGE,
      thumbnail: BACKGROUND_IMAGE,
      uploadStatus: false,
      uploadPercentage: undefined,
      timeCreated: undefined,
      uploadMessage: true,
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.scaleImageToWindow = this.scaleImageToWindow.bind(this);
    this.uploadStatus = this.uploadStatus.bind(this);
  }

  async UNSAFE_componentWillMount() {
    // console.info('Preview.componentWillMount() props -->', this.props);
    const { orderPicture } = this.props;
    // debugger;
    await this.getThumbnail(orderPicture);
    await this.getPicture(orderPicture);
    this.scaleImageToWindow();
  }

  componentDidMount() {
    // scaling the window on resize
    this.scaleImageToWindow();
    window.addEventListener('resize', this.scaleImageToWindow);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!areEqualShallow(nextState, this.state)) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.scaleImageToWindow);
    this.pictureEditor = undefined;
  }

  onImgLoad(picture) {
    const { uploadMessage } = this.state;
    if (picture !== BACKGROUND_IMAGE && uploadMessage === true) {
      this.setState({ uploadMessage: false });
    }
    // const img = { ...picture };
    const img = new Image();
    // console.info('Imagen ---------------------------------');
    // console.info(img);
    // console.info('Imagen ---------------------------------');
    img.crossOrigin = 'Anonymous';
    img.src = picture;
    img.onload = () => {
      const orientation = img.width >= img.height ? 'landscape' : 'portrait';
      this.setState(
        {
          orientation,
          borderOnCanvas: false,
          picture,
        },
        () => {
          this.scaleImageToWindow();
        }
      );
    };
  }

  async getThumbnail(picture) {
    try {
      if (!picture.thumbnailRef) return null;
      const thumbnailRef = await storageActions.getPictureByRef(picture.thumbnailRef);
      return this.setState(
        () => ({
          thumbnail: thumbnailRef,
        }),
        () => {
          this.onImgLoad(thumbnailRef);
        }
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getPicture(pictureOrderPicture) {
    try {
      const { userId } = this.props;
      const { renderedRef, userPictureId } = pictureOrderPicture;
      if (!renderedRef && !userPictureId) {
        return null;
      }
      let pictureRef = renderedRef;
      if (!renderedRef || renderedRef === '') {
        const userPicture = await userPicturesActions.getUserPicture(userId, userPictureId);
        pictureRef = userPicture.originalRef;
      }
      const picture = await storageActions.getPictureByRef(pictureRef);
      this.onImgLoad(picture);
      return this.setState({
        picture,
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  clickHandler() {
    const { disabled } = this.props;
    const { uploadPercentage, editor } = this.state;
    if (disabled || uploadPercentage !== undefined) {
      return;
    }
    window.document.body.style.overflow = 'visible';
    this.setState({
      editor: !editor,
      averageColor: undefined,
    });
  }

  scaleImageToWindow() {
    const { templateId } = this.props;
    if (!this.pictureEditor) return;
    if (!this.pictureEditor.current || !templateId) {
      return;
    }
    const { width } = templateSizes(templateId);
    const borderRatio = this.pictureEditor.current.offsetWidth / Utils.fromMm2PX(width);
    const borderSize = templateId === 'strip' ? 2 : 3;
    this.setState({
      borderWidth: Math.round(Utils.fromMm2PX(borderSize) * borderRatio, 0),
    });
  }

  uploadStatus(status, value, callback) {
    const { changeUploadingCount } = this.props;
    const { status: stateStatus } = this.state;
    const newState = { uploadStatus: status };
    if (status === 'uploading' && stateStatus !== 'uploading' && stateStatus === 'thumbnail') {
      this.setState({
        picture: BACKGROUND_IMAGE,
        thumbnail: undefined,
      });
      return;
    }
    if (status === 'uploading') {
      newState.uploadPercentage = parseInt(value, 10);
    }
    if (status === 'thumbnail') {
      delete newState.uploadStatus;
      newState.showMessage = false;
      newState.thumbnail = value;
      this.onImgLoad(value);
      changeUploadingCount(1);
    }
    if (status === 'done') {
      newState.uploadPercentage = undefined;
      changeUploadingCount(-1);
    }
    if (status === 'error') {
      this.setState({
        picture: BACKGROUND_IMAGE,
        thumbnail: undefined,
        uploadPercentage: undefined,
        retryHandler: callback,
        uploadMessage: true,
      });
      this.onImgLoad(BACKGROUND_IMAGE);
      // eslint-disable-next-line no-alert
      alert('Uploading was not successful, please check your internet connection or reload the page.');
      changeUploadingCount(-1);
      return;
    }
    this.setState(newState);
  }

  render() {
    const { userId, orderId, orderPicture, disabled } = this.props;
    const { editor, picture, thumbnail, orientation, borderWidth, uploadPercentage, uploadMessage, uploadLogoImage } =
      this.state;
    return (
      <span>
        {editor && (
          <div id="photo-container">
            <Suspense fallback={<Spinner />}>
              <PictureEditor
                {...this.props}
                orderId={orderId}
                userId={userId}
                close={this.clickHandler}
                templateId={orderPicture.templateId}
                pictureId={orderPicture.pictureOrderPictureId}
                picture={orderPicture}
                uploadStatus={this.uploadStatus}
                thumbnailData={thumbnail}
                pictureData={picture}
                disabled
              />
            </Suspense>
          </div>
        )}
        <span>
          {!editor && (
            <div
              className={`thumbnail ${orderPicture.templateId !== 'strip' && 'pictureShadow'} ${
                disabled && 'disabled'
              }`}
              onClick={this.clickHandler}
            >
              <div className="bg">
                {uploadMessage && (
                  <div className="uploadMessage">
                    <img src={uploadLogoImage} alt="" />
                    <p>{i18next.t('Preview.upload.text')}</p>
                  </div>
                )}
                <img
                  alt={picture}
                  src={picture}
                  ref={this.pictureEditor}
                  className={`picture-wrapper ${orientation}`}
                  style={{ border: `${borderWidth}px #fff solid` }}
                />
                <div
                  className="loading-container"
                  style={{
                    height: uploadPercentage !== undefined ? '4px' : '0px',
                    width: `${uploadPercentage}%`,
                  }}
                />
              </div>
            </div>
          )}
        </span>
      </span>
    );
  }
}
Preview.propTypes = {
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  templateId: PropTypes.string.isRequired,
  orderPicture: PropTypes.shape({
    pictureOrderPictureId: PropTypes.string,
    templateId: PropTypes.string,
  }).isRequired,
  changeUploadingCount: PropTypes.func,
};
export default Preview;
