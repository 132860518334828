import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

export type CancelReason =
  | 'expensive'
  | 'not_enough_photos'
  | 'was_gift'
  | 'quality_of_service'
  | 'technical_difficulties'
  | 'too_many_months'
  | 'other';

const CancelSubscription = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [cancelReason, setCancelReason] = useState<string | undefined>(undefined);
  const [cancelReasonId, setCancelReasonId] = useState<CancelReason | undefined>(undefined);
  const [otherText, setOtherText] = useState<string | undefined>(undefined);
  const [redirect, setRedirect] = useState<boolean>(false);

  const onOptionChange = (e: any) => {
    const { id, value } = e.target;
    setCancelReasonId(id);
    setCancelReason(value);
  };

  const continueHandler = () => {
    // return history.push({
    //   pathname: `/${global.language}/cancel-offers`,
    //   search: `?reason=${cancelReasonId}`,
    //   state: {
    //     cancelReason: cancelReasonId,
    //   },
    // });
    setRedirect(true);
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/${global.language}/cancel-offers`,
          search: `?reason=${cancelReasonId}`,
        }}
      />
    );
  }

  return (
    <div className="profile">
      <div className="details">
        <div className="cancelReasonsContainer">
          <h2>{t('app_manageSubscription:CancelReasons.screen.title')}</h2>
          <p>{t('app_manageSubscription:CancelReasons.title')}</p>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.expensive')}
              id="expensive"
              checked={cancelReasonId == 'expensive'}
            />
            <label id="expensive" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.expensive')}
            </label>
          </div>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.not_enough_photos')}
              id="not_enough_photos"
              checked={cancelReasonId == 'not_enough_photos'}
            />
            <label id="not_enough_photos" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.not_enough_photos')}
            </label>
          </div>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.was_gift')}
              id="was_gift"
              checked={cancelReasonId == 'was_gift'}
            />
            <label id="was_gift" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.was_gift')}
            </label>
          </div>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.quality_of_service')}
              id="quality_of_service"
              checked={cancelReasonId == 'quality_of_service'}
            />
            <label id="quality_of_service" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.quality_of_service')}
            </label>
          </div>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.technical_difficulties')}
              id="technical_difficulties"
              checked={cancelReasonId == 'technical_difficulties'}
            />
            <label id="technical_difficulties" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.technical_difficulties')}
            </label>
          </div>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.too_many_months')}
              id="too_many_months"
              checked={cancelReasonId == 'too_many_months'}
            />
            <label id="too_many_months" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.too_many_months')}
            </label>
          </div>

          <div className="selectionOption" onClick={onOptionChange}>
            <input
              type="radio"
              name="pauseDuration"
              value={t('app_manageSubscription:CancelReasons.reasons.other')}
              id="other"
              checked={cancelReasonId == 'other'}
            />
            <label id="other" htmlFor="regular">
              {t('app_manageSubscription:CancelReasons.reasons.other')}
            </label>
          </div>

          {cancelReasonId == 'other' && (
            <fieldset>
              <label htmlFor="additionalComments">
                {t('app_manageSubscription:CancelReasons.additionalComments.label')}
              </label>
              <div className="input-wrapper">
                <input
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setOtherText(value);
                  }}
                  name="additionalComments"
                  id="additionalComments"
                  className="name"
                  type="text"
                  value={otherText}
                  placeholder={t('app_manageSubscription:CancelReasons.additionalComments.placeholder')}
                />
              </div>
              <div className="message">{t('SendToNewAddress.incomplete.text')}</div>
            </fieldset>
          )}

          {cancelReasonId && (
            <button id="sendOrderBt" className="button lined" type="submit" onClick={continueHandler}>
              {t('app_manageSubscription:CancelReasons.continue.button')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
