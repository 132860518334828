import './CancelOffers.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Offer_PhotoStrip from '../CancelOffers/Offer_PhotoStrip';
import Offer_5Photos from '../CancelOffers/Offer_5Photos';
import Offer_1Photo from '../CancelOffers/Offer_1Photo';
import { CancelReason } from './CancelSubscription';

interface Props {
  reason: CancelReason | undefined;
}

const CancelReasonResolution = (props: Props) => {
  const { reason } = props;
  const { t } = useTranslation();

  const [showComingSoonState, setShowComingSoonState] = useState<boolean>(false);

  const offerSelectHandler = () => {
    console.log('offerSelectHandler');
    setShowComingSoonState(true);
  };

  const renderOffers = () => {
    if (showComingSoonState) {
      return (
        <div className="cancelOfferComingSoonContainer">
          <h2>{t('app_manageSubscription:ComingSoon.title')}</h2>
          <p>{t('app_manageSubscription:ComingSoon.subtitle')}</p>
        </div>
      );
    }
    return (
      <div className="cancelOffersContainer">
        <Offer_PhotoStrip selectHandler={offerSelectHandler} />
        <Offer_5Photos selectHandler={offerSelectHandler} />
        <Offer_1Photo selectHandler={offerSelectHandler} />
      </div>
    );
  };

  switch (reason) {
    case 'expensive':
    case 'too_many_months':
    case 'not_enough_photos':
      return (
        <div style={{ display: 'contents' }}>
          <div className="cancelOffersTitle">
            <h2>{t('app_manageSubscription:CancelReasonResolution.expensive.title')}</h2>
            <p>{t('app_manageSubscription:CancelOffers.subtitle')}</p>
          </div>
          {renderOffers()}
        </div>
      );
    case 'quality_of_service':
    case 'technical_difficulties':
    // return (
    //   <div style={{ display: 'contents' }}>
    //     <div className="cancelOffersTitle">
    //       <h2>{t('app_manageSubscription:CancelReasonResolution.technical_difficulties.title')}</h2>
    //     </div>

    //     <div className="cancelOffersTitle">
    //       <p>{t('app_manageSubscription:CancelReasonResolution.technical_difficulties.subtitle')}</p>
    //     </div>
    //   </div>
    // );
    default:
      return (
        <div style={{ display: 'contents' }}>
          <div className="cancelOffersTitle">
            <h2>{t('app_manageSubscription:CancelReasonResolution.default.title')}</h2>
          </div>
        </div>
      );
  }
};

export default CancelReasonResolution;
