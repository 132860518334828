import './Login.scss';

import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import i18next from '../services/i18n';
import translateError from './translateError';
import { resetPassword } from '../actions/authActions';
import { trackingClick } from '../actions/trackingActions';

class ResetPassword extends PureComponent {
  state = {
    message: '',
  };

  handleClick(event) {
    event.preventDefault();
    const c = this;
    const email = this.emailInput.value;
    trackingClick('account', 'reset_password');

    // TODO: check if `window` is ok to solve windows not deffined error
    try {
      resetPassword(email)
        .then(() => {
          // Email sent.
          c.setState({ message: i18next.t('ResetPassword.emailSent.message') });
        })
        .catch((error) => {
          trackingClick('account', error);

          c.setState({ message: translateError(error.code) });
        });
    } catch (error) {
      console.error('Something went wrong', error.message);
    }
  }

  render() {
    const { message } = this.state;
    return (
      <div className="login-wrapper fixed">
        <div className="login-container">
          <h2>{i18next.t('ResetPassword.tittle.text')}</h2>
          <p>{i18next.t('ResetPassword.body.text')}</p>
          <form
            onSubmit={(event) => {
              this.handleClick(event);
            }}
            ref={(form) => {
              this.loginForm = form;
            }}
          >
            <label htmlFor="emailResetPassword">
              {i18next.t('ResetPassword.email.placeholder')}
              <input
                id="emailResetPassword"
                name="email"
                type="email"
                ref={(input) => {
                  this.emailInput = input;
                }}
                placeholder={i18next.t('ResetPassword.email.placeholder')}
              />
            </label>
            {message && <p className="message">{message}</p>}
            <input type="submit" className="button" value={i18next.t('ResetPassword.resetButton.text')} />
          </form>
          <Link
            to={`/${global.language}/`}
            onClick={() => {
              trackingClick('account', 'password_back');
            }}
          >
            {i18next.t('ResetPassword.backButton.text')}
          </Link>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
