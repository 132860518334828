/* eslint-disable react/no-danger */
import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TranslatedLink from '../../Link/TranslatedLink';
import { formatPrice } from '../../../utils/CurrencyUtils';
import { trackingProducts, trackingProductsPage } from '../../../utils/TrackingProductsUtils';

export const SUBSCRIPTION_CLASSIC_PRODUCT_SKUS = [
  '2', // CLASSIC - 1 MONTH
  '3', // CLASSIC - 12 MONTHS + Starter Set
  '4', // CLASSIC - 6 MONTHS
  '5', // CLASSIC - 3 MONTHS
  '7', // CLASSIC - 7 MONTHS
];
export const SUBSCRIPTION_FAMILY_PRODUCT_SKUS = [
  '8', // FAMILY - 1 MONTH
  '9', // FAMILY - 12 MONTH
];
export const SUBSCRIPTION_POCKETBOOK_PRODUCT_SKUS = [
  '20', // POCKET BOOK - 1 MONTH
];
export const GIFT_CARDS_STANDARD = ['103', '104', '105'];
export const GIFT_CARDS_GIFTBOX = ['113', '114', '115'];
export const GIFT_CARDS_PHOTOBOOKS = ['120', '121', '122', '123'];
export const PHOTOSETS_SKUS = [
  '200', // ORIGINAL SET
  '250', // NO BIGGIE SET
  '251', // MEDIUM AND SQUARES
  '252', // SQUARES ONLY
];
export const CLASSIC_FRAMES = [
  '701', // SMALL CLASSIC FRAME
  '702', // MED CLASSIC FRAME
  '703', // LARGE CLASSIC FRAME
];
export const CANVAS_FRAMES = [
  '713', // A3 CANVAS FRAME
  '714', // A2 CANVAS FRAME
  '715', // A1 CANVAS FRAME
];
export const PHOTO_CARDS = [
  '751', 
  '752',
  '753',
];

const productMapping = {
  [SUBSCRIPTION_CLASSIC_PRODUCT_SKUS.join(',')]: {
    title: 'shop:ShopPage.ClassicSubscription.title',
    subtitle: 'shop:ShopPage.ClassicSubscription.subtitle',
  },
  [SUBSCRIPTION_FAMILY_PRODUCT_SKUS.join(',')]: {
    title: 'shop:ShopPage.FamilySubscription.title',
    subtitle: 'shop:ShopPage.FamilySubscription.subtitle',
  },
  [SUBSCRIPTION_POCKETBOOK_PRODUCT_SKUS.join(',')]: {
    title: 'shop:ShopPage.PhotoBookSubscription.title',
    subtitle: 'shop:ShopPage.PhotoBookSubscription.subtitle',
  },
  [GIFT_CARDS_GIFTBOX.join(',')]: {
    title: 'shop:ShopPage.GiftCards.StarterSet.title',
    subtitle: 'shop:ShopPage.GiftCards.StarterSet.subtitle',
  },
  [GIFT_CARDS_STANDARD.join(',')]: {
    title: 'shop:ShopPage.GiftCards.StandardSubscription.title',
    subtitle: 'shop:ShopPage.GiftCards.StandardSubscription.subtitle',
  },
  [GIFT_CARDS_PHOTOBOOKS.join(',')]: {
    title: 'shop:ShopPage.GiftCards.PhotoBookSubscription.title',
    subtitle: 'shop:ShopPage.GiftCards.PhotoBookSubscription.subtitle',
  },
  [CLASSIC_FRAMES.join(',')]: {
    title: 'shop:ShopPage.ClassicFrames.title',
    subtitle: 'shop:ShopPage.ClassicFrames.subtitle',
  },
  [CANVAS_FRAMES.join(',')]: {
    title: 'shop:ShopPage.CanvasFrames.title',
    subtitle: 'shop:ShopPage.CanvasFrames.subtitle',
  },
};

const ProductPresentational = (props: any) => {
  const { product, wide, trackedPage } = props;
  const { key, imageSrc, title, text, price, currency, type } = product;
  const { t } = useTranslation();

  const formattedPrice = formatPrice(price, currency, 'currency');
  const productPageRoute = `/product/${key}`;
  const productCheckoutRoute = `${productPageRoute}/checkout`;
  const [selectedImage] = useState(0);
  const currentImage = imageSrc && imageSrc[selectedImage];
  const handleBuyButtonTracking = useCallback(() => {
    trackingProducts('button', trackingProductsPage(trackedPage));
  }, [trackedPage]);

  const renderProductTitle = () => {
    let productTitle = t(`shop:ShopPage.${key}.title`);
    let productSubtitle = t(`shop:ShopPage.${key}.subtitle`);

    // Map product key to title and subtitle
    Object.entries(productMapping).forEach(([productKeys, { title, subtitle }]) => {
      const productKeysArray = productKeys.split(',');
      if (productKeysArray.includes(key)) {
        productTitle = t(title);
        productSubtitle = t(subtitle);
      }
    });

    return (
      <div style={{ gap: 4 }}>
        <p className="title">{productTitle}</p>
        {productSubtitle && <p className="subTitle">{productSubtitle}</p>}
      </div>
    );
  };

  const renderProductPrice = () => {
    if (type === 'wall' || type === 'coupon') {
      return (
        <div>
          <p className="price" style={{ marginRight: 4 }}>
            {t('app_common:from')}
          </p>
          <p className="price">{formattedPrice}</p>
        </div>
      );
    }
    return <p className="price">{formattedPrice}</p>;
  };

  return (
    <div className={`product ${wide ? 'wide' : ''} ${`sku-${key}`}`} key={key}>
      <TranslatedLink to={productPageRoute} onClick={handleBuyButtonTracking}>
        <div className="imageWrapper">
          <img src={currentImage} alt="product" className="imageProduct" />
        </div>
        <div className="productShort">
          {renderProductTitle()}
          {renderProductPrice()}
        </div>
      </TranslatedLink>
      {wide && (
        <span>
          <p
            className="description"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <TranslatedLink className="button buyButton" to={productCheckoutRoute} onClick={handleBuyButtonTracking}>
            {t('Shop.product.buyButton')}
          </TranslatedLink>
        </span>
      )}
    </div>
  );
};
ProductPresentational.defaultProps = {
  wide: false,
};
ProductPresentational.propTypes = {
  product: PropTypes.any,
  wide: PropTypes.bool,
  trackedPage: PropTypes.string.isRequired,
};
export default memo(ProductPresentational);
