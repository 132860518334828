import React from 'react';

const FrameClassic1x1 = ({ isSelected }: { isSelected: any }) => {
  const borderColor = isSelected ? '#18b2bb' : '#888888';
  const fillColor = isSelected ? '#c3f3e3' : '#D9D9D9';

  return (
    <svg width="65" height="92" viewBox="0 0 65 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="61" height="88" stroke={borderColor} strokeWidth="4" />
      <rect x="9" y="9" width="47" height="74" fill={fillColor} />
    </svg>
  );
};

export default FrameClassic1x1;
