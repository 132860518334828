import React from 'react';
import PropTypes from 'prop-types';
import i18next from '../../services/i18n';
import './Contact.scss';

const Contact = (props) => {
  const contactFormURL = '/reamaze-form.html';

  return (
    <section className="contactus">
      <div className="flex-wrapper">
        <div className="flex-container">
          <h2 className="title">{i18next.t('SmartSupport.contactus')}</h2>
          <iframe src={contactFormURL} width="100%" height="600" frameBorder="0" title="Contact Form"></iframe>
        </div>
      </div>
    </section>
  );
};

Contact.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Contact;
