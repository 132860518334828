import { fabric } from 'fabric';
import { TemplateObject } from '../BitmapEditor.types';
import { Canvas } from 'fabric/fabric-impl';

const rickRoll = 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Rickrolling_QR_code.png?20200615212723';

export const drawQr = (canvas: Canvas, object: TemplateObject): void => {
  fabric.Image.fromURL(
    rickRoll!,
    (img) => {
      img.set({
        left: object.x,
        top: object.y,
        width: img.width,
        height: img.height,
        evented: false,
      });

      // Fit the image in the object size
      if (object.width !== undefined) {
        img.scaleToWidth(object.width);
      }
      if (object.height !== undefined) {
        img.scaleToHeight(object.height);
      }
      canvas.add(img);
    },
    { crossOrigin: 'anonymous' }
  );
};
