import React, { useEffect, useState, useMemo } from 'react';
import './FrameUpgradeModal.scss';
import CloseBtn from '../images/closeBtn';
import ProductDetailVariantsView from './ProductDetailVariantsView';
import { IProductPackageExtended, localStoragePrefix } from '../../Shop/types';
import {
  GIFT_CARDS_GIFTBOX,
  GIFT_CARDS_PHOTOBOOKS,
  GIFT_CARDS_STANDARD,
  PHOTOSETS_SKUS,
  SUBSCRIPTION_CLASSIC_PRODUCT_SKUS,
  SUBSCRIPTION_FAMILY_PRODUCT_SKUS,
  SUBSCRIPTION_POCKETBOOK_PRODUCT_SKUS,
  CLASSIC_FRAMES,
  CANVAS_FRAMES,
  PHOTO_CARDS,
} from '../../Shop/Products/ProductPresentational';
import productsActions from '../../../actions/productsActions';
import { sortProductObjects } from '../../../utils/Utils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function FrameUpgradeModal(props: any) {
  const [product, setProduct] = useState<IProductPackageExtended | undefined>();
  const [products, setProducts] = useState<any>({});  
  const { type, key: sku, metadata = {} } = product ?? { type: 'pictures', key: '0', metadata: { templates: [] } };
  const { t } = useTranslation();

  const backgroundImageClassName = classNames(
    props.productKey === '701' && 'medium-frame',
    props.productKey === '702' && 'large-frame',
    props.productKey === '703' && 'xlarge-frame',
    props.productKey === '751' && 'post-card',
    props.productKey === '752' && 'post-card',
    props.productKey === '753' && 'post-card',
    props.productKey === '713' && 'canvas-frame',
    props.productKey === '714' && 'canvas-frame',
    props.productKey === '715' && 'canvas-frame',
  );

  const selectProduct = (sku: string) => {
    props.setProductKey(sku);
  };

  const variantsForProduct = useMemo(() => {
    if (!products || !products.all || !product) return [];
    const { key: sku } = product as { key: string };
    const allProducts = products.all;
    const productKeys = Object.keys(allProducts);
    const productVariants: any[] = [];

    const skuCategories = {
      SUBSCRIPTION_CLASSIC: SUBSCRIPTION_CLASSIC_PRODUCT_SKUS,
      SUBSCRIPTION_FAMILY: SUBSCRIPTION_FAMILY_PRODUCT_SKUS,
      SUBSCRIPTION_POCKETBOOK: SUBSCRIPTION_POCKETBOOK_PRODUCT_SKUS,
      GIFT_CARDS_STANDARD,
      GIFT_CARDS_PHOTOBOOKS,
      GIFT_CARDS_GIFTBOX,
      CLASSIC_FRAMES,
      CANVAS_FRAMES,
      PHOTOSETS_SKUS,
      PHOTO_CARDS
    };

    // Loop through each category to find if the input sku matches
    // any category; if it does, filter the products that belong to that category
    Object.entries(skuCategories).forEach(([category, skus]) => {
      if (skus.includes(sku)) {
        productKeys.forEach((key) => {
          if (skus.includes(key)) {
            productVariants.push({ ...allProducts[key] });
          }
        });
      }
    });

    productVariants.sort((a, b) => a.price - b.price);
    return productVariants;
  }, [product, products]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sku = params.get('sku') || '701';
    props.setProductKey(sku);

    productsActions.getFormattedProducts().then((newProducts) => {
      setProducts((oldProducts: any) => {
        if (JSON.stringify(oldProducts) === JSON.stringify(newProducts)) {
          return sortProductObjects(oldProducts, []);
        }
        return sortProductObjects(newProducts, []);
      });
      const productsToCache = Object.keys(newProducts);
      localStorage.setItem(`${localStoragePrefix}-products`, JSON.stringify(productsToCache));
      Object.entries(newProducts).forEach(([productId, product]) => {
        localStorage.setItem(`${localStoragePrefix}-${productId}`, JSON.stringify(product));
      });
    });
  }, [global.language]);

  useEffect(() => {
    const localStorageProduct = localStorage.getItem(`${localStoragePrefix}-${props.productKey}`);
    if (localStorageProduct) {
      const newProduct = JSON.parse(localStorageProduct);
      if (newProduct.thumbSrc) {
        new Image().src = newProduct.thumbSrc;
      }
      if (!product || product.key !== newProduct.key) {
        setProduct(newProduct);
      }
    }
  }, [props.productKey, products]);

  return (
    <div className="upgradeModalWrapper">
      <div className="upgradeModal">
        <div className="leftPanel">
          <div className={classNames('frame', backgroundImageClassName)}></div>
        </div>
        <div className="rightPanel">
          <div className="closeBtn" onClick={() => { props.setShowUpgradeModal(false) }}>
            <CloseBtn />
          </div>
          <div className="title">
            {(props.productType === 'wall' || props.productType === 'canvas') && t('BitmapEditor.upgradeModal.titleWall')}
            {props.productType === 'card' && t('BitmapEditor.upgradeModal.titleCard')}
          </div>
          <div className="sub-title">
            {(props.productType === 'wall' || props.productType === 'canvas') && t('BitmapEditor.upgradeModal.textWall')}
            {props.productType === 'card' && t('BitmapEditor.upgradeModal.textCard')}
          </div>
          <div className='product-variants'>
            <ProductDetailVariantsView
              productCategory={type}
              variantProducts={variantsForProduct}
              selectedProduct={props.productKey}
              selectHandler={(sku: string) => selectProduct(sku)}
            />
          </div>
          <div className="continueCheckout">
            <button onClick={() => { props.setSaveModalShow(true) }} className="button" type="button">
              {t('BitmapEditor.upgradeModal.continueToCheckout')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}