import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-responsive-modal';
import i18next from '../../services/i18n';
import './Confirm.scss';

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { visible } = this.props;
    this.setState({ show: visible });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.visible !== state.show) {
      return {
        show: props.visible,
      };
    }
    return null;
  }

  render() {
    const { show } = this.state;
    const { text, functionToConfirm, closeConfirm } = this.props;
    return (
      <Modal classNames={{ modal: 'confirm', closeIcon: 'close' }} open={show} onClose={closeConfirm} center>
        <div className="container">
          <p>{text}</p>
          <div className="buttons">
            <button className="cancel" type="button" onClick={closeConfirm}>
              {i18next.t('common:no')}
            </button>
            <button className="confirm" type="button" onClick={functionToConfirm}>
              {i18next.t('common:yes')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

Confirm.propTypes = {
  text: PropTypes.string.isRequired,
  functionToConfirm: PropTypes.func.isRequired,
  closeConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Confirm;
