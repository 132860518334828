import { fabric } from 'fabric';
import { TemplateObject } from '../BitmapEditor.types';
import { Canvas } from 'fabric/fabric-impl';

export const drawRectangle = (canvas: Canvas, object: TemplateObject): void => {
  const rectangle = new fabric.Rect({
    left: object.x,
    top: object.y,
    width: object.width,
    height: object.height,
    fill: object.fillStyle,
    selectable: true,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    borderScaleFactor: 3,
    stroke: '#3355ff',
    strokeWidth: 0,
    hoverCursor: 'pointer',
  });
  canvas.add(rectangle);
};
