import i18next from 'i18next';

const project = process.env.REACT_APP_PROJECT_ID;

export const apiBaseUrl = `https://${project === 'klikkie-dev' ? 'dev.' : ''}api.klikkie.com`;

export const orderApiBaseUrl = `https://${project === 'klikkie-dev' ? 'dev.' : ''}orders.klikkie.com`;
export const paymentsApiBaseUrl = `https://${project === 'klikkie-dev' ? 'dev.' : ''}payments.klikkie.com`;

export const apiFunctionsUrl = `https://europe-west1-${project}.cloudfunctions.net`;

export const getLinksfromLocize = () => {
  const appEnvironment = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev';

  return {
    app: i18next.t(`links:${appEnvironment}.app`),
    beta: i18next.t(`links:${appEnvironment}.beta`),
    contact: i18next.t(`links:${appEnvironment}.contact`),
    profile: i18next.t(`links:${appEnvironment}.profile`),
    site: i18next.t(`links:${appEnvironment}.site`),
    activate: i18next.t(`links:${appEnvironment}.activate`),
    subscription: i18next.t(`links:${appEnvironment}.subscription`),
    knowledgebase: i18next.t(`links:${appEnvironment}.knowledgebase`),
  };
};
export const uploadImage = './../../../img/upload-image.svg';
