import { fabric } from 'fabric';
import { TemplateObject } from '../BitmapEditor.types';

export const drawKlikkieImageElement = (canvas: fabric.Canvas, object: TemplateObject): void => {
  const { width = 100, height = 100 } = object;

  fabric.Image.fromURL(
    '/img/klikkie-logo.svg',
    (img: any) => {
      img.set({
        left: object.x,
        top: object.y,
        scaleX: width / img.width,
        scaleY: height / img.height,
        selectable: false,
        hasControls: false,
        hasBorders: false,
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: 'default',
      });
      canvas.add(img);
    },
    { crossOrigin: 'anonymous' }
  );
};
