import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import Spinner from '../helpers/Spinner/Spinner';
import i18next from '../services/i18n';
import { logout } from '../actions/authActions';

class Logout extends PureComponent {
  state = {
    redirect: false,
  };

  UNSAFE_componentWillMount() {
    logout().then(() => {
      this.setState({ redirect: true });
    });
  }

  render() {
    const { redirect } = this.state;
    if (redirect === true) {
      return <Redirect to={`/${global.language}/login`} />;
    }

    return (
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '25%',
          left: '50%',
        }}
      >
        <h3>{i18next.t('Logout.logout.text')}</h3>
        <Spinner />
      </div>
    );
  }
}

export default Logout;
