import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

const API_URL = `${apiBaseUrl}/countries`;

export default {
  getCountries() {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/`, requestBody);
    });
  },
};
