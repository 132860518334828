import React from 'react';
import PropTypes from 'prop-types';
import './Arrow.scss';
import IonIcon from '@reacticons/ionicons';

const Arrow = ({ up }) => (
  <div className="Arrow">{up ? <IonIcon name="chevron-up-outline" /> : <IonIcon name="chevron-down-outline" />}</div>
);

Arrow.defaultProps = {
  up: false,
};

Arrow.propTypes = {
  up: PropTypes.bool,
};

export default Arrow;
