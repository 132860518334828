import PropTypes from 'prop-types';
import React from 'react';
import './MobileAppCTA.scss';
import { withNamespaces } from 'react-i18next';
import i18next from '../../services/i18n';

const MobileAppCTA = (props) => {
  const { message, className, onClick } = props;

  return (
    <div className={className}>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: message }} />

      <div className="position-buttons">
        <a
          href="https://apps.apple.com/us/app/klikkie/id1439010826?ls=1"
          target="_blank"
          rel="noopener noreferrer"
          className="button apple"
          onClick={() => onClick('ios')}
        >
          {i18next.t('SendSlide.apple.text')}
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.klikkie.app"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => onClick('android')}
          className="button google"
        >
          {i18next.t('SendSlide.google.text')}
        </a>
      </div>
    </div>
  );
};

MobileAppCTA.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MobileAppCTA;
