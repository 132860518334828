import './AddressBookItemNew.scss';
import React from 'react';
import PropTypes from 'prop-types';
import addressActions from '../../actions/addressActions';
import { auth } from '../../services/firebase';
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';
import { Link } from 'react-router-dom';

const AddressBookItemNew = (props: any) => {
  const { address, isPrimaryAddress, setNewActiveAddress, refreshAddressbook } = props;
  const { addressId, fullname, address: address1, address2, zipcode, countryCode, city } = address;
  const { t } = useTranslation();

  const deleteHandler = async () => {
    if (confirm(t('AddressBook.deleteAddress.confirm'))) {
      const userId = auth.currentUser?.uid || '';
      if (userId) {
        await addressActions.deleteAddress(userId, addressId);
        refreshAddressbook();
      }
    } else {
    }
  };

  const addressSelectHandler = () => {
    if (!isPrimaryAddress && confirm(t('AddressBook.makePrimary.confirm'))) {
      setNewActiveAddress(addressId);
      refreshAddressbook();
    }
  };

  return (
    <div className="addressItem">
      <label className={`addressTile ${isPrimaryAddress}`} htmlFor={addressId} onClick={addressSelectHandler}>
        {isPrimaryAddress && <IonIcon name="home-outline" className="ion-icon" />}
        <div className="addressDetails">
          <div className="name">{fullname}</div>
          <div className="address">{address1}</div>
          <div className="address2">{address2}</div>
          <div className="zipcode">{zipcode}</div>
          <div className="city">{city}</div>
          <div className="countryCode">{countryCode}</div>
        </div>
        {!isPrimaryAddress && (
          <div className="makePrimaryAddress">
            <IonIcon name="home-outline" className="ion-icon" /> <p>{t('AddressBook.makePrimary.button')} </p>
          </div>
        )}
      </label>
      <div className="addressItemGroupButton">
        {!isPrimaryAddress && (
          <div className="addressItemDelete" onClick={deleteHandler}>
            <IonIcon name="trash-outline"></IonIcon>
          </div>
        )}

        <div className="addressItemEdit">
          <Link className="addressItemEditLink" to={{ pathname: `/${global.language}/addressbook/edit/${addressId}` }}>
            <IonIcon name="create-outline"></IonIcon>
          </Link>
        </div>
      </div>
    </div>
  );
};

AddressBookItemNew.propTypes = {
  isPrimaryAddress: PropTypes.bool.isRequired,
  address: PropTypes.object.isRequired,
  setNewActiveAddress: PropTypes.func,
  refreshAddressbook: PropTypes.func,
};
AddressBookItemNew.defaultProps = {};
export default AddressBookItemNew;
