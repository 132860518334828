import React, { Component } from 'react';
import './CheckOut.scss';
import PropTypes from 'prop-types';
import paymentActions from '../../actions/paymentActions';
import { trackingClick } from '../../actions/trackingActions';
import productsActions from '../../actions/productsActions';
import subscriptionActions from '../../actions/subscriptionActions';
import i18next from '../../services/i18n';
import SaveAnimation from '../../helpers/Spinner/SaveAnimation';
import userActions from '../../actions/userActions';
import pack3 from '../../img/products/3.jpg';
import pack4 from '../../img/products/4.jpg';
import pack7 from '../../img/products/7.jpg';
import { addMonthsToDate } from '../../utils/DateUtils';
import NewAddressForm from '../AddressBook/NewAddressForm';
import { database } from '../../services/firebase';
class CheckOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      title: '',
      id: '',
      sum: '',
      months: '',
      submit: true,
      imageSrc: '',
      formatedPrepaid: '',
      noAddress: false,
    };
  }

  componentDidMount = async () => {
    const {
      userId,
      match: {
        params: { id },
      },
    } = this.props;

    try {
      const product = await productsActions.getProduct(id);
      const subscription = await subscriptionActions.getSubscription(userId);
      const startDate = subscription.prepaidUntil
        ? new Date(`${subscription.prepaidUntil}-${subscription.monthlyRecurrenceDay}`)
        : this.getStartDate();

      const {
        profile: { languageCode, countryCode },
      } = await userActions.getUser(userId);

      const sum = this.transformPrice(product, countryCode);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      const formatedPrepaid = new Date(startDate).toLocaleDateString(languageCode, options);
      let imageSrc;
      let title;
      switch (id) {
        case '3':
          title = i18next.t('renewMembership.package3.title');
          imageSrc = pack3;
          break;
        case '4':
          title = i18next.t('renewMembership.package4.title');
          imageSrc = pack4;
          break;
        case '7':
          title = i18next.t('renewMembership.package7.title');
          imageSrc = pack7;
          break;
        default:
          title = i18next.t('renewMembership.package7.title');
          imageSrc = pack7;
      }
      this.setState({
        userId,
        sum,
        id,
        imageSrc,
        title,
        formatedPrepaid,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getStartDate = (monthlyRecurrenceDay) => {
    const today = new Date();
    this;
    let year = today.getFullYear();
    let month = today.getMonth(); // Note: January is 0, December is 11
    let day = monthlyRecurrenceDay;

    // Check if the day has already passed in the current month
    if (today.getDate() > monthlyRecurrenceDay) {
      month++; // Move to the next month
      if (month > 11) {
        month = 0; // Reset to January
        year++; // Increment the year
      }
    }

    // Adjust the day if it's more than the last day of the month
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    if (day > lastDayOfMonth) {
      day = lastDayOfMonth;
    }

    // Construct the next subscription date
    const nextSubscriptionDate = new Date(year, month, day);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return nextSubscriptionDate;
  };

  transformPrice = (product, countryCode) => {
    console.log(product, countryCode);
    const transformPrice = product.price[countryCode] / 100;
    const price = transformPrice.toLocaleString(this.countryCode, {
      style: 'currency',
      currency: product.currency[countryCode],
    });
    return price;
  };

  buyProduct = async () => {
    const { history } = this.props;
    const { userId, id } = this.state;
    this.setState({ submit: false });
    trackingClick('upsellPackage', 'confirm');
    const { metadata } = await userActions.getUser(userId);

    console.log('\t  - buyProduct');
    if (!metadata) {
      this.setState({ noAddress: true });
      return;
    }
    console.log('\t  - active address');
    const activeAddress = metadata.activeAddress || 'subscriptionV0';
    console.log('Tim: ==>', userId, id, activeAddress);
    await paymentActions
      .orderProductWithRecurrentDetails(userId, id, activeAddress)
      .then(() => {
        trackingClick('upsellPackage', 'purchase');
        history.push(`/${global.language}/success/${id}`);
      })
      .catch(() => {
        history.push(`/${global.language}/failed/${id}`);
      });
  };
  activeAddressListener = () => {
    const { userId } = this.state;
    const userRef = database.ref(`user/${userId}`);
    const onValueChange = userRef.on('value', (snapshot) => {
      this.handleUserSnapshotResponse(snapshot);
    });

    // Stop listening for updates when no longer required
    return () => userRef.off('value', onValueChange);
  };
  handleUserSnapshotResponse = async (snapshot) => {
    const _this = this;
    const userData = snapshot.val();
    if (userData && userData.metadata && userData.metadata.activeAddress) {
      this.buyProduct();
    }
  };

  closeAddressWindow = () => {
    this.setState({ noAddress: false, submit: true });
  };
  render() {
    const { submit, noAddress, returnUrl, sum, formatedPrepaid, imageSrc, title, userId } = this.state;
    if (noAddress) {
      return (
        <NewAddressForm
          newAddressSavedHandler={this.activeAddressListener}
          userId={userId}
          returnUrl={this.closeAddressWindow}
        />
      );
    }

    return (
      <span>
        {title && (
          <div className="checkOut">
            <div className="titleHeader">
              <h2 className="green">{i18next.t('checkout.title')}</h2>
              <h2>{title}</h2>
            </div>
            <img src={imageSrc} alt="" />
            <p>{i18next.t('confirmPayment.text', { formatedPrepaid })}</p>
            <p>{i18next.t('confirmPayment.totalSum', { sum })}</p>
            {/* hide button when clicked */}
            {submit && (
              <button type="button" className="button" onClick={this.buyProduct}>
                {i18next.t('confirmPayment.button')}
              </button>
            )}
            {!submit && (
              <div className="fauxButton">
                <SaveAnimation />
                <span>{i18next.t('confirmPayment.loading')}</span>
              </div>
            )}
            <small>{i18next.t('confirmPayment.disclaimer')}</small>
          </div>
        )}
      </span>
    );
  }
}
CheckOut.propTypes = {
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
export default CheckOut;
