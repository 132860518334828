import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import subscriptionActions from '../../actions/subscriptionActions';

interface Props {
  userId: string;
  pauseDuration: number;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReactivationSuccess = (props: Props) => {
  const { userId } = props;
  const { t } = useTranslation();
  let query = useQuery();
  const duration = query.get('duration');
  const [toSubscription, setToSubscription] = useState<boolean>(false);
  window.scrollTo(0, 0);
  const continueHandler = () => {
    setToSubscription(true);
  };

  const unpauseHandler = async () => {
    try {
      await subscriptionActions.unPauseSubscription(userId);
      setToSubscription(true);
    } catch (error) {
      // TODO: handle if something goes wrong
    }
  };

  if (toSubscription) {
    return <Redirect to={`/${global.language}/subscription`} />;
  }

  return (
    <div className="checkOut">
      <h2 style={{ textAlign: 'center' }}>{t('MembershipReactivated.title', { pausedDuration: duration })}</h2>
      <img
        src="https://media.giphy.com/media/R6gvnAxj2ISzJdbA63/giphy-downsized-large.gif"
        alt="thank you"
        className="gifAnimation"
      />

      <p style={{ textAlign: 'center' }}>{t('MembershipReactivated.text')}</p>

      <button id="sendOrderBt" className="button lined" type="submit" onClick={continueHandler}>
        {t('MembershipReactivated.continue')}
      </button>
    </div>
  );
};

ReactivationSuccess.defaultProps = {
  pauseDuration: 0,
};

export default ReactivationSuccess;
