import './Menu.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import { getLinksfromLocize } from '../../config';
import i18next from '../../services/i18n';
import image from '../../img/klikkie-logo.png';
import TranslatedLink from '../Link/TranslatedLink';
import { trackingClick } from '../../actions/trackingActions';
import IonIcon from '@reacticons/ionicons';
import HelpMenu from './HelpMenu/HelpMenu';

class Menu extends Component {
  state = {
    show: false,
    extraMonths: '!',
  };

  toggleMenuHandler = () => {
    const { show } = this.state;
    if (global.uploading) {
      return;
    }

    this.setState({ show: !show });
  };

  trackingClickHandlerGenerator = (eventLabel) => () => {
    trackingClick('Menu', eventLabel);
    this.toggleMenuHandler();
  };

  render() {
    const { changeLanguage } = this.props;
    const links = getLinksfromLocize();
    const { show, extraMonths } = this.state;

    return (
      <div className="menu">
        <div id="menuButton" className="trigger" onClick={this.toggleMenuHandler}>
          {!show ? (
            <span>
              <IonIcon name="menu-outline"></IonIcon>
            </span>
          ) : (
            <IonIcon name="close-outline"></IonIcon>
          )}
        </div>
        {show && (
          <div className="menu-wrapper">
            <ul>
              <li className="menuLogo">
                <img src={image} alt="logo" />
                <small>{process.env.REACT_APP_VERSION}</small>
              </li>
              <li>
                <TranslatedLink to="/" onClick={this.trackingClickHandlerGenerator('current_order')}>
                  <div>{i18next.t('Menu.currentMonth.text')}</div>
                </TranslatedLink>
              </li>
              <li>
                <TranslatedLink to="/orders" onClick={this.trackingClickHandlerGenerator('orders')}>
                  <div>{i18next.t('Menu.myMonths.text')}</div>
                </TranslatedLink>
              </li>
              <li>
                <TranslatedLink to="/orderHistory" onClick={this.trackingClickHandlerGenerator('orderHistory')}>
                  <div>{i18next.t('Menu.shop.orderHistory')}</div>
                </TranslatedLink>
              </li>
              <li>
                <TranslatedLink to="/shop" onClick={this.trackingClickHandlerGenerator('shop')}>
                  <div>{i18next.t('Menu.shop.text')}</div>
                </TranslatedLink>
              </li>
              <li>
                <TranslatedLink to="/invite">
                  <div onClick={this.trackingClickHandlerGenerator('mgm')}>
                    {i18next.t('Menu.invite')}
                    <span className="notificationNumber">{extraMonths}</span>
                  </div>
                </TranslatedLink>
              </li>
              <li>
                <TranslatedLink to="/profile">
                  <div onClick={this.trackingClickHandlerGenerator('profile')}>{i18next.t('Menu.myProfile.text')}</div>
                </TranslatedLink>
              </li>
              <li>
                <HelpMenu toggleMenuHandler={this.toggleMenuHandler} />
              </li>
              <li>
                <TranslatedLink to="/logout">
                  <div id="logout" onClick={this.trackingClickHandlerGenerator('logout')}>
                    {i18next.t('Menu.logout.text')}
                  </div>
                </TranslatedLink>
              </li>
              <li>
                <SelectLanguage changeLanguage={changeLanguage} />
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

Menu.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
};

export default Menu;
