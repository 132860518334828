import React, { Component } from 'react';
import IonIcon from '@reacticons/ionicons';
import './Profile.scss';
import PropTypes from 'prop-types';
import SaveAnimation from '../../helpers/Spinner/SaveAnimation';
import userActions from '../../actions/userActions';
import i18next from '../../services/i18n';
import subscriptionActions from '../../actions/subscriptionActions';

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageCode: 'NL',
      name: undefined,
      surname: '',
      phonenumber: '',
      saving: false,
      optin: {},
      loaded: false,
    };
  }

  componentDidMount() {
    this.getProfile();
  }

  async getProfile() {
    const { userId } = this.props;
    try {
      const { profile } = await userActions.getUser(userId);
      const { createdAt } = await subscriptionActions.getSubscription(userId);
      const { name, surname, phonenumber, languageCode } = profile;

      const date = new Date(createdAt).toLocaleDateString(languageCode);
      this.setState({
        name,
        surname,
        phonenumber,
        date,
        languageCode,
        loaded: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  saveProfile = (e) => {
    const { name, surname, phonenumber } = this.state;
    this.setState({ saving: true });
    e.preventDefault();

    const update = { name, surname, phonenumber };
    userActions.setUser(update).then(() => {
      this.setState({ saving: false });
    });
  };

  validateField = (fieldName, value) => {
    const _this = this;
    return true;
  };

  handleUserInput = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  render() {
    const { saving, date, loaded, name, surname, phonenumber } = this.state;

    return (
      <form onSubmit={this.saveProfile}>
        {loaded && (
          <span>
            <fieldset>
              <h2 className="iconHeader">
                <IonIcon name="person" className="ion-icon" /> Personal
              </h2>
              {saving && <SaveAnimation />}
              <p>
                {i18next.t('myDetails.memberSince')}

                {date}
              </p>
            </fieldset>
            <div className="fieldsetRow">
              <fieldset className="half">
                <label htmlFor="name">{i18next.t('myDetails.name')}</label>
                <div className="input-wrapper">
                  <input
                    onChange={this.handleUserInput}
                    name="name"
                    id="name"
                    className="name"
                    type="text"
                    required
                    value={name}
                  />
                </div>
                <div className="message">{i18next.t('SendToNewAddress.incomplete.text')}</div>
              </fieldset>
              <fieldset className="half">
                <label htmlFor="surname">{i18next.t('myDetails.surname')}</label>
                <div className="input-wrapper">
                  <input
                    onChange={this.handleUserInput}
                    name="surname"
                    id="surname"
                    className="name"
                    type="text"
                    required
                    value={surname}
                  />
                </div>
                <div className="message">{i18next.t('SendToNewAddress.incomplete.text')}</div>
              </fieldset>
            </div>
            <fieldset>
              <label htmlFor="phonenumber">{i18next.t('myDetails.phonenumber')}</label>
              <div className="input-wrapper">
                <input
                  onChange={this.handleUserInput}
                  name="phonenumber"
                  id="phonenumber"
                  className="name"
                  type="text"
                  required
                  value={phonenumber}
                />
              </div>
              <div className="message">{i18next.t('SendToNewAddress.incomplete.text')}</div>
            </fieldset>
            <fieldset className="buttonContainer">
              <button id="sendOrderBt" className="button lined" type="submit">
                {i18next.t('myDetails.button')}
              </button>
            </fieldset>
          </span>
        )}
      </form>
    );
  }
}
PersonalDetails.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default PersonalDetails;
