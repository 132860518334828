import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-responsive-modal';
import i18next from '../../services/i18n';
import Confirm from '../../helpers/Confirm/Confirm';
import pictureOrderActions from '../../actions/pictureOrderActions';
import productsActions from '../../actions/productsActions';
import IonIcon from '@reacticons/ionicons';

const productImages = require.context('../../img/products/', true);

class SelectSetButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sets: undefined,
      showConfirm: false,
      currentSku: undefined,
      newSku: {},
      showSetSelection: false,
    };
  }

  async componentDidMount() {
    try {
      const { currentSku, userId } = this.props;
      const sets = await productsActions.getPhotoSets(userId);
      const photoSet = sets.filter(({ key }) => key === currentSku)[0];

      this.setState({ sets, photoSet, currentSku });
    } catch (error) {
      console.error(error);
    }
  }

  componentWillUnmount() {}

  toggleSelectSetModal = (newSku) => {
    const { showSetSelection, photoSet: prevSet } = this.state;

    if (newSku && newSku !== prevSet.key) {
      this.setState({ showConfirm: true, newSku });
    }
    this.setState({ showSetSelection: !showSetSelection });
  };

  closeConfirm = () => {
    this.setState({ showConfirm: false });
  };

  changePhotoSet = async () => {
    try {
      const { newSku, sets } = this.state;
      const { userId, orderId, loadPhotoSet } = this.props;

      await pictureOrderActions.changePhotoSet(userId, orderId, newSku);
      const photoSet = sets.filter(({ key }) => key === newSku)[0];
      this.setState({ showConfirm: false, photoSet, currentSku: newSku });
      loadPhotoSet();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { currentSku } = this.state;
    const { sets, showConfirm, showSetSelection, photoSet } = this.state;

    // "Your selection and edits will be lost if you pick a new photo set. Are you sure?"
    // change photo set
    return (
      <div>
        <Confirm
          text={i18next.t('SelectSetButton.confirm.text')}
          visible={showConfirm}
          functionToConfirm={this.changePhotoSet}
          closeConfirm={this.closeConfirm}
        />
        <button type="button" className="set-select" onClick={() => this.toggleSelectSetModal()}>
          {photoSet ? photoSet.title : ''}
          <IonIcon name="chevron-down-outline" />
        </button>
        <Modal
          classNames={{ overlay: 'set-select-Modal', modal: 'select-set' }}
          open={showSetSelection}
          onClose={() => this.toggleSelectSetModal()}
          center
        >
          <h2>{i18next.t('SelectSetButton.modal.title')}</h2>
          {!sets && <span>loading</span>}
          {sets &&
            sets.map(({ key, title, textShort }) => (
              <div
                key={key}
                className="photo-set-card"
                role="presentation"
                onClick={() => this.toggleSelectSetModal(key)}
              >
                <div className="selector-container">
                  <span className={`selector ${key === currentSku ? 'selected' : ''}`}>
                    <IonIcon name="checkmark" className={`${key === currentSku ? 'selected' : ''}`} />
                  </span>
                </div>
                <div>
                  <h3>{title}</h3>
                  <p className="card-description">{textShort}</p>
                  <div className="card-image">
                    <img src={productImages(`./${key}.png`)} alt={i18next.t('SelectSetButton.image.alt')} />
                  </div>
                </div>
              </div>
            ))}
        </Modal>
      </div>
    );
  }
}

SelectSetButton.propTypes = {
  userId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  currentSku: PropTypes.string,
  loadPhotoSet: PropTypes.func.isRequired,
};

SelectSetButton.defaultProps = {
  currentSku: '200',
};

export default SelectSetButton;
