import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

const API_URL = `${apiBaseUrl}/userPictures`;

export default {
  getUserPicture(userId, userPictureId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${userId}/${userPictureId}`, requestBody);
    });
  },
  getUserPictures(userId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${userId}`, requestBody);
    });
  },

  getUserPicturesByOrder(userId, orderId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${userId}/order/${orderId}`, requestBody);
    });
  },

  createPictures(userId, pictures) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(pictures),
      };

      return fetch(`${API_URL}/${userId}`, requestBody);
    });
  },

  updatePicture(userId, userPictureId, update) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(update),
      };

      return fetch(`${API_URL}/${userId}/${userPictureId}`, requestBody);
    });
  },

  deletePicture(userId, userPictureId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${userId}/${userPictureId}`, requestBody);
    });
  },
};
