import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

const API_URL = `${apiBaseUrl}/pictureOrders`;
const sortByDateDesc = (orderA, orderB) =>
  orderB.createdAt > orderA.createdAt ? 1 : orderA.createdAt > orderB.createdAt ? -1 : 0;
export default {
  async getOrder(userId, orderId) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${API_URL}/${userId}/${orderId}/`, requestBody);
  },
  async getOrders(userId) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${API_URL}/${userId}/`, requestBody);
  },
  async getUserOrders(userId) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${API_URL}/${userId}/`, requestBody);
  },
  async getUserOrdersAfterDayWithMessedOrders(userId) {
    const orders = await this.getUserOrders(userId);
    const dayD = '2018-11-01';
    const time = new Date(dayD).getTime();
    if (!orders) return [];
    return orders.filter((order) => order.createdAt >= time).sort(sortByDateDesc);
  },
  orderPictures2Arr(pictures) {
    if (!pictures) return [];
    return Object.keys(pictures).map((pictureId) => {
      const picture = pictures[pictureId];
      picture.pictureOrderPictureId = pictureId;
      return picture;
    });
  },
  orderPictures2BySizeObj(pictures) {
    return this.orderPictures2Arr(pictures).reduce((total, picture) => {
      if (!total[picture.templateId]) {
        total[picture.templateId] = [];
      }
      total[picture.templateId].push(picture);
      return total;
    }, {});
  },
  async getOrderPictures(userId, orderId) {
    try {
      const order = await this.getOrder(userId, orderId);
      const { pictures } = order;
      return this.orderPictures2Arr(pictures);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async countPictures(userId, orderId) {
    const { pictures } = await this.getOrder(userId, orderId);
    return this.orderPictures2Arr(pictures).reduce((total, current) => {
      if (typeof current.userPictureId === 'string' && current.userPictureId.length > 0) {
        total += 1;
      }
      return total;
    }, 0);
  },
  async updateOrder(userId, orderId, update) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(update),
    };
    return fetch(`${API_URL}/${userId}/${orderId}/`, requestBody);
  },
  async changePhotoSet(userId, orderId, sku) {
    try {
      const token = await getUserBearer();
      const options = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sku }),
      };
      return fetch(`${API_URL}/${userId}/${orderId}/change-package`, options);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async submitOrder(userId, orderId, metadata) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ metadata }),
      };
      return fetch(`${API_URL}/${userId}/${orderId}/submit`, requestBody);
    });
  },
  async updateOrderPicture(userId, orderId, pictureId, update) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(update),
    };
    return fetch(`${API_URL}/${userId}/${orderId}/pictures`, requestBody);
  },
  async resetOrderPicture(userId, orderId) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${API_URL}/${userId}/${orderId}/reset`, requestBody);
  },
  async deletePictureRefs(userId, orderId, pictureId) {
    const token = await getUserBearer();
    const requestBody = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return fetch(`${API_URL}/${userId}/${orderId}/pictures/${pictureId}`, requestBody);
  },
};
