import i18next from 'i18next';
import React, { Component } from 'react';

class Error extends Component {
  render() {
    return (
      <div className="checkoutMessage">
        <div className="checkOut">
          <h2>{i18next.t('paymentError.title')}</h2>
          <p dangerouslySetInnerHTML={{ __html: i18next.t('paymentError.text') }} />
        </div>
      </div>
    );
  }
}

export default Error;
