import './MembershipCard.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import subscriptionActions from '../../actions/subscriptionActions';
import { ISubscription } from '@klikkie/klikkie-schemas/lib/interfaces';

interface Props {
  subscription: ISubscription | undefined;
}

type SubscriptionStatus = 'active' | 'churned' | 'paused' | undefined;

const renderMembershipCard = (props: Props) => {
  const { subscription } = props;
  const { t } = useTranslation();
  const [memberSince, setMemberSince] = useState('');
  const [reactivationDate, setReactivationDate] = useState<string | undefined>(undefined);
  const [packageId, setPackageId] = useState<string | number>('');
  const [isPaused, setIsPaused] = useState<number | null | undefined>(undefined);
  const [scheduledEvents, setScheduledEvents] = useState<any[]>([]);
  const [actualSubscriptionStatus, setActualSubscriptionStatus] = useState<SubscriptionStatus>('active');

  const getScheduledEvents = async (userId: string) => {
    const events = await subscriptionActions.getScheduledEventData(userId);
    setScheduledEvents(events);
  };

  useEffect(() => {
    if (subscription) {
      workoutActualStatus(subscription, scheduledEvents);
    }
  }, [scheduledEvents, subscription]);

  const getReactivationDate = async () => {
    if (subscription && subscription.userId) {
      const { userId } = subscription;
      const reactiavtiondate = await subscriptionActions.getReactivationDate(userId);
      setReactivationDate(reactiavtiondate);
    }
  };

  const workoutActualStatus = async (subscription: ISubscription, scheduledEventData: any[]) => {
    const actualStatus = await subscriptionActions.workoutActualStatus(subscription, scheduledEventData);
    setActualSubscriptionStatus(actualStatus);
  };

  useEffect(() => {
    if (subscription) {
      const { createdAt, packageId, pausedAt, userId } = subscription;
      const memberSinceValue = moment(createdAt).format('DD MMMM YYYY');
      setMemberSince(memberSinceValue);
      if (packageId) {
        setPackageId(packageId);
      }
      setIsPaused(pausedAt);

      getReactivationDate();
      getScheduledEvents(userId || '');
    }
  }, [subscription]);

  const renderPlan = () => {
    if (!packageId) return null;
    if (actualSubscriptionStatus == 'churned') {
      return (
        <div className="labelItem">
          <p className="labelTitle">{t('app_manageSubscription:SubscriptionCard.plan.label')}</p>
          <p className="labelValue">
            {t(`app_manageSubscription:PackageDetail.${packageId}`)} (
            {t('app_manageSubscription:SubscriptionCard.cancelled.text')})
          </p>
        </div>
      );
    }
    return (
      <div className="labelItem">
        <p className="labelTitle">{t('app_manageSubscription:SubscriptionCard.plan.label')}</p>
        <p className="labelValue">{t(`app_manageSubscription:PackageDetail.${packageId}`)}</p>
      </div>
    );
  };

  return (
    <div className="MembershipCardContainer">
      <div className="titleContainer">
        <h2>{t('app_manageSubscription:SubscriptionCard.title')}</h2>
      </div>
      <div style={{ padding: 12 }}>
        <div className="labelItem">
          <p className="labelTitle">{t('app_manageSubscription:SubscriptionCard.memberSince.label')}</p>
          <p className="labelValue">{memberSince}</p>
        </div>
        {isPaused && reactivationDate && (
          <div className="labelItem">
            <p className="labelTitle">{t('app_manageSubscription:SubscriptionCard.pausedTill.label')}</p>
            <p className="labelTitle">{reactivationDate}</p>
          </div>
        )}
        {renderPlan()}
      </div>
    </div>
  );
};
renderMembershipCard.propTypes = {};
export default renderMembershipCard;
