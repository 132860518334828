import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackingClick } from '../actions/trackingActions';
import IntroSlide from '../components/IntroSlide/IntroSlide';
import SubmitSlide from '../components/SubmitSlide/SubmitSlide';
import ProductPackage from '../components/ProductPackage/ProductPackage';
import i18next from '../services/i18n';
import pictureOrderActions from '../actions/pictureOrderActions';
import NotSupportedFeature from '../helpers/NotSupportedFeature/NotSupportedFeature';
import { orderHasUnsupportedFormat } from '../utils/PictureTemplateUtils';
import Spinner from '../helpers/Spinner/Spinner';
import CircleMenu from '../helpers/CircleMenu/CircleMenu';
import './Order.scss';
import userActions from '../actions/userActions';

class Order extends Component {
  totalUploaded = 0;

  state = {
    order: null,
    uploadedTenPictures: false,
    uploadingCount: 0,
    isLoading: true,
    invalidTemplate: false,
    hasActiveAddress: false,
  };

  async UNSAFE_componentWillMount() {
    const { history, orderId, userId } = this.props;
    if (orderId === 'null') {
      return history.push({
        pathname: `/${global.language}/error`,
        state: {
          message: `${i18next.t('Order.noOrderPresent.message')} `,
        },
      });
    }
    if (this.orderRef) {
      this.orderRef.off('value');
    }
    const { metadata } = await userActions.getUser(userId);
    if (metadata && metadata.activeAddress) {
      this.setState({ hasActiveAddress: true });
    }
    await this.getPictureOrder();
    window.addEventListener('beforeunload', this.onUnload);
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
    if (this.orderRef) {
      this.orderRef.off('value');
    }
    if (this.totalProductsRef) {
      this.totalProductsRef.off('value');
    }
  }

  onUnload = (event) => {
    const { uploadingCount } = this.state;
    // Prevent Reload if still uploading
    if (uploadingCount > 0) {
      // eslint-disable-next-line no-param-reassign
      // TODO: LOZICE: add translation
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'Are you sure you want to leave?';
    }
  };

  getPictureOrder = async () => {
    try {
      const { userId, orderId, history, handleError, location } = this.props;
      const search = (location && location.search) || '';
      const order = await pictureOrderActions.getOrder(userId, orderId);
      this.setState({
        order,
        orderId: order.orderId,
        invalidTemplate: orderHasUnsupportedFormat(order),
      });
      if (!order) {
        handleError(i18next.t('Order.noOrderPresent.message'));
        return history.push({
          pathname: `/${global.language}/error`,
          state: {
            message: `${i18next.t('Order.noOrderPresent.message')} `,
          },
        });
      }
      // order already sent
      if (order && order.status === 'fulfilled') {
        return history.push(`/${global.language}/send/${orderId}/${search}`);
      }
      const totalPictures = await pictureOrderActions.countPictures(userId, orderId);
      const uploadedTenPictures = totalPictures >= 10;
      this.setState({
        uploadedTenPictures,
        isLoading: false,
      });
      return true;
    } catch (error) {
      trackingClick('Error', error);

      return false;
    }
  };

  changeUploadingCount = (value) => {
    this.setState((prevState) => {
      const newUploadingCount = prevState.uploadingCount + value;
      if (newUploadingCount > 0) {
        global.uploading = true;
      } else {
        global.uploading = false;
        this.getPictureOrder();
      }
      return {
        uploadingCount: newUploadingCount,
      };
    });
  };

  trackingAppDownloadHandler = (eventLabel) => {
    trackingClick(eventLabel);
    return this;
  };

  renderSupportedOrder(props, state) {
    const { userId, history, subscription, submitOrder, user } = props;
    const { order, orderId, uploadingCount, uploadedTenPictures, hasActiveAddress } = state;
    const picturesArr = order ? pictureOrderActions.orderPictures2BySizeObj(order.pictures) : null;
    const { status: orderStatus } = order || { status: '' };
    const metadata = order ? order.metadata : undefined;
    const sku = metadata ? metadata.sku : '200';

    return (
      <div className="order-wrapper">
        <CircleMenu />
        {user && order && (
          <div className={`order-container ${order.status === 'fulfilled' && 'order-container--order-ready'}`}>
            {orderStatus === 'pending' && <IntroSlide start={order.createdAt} userId={userId} />}

            <ProductPackage
              pictures={picturesArr}
              user={user}
              orderId={orderId}
              userId={userId}
              sku={sku}
              start={order.createdAt}
              changeUploadingCount={this.changeUploadingCount}
              loadPhotoSet={this.getPictureOrder}
            />

            <SubmitSlide
              orderId={orderId}
              subscription={subscription}
              uploadedTenPictures={uploadedTenPictures}
              uploadingCount={uploadingCount}
              hasActiveAddress={hasActiveAddress}
              func={submitOrder}
              userId={user.uid}
              user={user}
              size={order}
              history={history}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { isLoading, invalidTemplate } = this.state;

    return (
      <React.Fragment>
        {isLoading && <Spinner />}
        {invalidTemplate ? <NotSupportedFeature /> : this.renderSupportedOrder(this.props, this.state)}
      </React.Fragment>
    );
  }
}
Order.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};
export default Order;
