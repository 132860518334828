import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../helpers/Spinner/Spinner';
import i18next from '../services/i18n';
import pictureOrderActions from '../actions/pictureOrderActions';

class Home extends PureComponent {
  async UNSAFE_componentWillMount() {
    try {
      const { history, location, userId } = this.props;
      const search = (location && location.search) || '';

      if (!userId) {
        return false;
      }
      const orders = await pictureOrderActions.getUserOrdersAfterDayWithMessedOrders(userId);

      // Redirect to shop if no user has no orders yet
      if (!orders || orders.length === 0) {
        return history.replace(`/${global.language}/shop`);
      }

      // Filter orders that are not yet fulfilled so redirect by default to that one
      const nonFulfilledOrders = orders.filter(({ status }) => status !== 'fulfilled');

      // Get last non fulfilled order if exists or redirect to the first order cronologically
      const lastOrder = nonFulfilledOrders.length === 0 ? orders[0] : nonFulfilledOrders[0];
      //redirect code or order

      const searchParams = new URLSearchParams(search);
      let url = `/${global.language}/order/${lastOrder.orderId}${search && '/' + search}`;
      console.log(search);
      if (searchParams.get('redirect')) {
        url = `/${global.language}${searchParams.get('redirect') && '/' + searchParams.get('redirect')}`;
      }
      return history.replace(url);
    } catch (error) {
      const { handleError } = this.props;

      return handleError(i18next.t('NoOrderAvailable.message'));
    }
  }

  render() {
    return <Spinner />;
  }
}

Home.propTypes = {
  handleError: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default Home;
