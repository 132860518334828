import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';

export interface GoogleFontConfig {
  families: string[];
}

export interface WebFontConfig {
  google?: GoogleFontConfig;
  active?: () => void;
}

const useFontLoader = (fontConfig: WebFontConfig) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    WebFont.load({
      ...fontConfig,
      active: () => {
        setIsFontLoaded(true);
      },
    });
  }, [fontConfig]);

  return isFontLoaded;
};

export default useFontLoader;
