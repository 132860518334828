import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import useProduct from '../../utils/hooks/useProduct';
import { formatPrice } from '../../utils/CurrencyUtils';
import i18n from '../../services/i18n';
import './ProductLineItem.scss';

const ProductLineItem = (props: any) => {
  const { product } = props;
  const { price, currency, units, key: sku, status } = product;
  console.log({ product });
  const { products, error, isLoading } = useProduct();
  const { t } = useTranslation();

  const formattedPrice = formatPrice(price, currency, 'currency');

  if (sku === '0') return null;

  let description = '';
  if (i18n.exists(`products:${sku}.shortDescription`)) {
    description = t(`products:${sku}.shortDescription`);
  }

  const getFirstImage = (sku: any) => {
    if (products && products[sku] && products[sku].imageSrc) {
      const image = products[sku].imageSrc;
      if (typeof image === 'string') {
        return image;
      } else if (Array.isArray(image) && image.length > 0) {
        return image[0];
      } else {
        return '/img/product-fallback.png';
      }
    }

    return '/img/product-fallback.png';
  };

  return (
    <div className="product-line-item">
      <div className="product-image">
        <img className="product-image-item" src={getFirstImage(sku)} />
      </div>
      <div className="product-item">
        {units && (
          <div className="product-name">
            {units}x {t(`products:${sku}.title`)}
          </div>
        )}
        {!units && <div className="product-name">{t(`products:${sku}.title`)}</div>}

        <div className="product-description">{description}</div>
        <div className="product-status">{status === 'fulfilled' ? t('SmartSupport.paid') : status}</div>
      </div>
      <div className="product-price">
        <p className="price">{formattedPrice}</p>
      </div>
    </div>
  );
};

export default ProductLineItem;
