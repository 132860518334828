import { useEffect, useState } from 'react';
import fulfillmentActions from '../../actions/fulfillmentActions';

const useFulfillment = (userId, orderId) => {
  const [fulfillments, setFulfillment] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    fulfillmentActions
      .getFulfillmentsByOrderId(userId, orderId)
      .then((res) => {
        setFulfillment(res);
        setLoading(false);
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  }, []);

  return { fulfillments, error, isLoading };
};

export default useFulfillment;
