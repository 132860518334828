import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';
import './Profile.scss';
import SaveAnimation from '../../helpers/Spinner/SaveAnimation';
import userActions from '../../actions/userActions';
import { logout } from '../../actions/authActions';

import i18next from '../../services/i18n';

class ChangeEmail extends Component {
  state = {
    email: '',
    saving: false,
    loaded: false,
  };

  componentDidMount() {
    this.getEmail();
  }

  async getEmail() {
    const { userId } = this.props;
    const { profile } = await userActions.getUser(userId);
    this.setState({
      email: profile.email,
      loaded: true,
    });
  }

  validateField = () => {
    return this.true;
  };

  updateEmail = (e) => {
    e.preventDefault();
    this.setState({ saving: true });
    const { email } = this.state;
    const update = { email };
    userActions
      .updateEmail(update)
      .then(() => {
        this.setState({ saving: false });
      })
      .then(() => logout());
  };

  handleUserInput = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.validateField();
    });
  };

  render() {
    const { email, saving, loaded } = this.state;
    return (
      <form onSubmit={this.updateEmail}>
        {loaded && (
          <span>
            <fieldset>
              <h2 className="iconHeader">
                <IonIcon name="mail" className="ion-icon" />
                {i18next.t('myDetails.changeEmail.text')}
              </h2>
              {saving && <SaveAnimation />}
              <p>{i18next.t('myDetails.changeEmail.description')}</p>
              <label htmlFor="email">{i18next.t('myDetails.changeEmail.label')}</label>
              <div className="input-wrapper">
                <input
                  onChange={this.handleUserInput}
                  name="email"
                  id="email"
                  className="name"
                  type="email"
                  required
                  value={email}
                />
              </div>
              <div className="message">{i18next.t('SendToNewAddress.incomplete.text')}</div>
            </fieldset>
            <fieldset className="buttonContainer">
              <button id="sendOrderBt" className="button lined" type="submit">
                {i18next.t('myDetails.button')}
              </button>
            </fieldset>
          </span>
        )}
      </form>
    );
  }
}

ChangeEmail.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ChangeEmail;
