import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18next from '../../services/i18n';

const ProfileSegmentMenu = (props) => {
  const { menuItem } = props;

  return (
    <div style={{ display: 'contents' }}>
      <div className="profileMenu">
        <Link className="segment" to={{ pathname: `/${global.language}/profile/` }}>
          <div className={menuItem === 'profile' ? 'active' : 'normal'} value="profile">
            {i18next.t('myDetails.menu.profile')}
          </div>
        </Link>
        <Link className="segment" to={{ pathname: `/${global.language}/subscription/` }}>
          <div className={menuItem === 'subscription' ? 'active' : 'normal'} value="subscription">
            {i18next.t('myDetails.menu.subscription')}
          </div>
        </Link>
      </div>
    </div>
  );
};
ProfileSegmentMenu.propTypes = {
  menuItem: PropTypes.string.isRequired,
};
export default ProfileSegmentMenu;
