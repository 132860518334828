import React, { Component } from 'react';
import './Profile.scss';
import PropTypes from 'prop-types';
import DeleteAccount from './DeleteAccount';
import PersonalDetails from './PersonalDetails';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import ProfileAddressBook from './ProfileAddressBook';
import ProfileSegmentMenu from './ProfileSegmentMenu';
// eslint-disable-next-line react/prefer-stateless-function
class Profile extends Component {
  render() {
    return (
      <div className="profile">
        <ProfileSegmentMenu menuItem="profile" />
        <div className="details">
          <div className="detailsSection">
            <PersonalDetails {...this.props} />
          </div>
          <div className="detailsSection">
            <ProfileAddressBook {...this.props} />
          </div>
          <div className="detailsSection">
            <ChangeEmail {...this.props} />
          </div>
          <div className="detailsSection">
            <ChangePassword {...this.props} />
          </div>
          <div className="detailsSection">
            <DeleteAccount {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
Profile.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default Profile;
