import React from 'react';

const FrameClassic3x32T = ({ isSelected }: { isSelected: any }) => {
  const borderColor = isSelected ? '#18b2bb' : '#888888';
  const fillColor = isSelected ? '#c3f3e3' : '#d9d9d9';

  return (
    <svg width="65" height="92" viewBox="0 0 65 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="61" height="88" stroke={borderColor} strokeWidth="4" />
      <rect x="16" y="72" width="33" height="3" fill={fillColor} />
      <rect x="19" y="77" width="28" height="2" fill={fillColor} />
      <rect x="9" y="9" width="13" height="17" fill={fillColor} />
      <rect x="26" y="9" width="13" height="17" fill={fillColor} />
      <rect x="9" y="29" width="13" height="17" fill={fillColor} />
      <rect x="26" y="29" width="13" height="17" fill={fillColor} />
      <rect x="43" y="9" width="13" height="17" fill={fillColor} />
      <rect x="43" y="29" width="13" height="17" fill={fillColor} />
      <rect x="9" y="49" width="13" height="17" fill={fillColor} />
      <rect x="26" y="49" width="13" height="17" fill={fillColor} />
      <rect x="43" y="49" width="13" height="17" fill={fillColor} />
    </svg>
  );
};

export default FrameClassic3x32T;
