import { IProductPackage } from '@klikkie/klikkie-schemas/lib/interfaces';

export interface IProductPackageExtended extends IProductPackage {
  key: string;
  videoSrc: [string];
  metadata?: {
    maxPages?: number;
    minPages?: number;
    height?: number;
    width?: number;
    pageSku?: number;
    supplierProductId?: number;
    templates?: any[];
  };
}

export type Product = {
  key: string;
  title: string;
  description: string;
  textShort: string;
  imageSrc: [string];
  thumbSrc: [string];
  price: number;
  shippingCost: number;
  currency: string;
  type: string;
};

export type Category = {
  id: string;
  title: string;
  description: string;
  order: number | undefined;
  textShort: string;
  imageSrc: [string];
  thumbSrc: [string];
};

export type ProductTypeTestimonials = {
  title: string;
  text: string;
  user: string;
};

export const localStoragePrefix = '@k-webApp-product';

export const SUPPORTED_PRODUCT_CATEGORIES = ['coupon', 'pictures', 'product', 'scrapbooking', 'subscription', 'wall'];
