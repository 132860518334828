import * as numeral from 'numeral';
/**
 * Takes a value and the format string from locize and formats it accordingly
 * Supports endless divide and multiply
 *
 * Example params: value: 500, formatString: "€0.0,[00] divide:100"
 * First the value is divided by 100 => 5, then it is formatted using "€0.0,[00]" resulting in 5.00
 *
 *
 * @param value
 * @param formatString
 */
const formatCurrency = (value, formatString) => {
  let numberNumeral = numeral(value);
  const [format, ...extraOptions] = formatString.split(' ');
  extraOptions.forEach((option) => {
    const [operator, operand] = option.split(':');
    if (operator === 'divide') {
      numberNumeral = numberNumeral.divide(Number(operand));
    }
    if (operator === 'multiply') {
      numberNumeral = numberNumeral.multiply(Number(operand));
    }
  });
  return numberNumeral.format(format);
};
/**
 * Formats price using currency and user profile's language
 * Fallback to DUTCH
 *
 * @param {*} price
 * @param {*} currency
 * @param {*} user
 */
const formatPrice = (price, currency, style = 'decimal', discount = 0) => {
  if (price === undefined || !currency) {
    return price;
  }
  const parsePrice = parseFloat(price-discount) / 100;
  let language = global.language || 'nl-NL';
  // Split nl-NL into NL
  if (language.length > 2) {
    [, language] = language.split('-');
  }
  const parsedPrice = parsePrice.toLocaleString('en-US', {
    style,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency,
    currencyDisplay: 'symbol',
  });
  // Weird space is added, this will convert to regular space
  return parsedPrice.replace(String.fromCharCode([160]), ' ');
};
export { formatCurrency, formatPrice };
