import React from 'react';

const Postcard1x1 = ({ isSelected }: { isSelected: boolean }) => {
  const borderColor = isSelected ? '#18b2bb' : '#888888';
  const fillColor = isSelected ? '#c3f3e3' : '#d9d9d9';

  return (
    <svg width="60" height="86" viewBox="0 0 60 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="59" height="85" fill="white" stroke={borderColor} />
      <rect x="6" y="6" width="48" height="74" fill={fillColor} />
    </svg>
  );
};

export default Postcard1x1;