import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, withRouter } from 'react-router-dom';
import ProfileSegmentMenu from '../Profile/ProfileSegmentMenu';
import SubscriptionDetails from '../Profile/SubscriptionDetails';
import subscriptionActions from '../../actions/subscriptionActions';
import MembershipCard from '../MembershipCard/MembershipCard';
import SubscriptionBenefits from './SubscriptionBenefits';
import { ISubscription } from '@klikkie/klikkie-schemas/lib/interfaces';
import { database } from '../../services/firebase';
import { getLinksfromLocize } from '../../config';

const SubscriptionUpdated = (props: any) => {
  const { userId } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [showCancelFlow, setShowCancelFlow] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<number | null | undefined>(undefined);
  const [subscription, setSubscription] = useState<ISubscription | undefined>(undefined);
  const [showUpsell, setShowUpsell] = useState<boolean>(true);
  const cancelMembershipHandler = () => {
    setShowCancelFlow(!showCancelFlow);
  };

  useEffect(() => {
    console.log(subscription);
    if (subscription && !('createdAt' in subscription)) {
      console.log(!subscription?.createdAt);
      setShowUpsell(false);
    }
    if (subscription) {
      const { pausedAt } = subscription;
      setIsPaused(pausedAt);
    } else {
      setIsPaused(undefined);
    }
  }, [subscription]);

  const handleSubscriptionChangeResponse = async (snapshot: any | null) => {
    let updatedSubscription = snapshot.val();

    updatedSubscription = {
      ...updatedSubscription,
      userId,
    };
    setSubscription(updatedSubscription);
  };

  useEffect(() => {
    const onValueChange = database.ref(`Subscriptions/${userId}`).on('value', (snapshot) => {
      handleSubscriptionChangeResponse(snapshot);
    });

    // Stop listening for updates when no longer required
    return () => database.ref(`Subscriptions/${userId}`).off('value', onValueChange);
  }, []);

  useEffect(() => {}, [subscription]);

  if (showCancelFlow) {
    return (
      <div className="profile">
        <ProfileSegmentMenu menuItem="subscription" />

        <div className="details">
          <div className="detailsSection">
            <h2 onClick={cancelMembershipHandler}>Cancel Membership</h2>
            <button onClick={cancelMembershipHandler}>Cancel Membership</button>
          </div>
        </div>
      </div>
    );
  }

  const unPauseHandler = async () => {
    if (!confirm(t('app_manageSubscription:MembershipPaused.confirmReactivation'))) {
      return false;
    }
    console.log('unPauseHandler');
    try {
      // useAnalytics().trackEvent('unpause_alert_unpause_selected');
      // setIsUnpausing(true);
      const response = await subscriptionActions.unPauseSubscription(userId);
      // setIsUnpausing(false);
      console.log({ response });
      const { pausedAt } = response;
      setIsPaused(pausedAt);
      if (!pausedAt) {
        // setDisplayModal({ modal: 'subscription-unpaused' });
      } else {
        // Alert.alert(t('app_error:General.Error.title'), t('app_error:General.Error.description'));
      }
      history.push(`/${global.language}/membership-reactivated`);
    } catch (error) {
      // setIsUnpausing(false);
      // Alert.alert(t('app_error:General.Error.title'), t('app_error:General.Error.description'));
    }
  };

  const renderButton = () => {
    const { status } = subscription || { status: undefined };
    const links = getLinksfromLocize();
    console.log({ isPaused, status });
    if (status && status == 'active') {
      return (
        <div className="details">
          <div className="detailsSection" style={{ marginTop: 40 }}>
            <Link className="segment" to={{ pathname: `/${global.language}/cancel/` }}>
              <button id="sendOrderBt" className="button lined" type="submit">
                {t('app_manageSubscription:ManageSubscription.cancelSubscription.label')}
              </button>
            </Link>
          </div>
        </div>
      );
    } else if (isPaused) {
      return (
        <div className="details">
          <div className="detailsSection" style={{ marginTop: 40 }}>
            <button id="sendOrderBt" className="button lined" type="submit" onClick={unPauseHandler}>
              {t('app_manageSubscription:Subscription.unpause.button')}
            </button>
          </div>
        </div>
      );
    } else if (!showUpsell) {
      return (
        <div className="details">
          <div className="detailsSection" style={{ marginTop: 40 }}>
            <a className="segment" href={links.site.toString()}>
              <button id="sendOrderBt" className="button lined" type="submit">
                {t('Subscription.website.text')}
              </button>
            </a>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="profile">
      <ProfileSegmentMenu menuItem="subscription" />
      {!showUpsell && <SubscriptionBenefits />}
      {showUpsell && (
        <span>
          <MembershipCard subscription={subscription} />
          <SubscriptionBenefits />
          <hr />
        </span>
      )}
      {showUpsell && <SubscriptionDetails {...props} />}
      {renderButton()}
    </div>
  );
};

export default withRouter(SubscriptionUpdated);
