import React, { Component } from 'react';
import PropTypes from 'prop-types';
import addressActions from '../../actions/addressActions';
import paymentActions from '../../actions/paymentActions';
import klikkieLogo from '../../img/klikkie-logo.png';
import CountryUtils from '../../utils/CountryUtils';
import { formatPrice } from '../../utils/CurrencyUtils';
import './Invoice.scss';
import i18next from '../../services/i18n';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      currency: '',
      products: {},
      address: '',
      payment: {},
      loaded: false,
    };
  }

  async componentDidMount() {
    const { order } = this.props;
    try {
      await paymentActions.getUserPayment(order.id).then((result) => this.setState({ payment: result }));
      addressActions.getAddress(order.userId, order.invoiceAddressId).then((result) => {
        this.setState({
          order,
          products: order.products,
          currency: order.currency,
          address: result,
          loaded: true,
        });
      }).catch(() => {
        this.setState({
          order,
          products: order.products,
          currency: order.currency,
          loaded: true,
        });
      });
    } catch (error) {
      console.info(error);
      this.setState({ loaded: true });
    }
  }

  componentWillUnmount() {
    this.setState({ loaded: false });
  }

  convertPrice(price) {
    const { currency } = this.state;
    return formatPrice(price, currency);
  }

  render() {
    const { order, products, address, payment, loaded } = this.state;
    const updatedAt = new Date(order.updatedAt).toLocaleDateString();
    if (!loaded) {
      return false;
    }
    return (
      <section className="invoicePage">
        <div className="invoiceHeader">
          <div className="address">
            <img className="logo" src={klikkieLogo} alt="logo" />
            <br />
            klikkie BV
            <br />
            Herengracht 577
            <br />
            1017 CD Amsterdam
            <br />
            The Netherlands
            <br />
            VAT NL 8576 14289 B01
          </div>
          <div className="date">
            {i18next.t('Invoices.invoice.date')} {new Date().toLocaleDateString()}
            <br />
            {i18next.t('Invoices.invoice.order.date')} {updatedAt}{' '}
          </div>
        </div>
        <table className="order">
          <tbody>
            <tr className="title">
              <td colSpan="3">
                {' '}
                <h4>
                  order:
                  {order.id}
                </h4>
              </td>
            </tr>
            <tr />
            {order &&
              Object.keys(products).map((product) => (
                <tr key={product}>
                  <td>
                    {products[product].units} x <span className="uppercase"> {products[product].name} </span>
                    <small>{products[product].units > 1 && this.convertPrice(products[product].unitPrice)}</small>
                  </td>
                  <td />
                  <td>{this.convertPrice(products[product].total)}</td>
                </tr>
              ))}
            {order.totalDiscount > 0 && (
              <>
                <tr>
                  <td className="colorSpan">Total Discount: </td>
                  <td />
                  <td> {this.convertPrice(order.totalDiscount)}</td>
                </tr>
                <tr>
                  <td className="colorSpan">Total Pre Discount: </td>
                  <td />
                  <td>{this.convertPrice(order.totalPreDiscount)}</td>
                </tr>
              </>
            )}
            {order.totalPrepaid > 0 && (
              <tr>
                <td className="colorSpan">Total Pre Paid:</td>
                <td />
                <td>{this.convertPrice(order.totalPrepaid)}</td>
              </tr>
            )}
            <tr>
              <td className="colorSpan"> {i18next.t('Invoices.invoice.shipping')} : </td>
              <td />
              <td>{this.convertPrice(order.totalShipping)}</td>
            </tr>
            <tr className="total">
              <td className="colorSpan"> {i18next.t('Invoices.invoice.total')} : </td>
              <td />
              <td>{this.convertPrice(order.totalToCharge)}</td>
            </tr>
            <tr>
              <td className="colorSpan"> {i18next.t('Invoices.invoice.vat')}: </td>
              <td />
              <td>{this.convertPrice(order.totalVat)}</td>
            </tr>
          </tbody>
        </table>
        {payment && (
          <table className="payment">
            <tbody>
              <tr className="title">
                <td colSpan="2">
                  {' '}
                  <h4>{i18next.t('Invoices.invoice.title.payment')} </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="colorSpan">{i18next.t('Invoices.invoice.paymentMethod')}:</span>{' '}
                  {payment.paymentMethod || 'undefined'}
                </td>
                <td>
                  <span className="colorSpan">{i18next.t('Invoices.invoice.paymentDate')}:</span>{' '}
                  {new Date(payment.updatedAt).toLocaleDateString()}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {address && (
          <table className="address">
            <tbody>
              <tr className="title">
                <td>
                  {' '}
                  <h4>{i18next.t('Invoices.invoice.title.address')} </h4>
                </td>
              </tr>
              <tr>
                <td>
                  {address.fullname || address}
                  <br />
                  {address.address}
                  <br />
                  {address.address2}
                  <br />
                  {address.region}
                  <br />
                  {address.zipcode}
                  <br />
                  {address.city}
                  <br />
                  {CountryUtils.getCountryFromCountryCode(address.countryCode)}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </section>
    );
  }
}
Invoice.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
};

export default Invoice;
