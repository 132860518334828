import './PictureEditor/PictureEditor.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';

import imageBadQuality from '../../img/bad-quality-picture-klikkie.jpg';
import i18next from '../../services/i18n';

const ModalQualityInfo = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <div className="qualityModal">
      <div className="section-header">
        <h1>{i18next.t('ModalQualityInfo.header.text')}</h1>
      </div>
      <p>{i18next.t('ModalQualityInfo.body1.text')}</p>
      <img className="u-responsive-image" src={imageBadQuality} alt={i18next.t('ModalQualityInfo.image.alt')} />
      <h3>{i18next.t('ModalQualityInfo.subheader.text')}</h3>

      <p>{i18next.t('ModalQualityInfo.body2.text')}</p>
    </div>
  </Modal>
);

ModalQualityInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalQualityInfo;
