import React, { useState } from 'react';
import './HelpMenu.scss';
import IonIcon from '@reacticons/ionicons';
import TranslatedLink from '../../Link/TranslatedLink';
import PropTypes from 'prop-types';
import i18next from 'i18next';

const HelpMenuOption = ({ option, onOptionClick }) => {
  return (
    <div className="helpOption" onClick={onOptionClick}>
      {option.route ? (
        <TranslatedLink to={option.route}>
          <div className="helpOptionName">{option.name}</div>
        </TranslatedLink>
      ) : (
        <a href={option.url} target="_blank" rel="noopener noreferrer">
          <div className="helpOptionName">{option.name}</div>
        </a>
      )}
    </div>
  );
};

HelpMenuOption.propTypes = {
  option: PropTypes.shape({
    route: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

const HelpMenu = ({ toggleMenuHandler }) => {
  const [display, setDisplay] = useState(false);

  const helpOptions = [
    { id: 'orderHelp', name: i18next.t('SmartSupport.helpWithOrder'), route: '/orderHistory' },
    { id: 'generalHelp', name: i18next.t('SmartSupport.generalHelp'), url: 'https://www.klikkie.com/contact' },
  ];

  const toggleDisplay = () => setDisplay(!display);

  const handleOptionClick = () => {
    toggleDisplay();
    toggleMenuHandler();
  };

  return (
    <div className="helpMenuContainer">
      <div className="helpTitle" role="presentation" onClick={toggleDisplay}>
        <div className="helpName">{i18next.t('SmartSupport.help')}</div>
        <div className="helpDetails">
          <div className="helpArrow">
            <IonIcon name={display ? 'chevron-up-outline' : 'chevron-down-outline'} />
          </div>
        </div>
      </div>

      {display && (
        <div className="helpOptionsContainer is-active">
          {helpOptions.map((option) => (
            <HelpMenuOption key={option.id} option={option} onOptionClick={handleOptionClick} />
          ))}
        </div>
      )}
    </div>
  );
};

HelpMenu.propTypes = {
  toggleMenuHandler: PropTypes.func.isRequired,
};

export default HelpMenu;
