import React, { Component } from 'react';
import IonIcon from '@reacticons/ionicons';
import PropTypes from 'prop-types';
import i18next from 'i18next';
class DeleteAccount extends Component {
  state = {
    saving: false,
  };
  openDelete = () => {
    const { history } = this.props;
    history.push(`/${global.language}/delete/`);
  };
  render() {
    const { saving } = this.state;
    return (
      <div>
        <fieldset>
          <h2 className="iconHeader red">
            <IonIcon className="ion-icon " name="close" />
            {i18next.t('Profile.DataRemoval.title')}
          </h2>

          <p>{i18next.t('Profile.DataRemoval.body')}</p>

          <div className="buttonContainer">
            <button id="sendOrderBt" className="button lined " onClick={this.openDelete}>
              {i18next.t('Profile.DataRemoval.button')}
            </button>
          </div>
        </fieldset>
      </div>
    );
  }
}
DeleteAccount.propTypes = {
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
export default DeleteAccount;
