import { storage } from '../services/firebase';

const MAX_STORAGE_RETRY_TIME = 60 * 1000;

storage.setMaxOperationRetryTime(MAX_STORAGE_RETRY_TIME);
storage.setMaxUploadRetryTime(MAX_STORAGE_RETRY_TIME);

export default {
  // TODO: update v0 2 pictures after migration.
  getBucket: () => process.env.REACT_APP_STORAGE_BUCKET,
  getUserPicturesRoute: (userId, orderId) => `user/${userId}/userpictures/${orderId}`,
  getUserSupportRoute: (userId, orderId) => `support/${userId}/${orderId}`,
  getOrderPicturesRoute: (userId, orderId) => `user/${userId}/pictureorders/${orderId}`,
  thumbnailRef: (originalRef) => originalRef.replace('pictures/', 'pictures/thumb_'),
  getStorageRef() {
    return storage.ref();
  },

  deleteStorageFile(url) {
    return this.existsStorageFile(url).then((exists) => {
      if (exists) {
        const fileRef = storage.ref(url);
        return fileRef.delete();
      }

      return null;
    });
  },

  async existsStorageFile(url) {
    try {
      const fileRef = storage.ref(url);

      await fileRef.getDownloadURL();

      return true;
    } catch (error) {
      return false;
    }
  },

  async uploadedStorageFile(url) {
    const fileRef = storage.ref(url);
    const { size } = await fileRef.getMetadata();

    if (size > 0) {
      return true;
    }

    throw new Error('File not uploaded');
  },

  async getPictureUpdatedAt(ref) {
    try {
      const fileRef = storage.ref(ref);
      const metadata = await fileRef.getMetadata();

      const updatedDate = new Date(metadata.updated);

      return updatedDate.getTime();
    } catch {
      return Date.now();
    }
  },

  async getPictureByRef(ref) {
    const fileRef = storage.ref(ref);

    const localStoragePrefix = '@k-webApp-pictureCache';
    const updatedAt = await this.getPictureUpdatedAt(ref);
    const localStorageKey = `${localStoragePrefix}-${ref}-${updatedAt}`;

    const localStorageUrl = localStorage.getItem(localStorageKey);

    if (localStorageUrl) {
      return localStorageUrl;
    }

    const downloadUrl = await fileRef.getDownloadURL();

    localStorage.setItem(localStorageKey, downloadUrl);

    return downloadUrl;
  },

  getPictureMetadata(ref) {
    const fileRef = storage.ref(ref);
    return fileRef.getMetadata();
  },
};
