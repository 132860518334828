import './OfferCardStyle.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

import image from './img/5_photos.png';

interface Props {
  selectHandler: () => void;
}

const Offer_5Photos = (props: Props) => {
  const { selectHandler } = props;
  const { t } = useTranslation();

  const continueHandler = () => {
    selectHandler();
  };

  return (
    <div className="offerCardContainer">
      <div className="topImage">
        <img src={image} alt="" />
      </div>

      <div className="descriptionContainer">
        <h2>{t('app_manageSubscription:Offer_5Prints.title')}</h2>

        <p>{t('app_manageSubscription:Offer_5Prints.description')}</p>
        <div className="priceContainer">
          <div className="priceLabel">
            <h2>{t('app_manageSubscription:Offer_5Prints.price')}</h2>
            <h2>pm</h2>
          </div>
        </div>
        <div>
          <button className="button lined" type="submit" onClick={continueHandler}>
            {t('app_manageSubscription:Offer_5Prints.button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Offer_5Photos;
