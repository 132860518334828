import './CircleMenu.scss';
import React, { Component } from 'react';
import i18next from 'i18next';
import TranslatedLink from '../../components/Link/TranslatedLink';
import { trackingClick } from '../../actions/trackingActions';
import myorder from '../../img/menu/myOrders.jpg';
import shop from '../../img/menu/shop.jpg';
import invite from '../../img/menu/invite.jpg';

class CircleMenu extends Component {
  trackingClickHandlerGenerator = (eventLabel) => () => {
    trackingClick('CircleMenu', eventLabel);

    return this;
  };

  render() {
    return (
      <div className="circleMenu">
        <div className="circleMenu__item">
          <TranslatedLink
            to="/shop"
            onClick={() => {
              trackingClick('menu', 'shop');
            }}
          >
            <div className="imageHolder">
              <img src={shop} alt="" />
            </div>
            <div className="name">{i18next.t('Menu.shop.text')}</div>
          </TranslatedLink>
        </div>
        <div className="circleMenu__item">
          <TranslatedLink
            to="/invite"
            onClick={() => {
              trackingClick('menu', 'mgm');
            }}
          >
            <div className="imageHolder">
              <img src={invite} alt="" />
            </div>
            <div className="name">{i18next.t('Menu.invite')}</div>
          </TranslatedLink>
        </div>
        <div className="circleMenu__item">
          <TranslatedLink
            to="/orders"
            onClick={() => {
              trackingClick('menu', 'orders');
            }}
          >
            <div className="imageHolder">
              <img src={myorder} alt="" />
            </div>
            <div className="name"> {i18next.t('Menu.myMonths.text')}</div>
          </TranslatedLink>
        </div>
      </div>
    );
  }
}
export default CircleMenu;
