import classNames from 'classnames';
import FrameClassic1x1 from '../Collage/FrameClassic1x1';
import FrameClassic2x22T from '../Collage/FrameClassic2x22T';
import FrameClassic3x32T from '../Collage/FrameClassic3x32T';
import Postcard1x1 from '../Collage/Postcard1x1';
import Postcard1x12TT from '../Collage/Postcard1x12TT';
import Postcard1x12TB from '../Collage/Postcard1x12TB';

interface CollageMenuProps {
  handleCollageClick: (collage: string) => void;
  selectedCollage: string;
  productType: string;
}

export const CollageMenu: React.FC<CollageMenuProps> = ({
  handleCollageClick,
  selectedCollage,
  productType,
}) => {
  const renderCollageItem = (collageName: string, Component: React.FC<{ isSelected: boolean }>) => (
    <div
      className={classNames('collageItem', { selected: selectedCollage === collageName })}
      onClick={() => {
        if(collageName !== selectedCollage) {
          handleCollageClick(collageName);
        }
      }}
    >
      <Component isSelected={selectedCollage === collageName} />
    </div>
  );

  return (
    <>
      {productType === 'card' && (
        <>
          {renderCollageItem('Postcard-1x1', Postcard1x1)}
          {renderCollageItem('Postcard-1x1-2TT', Postcard1x12TT)}
          {renderCollageItem('Postcard-1x1-2TB', Postcard1x12TB)}
        </>
      )}
      {productType === 'wall' && (
        <>
          {renderCollageItem('Frame-Classic-1x1', FrameClassic1x1)}
          {renderCollageItem('Frame-Classic-2x2-2T', FrameClassic2x22T)}
          {renderCollageItem('Frame-Classic-3x3-2T', FrameClassic3x32T)}
        </>
      )}
      {productType === 'canvas' && (
        <>
          {renderCollageItem('Frame-Canvas-1x1', FrameClassic1x1)}
        </>
      )}
    </>
  );
};
