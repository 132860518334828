import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import i18next from '../../services/i18n';
import MobileAppCTA from '../MobileAppCTA/MobileAppCTA';

import './NotSupportedFeature.scss';
import { trackingClick } from '../../actions/trackingActions';

class NotSupportedFeature extends Component {
  totalUploaded = 0;

  render() {
    const appTitle = i18next.t('AppCTA.title');
    const appMessage = i18next.t('AppCTA.featureNotAvailableBody');
    const appFeatureOnApp = i18next.t('AppCTA.featureNotAvailableTitle');

    return (
      <div className="flex-wrapper NotSupported">
        <div className="flex-container">
          <h1 className="NotSupported__title">{appFeatureOnApp}</h1>

          <div className="NotSupported__content">
            <MobileAppCTA
              title={appTitle}
              message={appMessage}
              className="containerSendSlide"
              onClick={trackingClick('order_submitted', 'not_supported_feature')}
            />
          </div>
        </div>
      </div>
    );
  }
}

// NotSupportedFeature.propTypes = {};

export default NotSupportedFeature;
