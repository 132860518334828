import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { resolveLanguageAndCountry } from './services/i18n';
import { allowedLanguageAndCountryCodes } from './services/i18n';

const LanguageRedirect = ({ pathname, search }) => {
  const [resolvedLanguage, setResolvedLanguage] = useState(null);

  useEffect(() => {
    resolveLanguageAndCountry(global.language)
      .then((language) => {
        setResolvedLanguage(language);
      })
      .catch((error) => {
        console.error('Error resolving language and country:', error);
        setResolvedLanguage('en-EU');
      });
  }, []);

  if (!resolvedLanguage) {
    return null;
  }

  if (pathname.startsWith('/_') && !new RegExp(`/_/${allowedLanguageAndCountryCodes.join('|')}`).test(pathname)) {
    const newPath = pathname.replace(/^\/_\/?/, '');
    return <Redirect to={`/_/${resolvedLanguage}/${newPath}${search}`} />;
  }

  const split = pathname.includes('/') ? pathname.split('/') : pathname;
  const path = split[1];
  return <Redirect to={`/${resolvedLanguage}/${path}${search}`} />;
};

export default LanguageRedirect;
