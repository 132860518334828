export interface Font {
  name: string;
  resize: number;
}

export const fontsList: Font[] = [
  {
    name: 'Crimson Text',
    resize: 1,
  },
  {
    name: 'Cinzel',
    resize: 1,
  },
  // {
  //   name: 'Cormorant',
  //   resize: 1.2,
  // },
  {
    name: 'Cutive',
    resize: 0.9,
  },
  // {
  //   name: 'Homemade Apple',
  //   resize: 1,
  // },
  // {
  //   name: 'Josefin Sans',
  //   resize: 1.2,
  // },
  {
    name: 'Julius Sans One',
    resize: 1,
  },
  // {
  //   name: 'Kalam',
  //   resize: 1.2,
  // },
  // {
  //   name: 'Merriweather',
  //   resize: 1,
  // },
  {
    name: 'Ms Madi',
    resize: 1.5,
  },
];
