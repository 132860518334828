import './AddressBook.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { trackingClick } from '../../actions/trackingActions';
import addressActions from '../../actions/addressActions';
import userActions from '../../actions/userActions';
import pictureOrderActions from '../../actions/pictureOrderActions';
import orderActions from '../../actions/orderActions';
import productsActions from '../../actions/productsActions';
import { formatPrice } from '../../utils/CurrencyUtils';
import Spinner from '../../helpers/Spinner/Spinner';
import CheckBox from './CheckBox';
import i18next from '../../services/i18n';
import paymentActions from '../../actions/paymentActions';

class AddressBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orderId: '',
      userId: '',
      addresses: {},
      addressSelection: [],
      countryCode: '',
      currency: 'EUR',
      formattedPrice: 0,
      productPrice: 0,
      sku: 200,
      total: 0,
      activeAddress: '',
      metadata: {
        platform: 'web',
        version: process.env.REACT_APP_VERSION,
        name: '',
      },
      redirect: false,
      showCheckBoxes: false,
    };
    this.checkboxRefs = [];
  }

  async componentDidMount() {
    const { language } = global;
    const countryCode = language.slice(3);
    const { user, orderId } = this.props;
    const { uid: userId } = user;
    this.setState({
      userId,
      orderId,
      countryCode,
    });
    try {
      const addresses = (await addressActions.getAddresses(userId)) || {};
      const prices = await productsActions.getProducts();
      const activeAddress = await userActions.getUser(userId).then((r) => (r.metadata || {}).activeAddress);
      const newSku = await pictureOrderActions.getOrder(userId, orderId).then((result) => result.metadata.sku);
      const skuToLookUp = newSku === '901' ? 200 : newSku;
      const currency = prices[skuToLookUp].currency[countryCode];
      const price = prices[skuToLookUp].price[countryCode];
      this.setState({
        currency,
        addresses,
        activeAddress,
        productPrice: price,
        formattedPrice: formatPrice(price, currency),
        showCheckBoxes: true,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
    this.orderFullfilled(userId, orderId);
  }

  sendOrder = async () => {
    try {
      const { addressSelection } = this.state;
      trackingClick('addressbook', 'fulfil_order');
      if (addressSelection.length === 1) {
        await this.pictureOrder(addressSelection[0]);
        this.goToSendPage();
      } else {
        await this.extraOrders(addressSelection);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  goToSendPage = () => {
    trackingClick('fulfil_order');
    const { history } = this.props;
    const { orderId } = this.state;
    history.replace(`/${global.language}/send/${orderId}`);
  };

  addAddress = (addressId) => {
    const { addressSelection, productPrice } = this.state;
    const totalPrice = addressSelection.length * productPrice;
    this.setState({ addressSelection: [...addressSelection, addressId], total: totalPrice });
  };

  removeAddress = (addressId) => {
    const { addressSelection: oldAddressSelection, productPrice } = this.state;
    const addressSelection = oldAddressSelection.filter((address) => address !== addressId);
    const totalPrice = (addressSelection.length - 1) * productPrice;
    this.setState({ addressSelection, total: totalPrice });
  };

  async pictureOrder(address) {
    try {
      const { metadata, userId, orderId } = this.state;
      const update = { addressId: address, status: 'fulfilled' };
      await pictureOrderActions.updateOrder(userId, orderId, update);
      await pictureOrderActions.submitOrder(userId, orderId, metadata);
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  async extraOrders(orderAddresses) {
    const addresses = orderAddresses;
    const freeOrder = addresses.shift();
    try {
      const { countryCode, userId, orderId, sku } = this.state;
      await this.pictureOrder(freeOrder);
      const result = await orderActions.createReprintsOrder(userId, countryCode, sku, addresses, orderId);
      return paymentActions.gotoCheckOut(result.id);
    } catch (error) {
      return error;
    }
  }

  async orderFullfilled(userId, orderId) {
    const totalPictures = await pictureOrderActions.countPictures(userId, orderId);
    const uploadedTenPictures = totalPictures >= 10;
    if (!uploadedTenPictures) {
      this.setState({ redirect: true });
    }
    return true;
  }

  render() {
    const {
      addresses,
      orderId,
      showCheckBoxes,
      currency,
      addressSelection,
      activeAddress,
      formattedPrice,
      total,
      redirect,
      loading,
    } = this.state;
    if (redirect) {
      return <Redirect to={`/${global.language}/`} />;
    }
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="addressBook">
        <h2>Address Book</h2>
        <Link
          className="addAddress"
          to={{
            pathname: `/${global.language}/address/${orderId}`,
            state: { orderId },
          }}
        >
          <div className="circle">+</div>
          <span>Add address</span>
        </Link>
        <div className="book">
          {showCheckBoxes &&
            Object.entries(addresses).map(([key, address]) => (
              <CheckBox
                {...address}
                key={key}
                addAddress={this.addAddress}
                removeAddress={this.removeAddress}
                addressSelection={addressSelection}
                price={formattedPrice}
                defaultCheck={activeAddress}
              />
            ))}
        </div>
        {addressSelection.length < 1 && (
          <div className="addressValidationWrapper">Please select an address to proceed.</div>
        )}
        <button onClick={this.sendOrder} className="button fixed" type="button" disabled={addressSelection.length < 1}>
          {total <= 0
            ? i18next.t('AddressBook.button.free')
            : i18next.t('AddressBook.button.pay') + formatPrice(total, currency)}
        </button>
      </div>
    );
  }
}
AddressBook.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types

  orderId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};
export default AddressBook;
