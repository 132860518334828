import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

export default {
  async getPictureTemplates() {
    try {
      const requestBody = {
        method: 'GET',
      };

      return fetch(`${apiBaseUrl}/pictureTemplates`, requestBody);
    } catch (error) {
      console.error(error);

      return null;
    }
  },
  async getPictureTemplate(pictureTemplateId) {
    try {
      const token = await getUserBearer();
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${apiBaseUrl}/pictureTemplates/${pictureTemplateId}`, requestBody);
    } catch (error) {
      console.error(error);

      return null;
    }
  },
};
