import React from 'react';
import PropTypes from 'prop-types';
import './Notify.scss';

const Notify = ({ text }) => (
  <div className="notify">
    {' '}
    <div className="notify__close">&times;</div>
    {text}
  </div>
);

Notify.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Notify;
