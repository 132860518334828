/**
 * Removes the prefix by splitting based on the prefix
 *
 * Split is limited to 2 so the split doesn't remove other
 * The first value after split will always be empty
 * The returned value is always the second item in the array at index 1
 *
 * @param str
 * @param prefix
 */
export const removePrefix = (str, prefix) => {
  if (str.startsWith(prefix)) {
    return str.slice(prefix.toString().length);
  }

  return str;
};

export const concat = (str1, str2) => `${str1}/${str2}`;
