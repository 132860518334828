import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer } from './authActions';

const API_URL = `${apiBaseUrl}/fulfillment/v2`;

export default {
  getFulfillment(fulfillmentId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/${fulfillmentId}`, requestBody);
    });
  },
  getFulfillmentsByOrderId(userId, orderId) {
    return getUserBearer().then((token) => {
      const requestBody = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}/order/${userId}/${orderId}`, requestBody);
    });
  },
};
