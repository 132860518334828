import React, { useState, useEffect, useRef, useMemo } from 'react';
import CheckoutPresentational from './CheckoutPresentational';
import productsActions from '../../actions/productsActions';
import userActions from '../../actions/userActions';
import paymentActions from '../../actions/paymentActions';
import { trackingClick } from '../../actions/trackingActions';

export const PHOTOSETS_SKUS = [
  '200', // ORIGINAL SET
  '250', // NO BIGGIE SET
  '251', // MEDIUM AND SQUARES
  '252', // SQUARES ONLY
];

export const SUBSCRIPTION_FAMILY_PRODUCT_SKUS = [
  '8', // FAMILY - 1 MONTH
  '9', // FAMILY - 12 MONTH
];

export const SUBSCRIPTION_CLASSIC_PRODUCT_SKUS = [
  '2', // CLASSIC - 1 MONTH
  '3', // CLASSIC - 12 MONTHS + Starter Set
  '4', // CLASSIC - 6 MONTHS
  '5', // CLASSIC - 3 MONTHS
  '7', // CLASSIC - 7 MONTHS
];

export const SUBSCRIPTION_POCKETBOOK_PRODUCT_SKUS = [
  '20', // POCKET BOOK - 1 MONTH
];

export const SKUS_THAT_DONT_REQUIRE_ADDRESS = [
  ...SUBSCRIPTION_CLASSIC_PRODUCT_SKUS,
  ...SUBSCRIPTION_FAMILY_PRODUCT_SKUS,
  ...SUBSCRIPTION_POCKETBOOK_PRODUCT_SKUS,
  ...PHOTOSETS_SKUS,
  '0',
];

const localStoragePrefix = '@k-webApp-product';

const Checkout = (props: any) => {
  const { packageId, userId, history, match } = props;
  // Rest is the rest of the url /product/:id/:rest
  const { rest: paymentStatus } = match.params as { rest: 'checkout' | 'success' | 'failed' };
  const [loading, setLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<any>({});
  const addressId = useRef<string>('');

  const requiresAddress = useMemo(() => {
    let needAddress = false;
    if (!SKUS_THAT_DONT_REQUIRE_ADDRESS.includes(packageId)) {
      needAddress = true;
    }
    if (packageId === '3') {
      // Subscription with Start Set
      needAddress = true;
    }

    return needAddress;
  }, [packageId]);

  const loadProductData = async () => {
    const product = await productsActions.getFormattedProduct(packageId);
    const user = await userActions.getUser(userId);
    setProduct(product);
    setLoading(false);

    // Save user & product to cache
    localStorage.setItem(`${localStoragePrefix}-user`, JSON.stringify(user));
    localStorage.setItem(`${localStoragePrefix}-${packageId}`, JSON.stringify(product));

    console.log({ product });
  };

  useEffect(() => {
    // Get product from cache
    const localStorageProduct = localStorage.getItem(`${localStoragePrefix}-${packageId}`);
    // Get user from cache
    const localStorageUser = localStorage.getItem(`${localStoragePrefix}-user`);

    if (localStorageProduct) {
      const newProduct = JSON.parse(localStorageProduct);
      console.log({ localStorageProduct, newProduct });
      //   this.setState({
      //     product: newProduct,
      //     isLoading: false,
      //   });
    }
    if (localStorageUser) {
      const user = JSON.parse(localStorageUser);
      //   this.setState({
      //     user,
      //   });
    }

    loadProductData();
  }, []);

  const buyProduct = async () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    trackingClick('checkout', 'confirm');

    // setLoading(true);

    try {
      console.log('buyProduct...1', userId, packageId, addressId.current);
      // Await the async call to order the product with recurrent details
      const order = await paymentActions.orderProductWithRecurrentDetails(userId, packageId, addressId.current);
      console.log('buyProduct...SUCCESS', { order });
      trackingClick('checkout', 'purchase', order);
      trackingClick('checkout', 'purchase:success');
      history.push(`/${global.language}/product/${packageId}/success`);
    } catch (error) {
      console.log('buyProduct...error', { error });
      // Catch any errors that occur during the order process
      trackingClick('checkout', 'purchase:failed');
      history.push(`/${global.language}/product/${packageId}/failed`);
    }
  };

  const selectAddressHandler = (selectedAddressId: string) => {
    addressId.current = selectedAddressId;
  };

  const backToHomeHandler = () => {
    trackingClick('confirmation', 'shop');
  };

  const tryAgainHandler = () => {
    window.history.back(); 
  };

  return (
    <CheckoutPresentational
      product={product}
      isLoading={loading}
      user={{ userId }}
      paymentStatus={paymentStatus ?? 'checkout'}
      selectAddressHandler={selectAddressHandler}
      buyHandler={buyProduct}
      backToHomeHandler={backToHomeHandler}
      addressRequired={requiresAddress}
      tryAgainHandler={tryAgainHandler}
    />
  );
};

export default Checkout;
