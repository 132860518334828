/**
 * The upload photo order was setted by a
 * firebase becoming object. We needed different
 * order so here is this util.
 *
 * @param {Object} photoObj
 * @returns {Object} object ordered by ['XL', 'M', 'Strip', 'Mini']
 */
export const imageUploadDisplayOrder = (photosObj) => {
  const order = ['xl', 'medium', 'strip', 'mini'];
  const orderedOutput = {};

  order.map((size) => {
    if (photosObj[size]) {
      orderedOutput[size] = photosObj[size];
    }
    return orderedOutput;
  });

  return orderedOutput;
};

/**
 * Transform from milimeters to pixels.
 *
 * @param {number} milimeters
 */
export const fromMm2PX = (mm, dpi = 72) => {
  const px = Math.round((mm * dpi) / 25.4);

  return px;
};

export const sortProductObjects = (obj, keys) =>
  Object.keys(obj).reduce(
    (acc, key) => {
      if (keys.includes(key)) {
        acc.included[key] = obj[key];
      } else {
        acc.excluded[key] = obj[key];
      }
      acc.all[key] = obj[key];
      return acc;
    },
    { included: {}, excluded: {}, all: {} }
  );
