import { fabric } from 'fabric';
import { TemplateObject } from '../BitmapEditor.types';

export const drawDividerLine = (canvas: fabric.Canvas, object: TemplateObject): void => {
  const { x = 0, y = 0, width = 100, color = 'black' } = object;

  const line = new fabric.Line([x, y, x + width, y], {
    stroke: color,
    strokeWidth: 2,
    selectable: false,
    hasControls: false,
    hasBorders: false,
    lockMovementX: true,
    lockMovementY: true,
    hoverCursor: 'default',
  });

  canvas.add(line);
};