import './Profile.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addressActions from '../../actions/addressActions';
import userActions from '../../actions/userActions';
import { useHistory } from 'react-router-dom';
import IonIcon from '@reacticons/ionicons';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      item: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

type AddressBookItem = {
  address: string;
  address2?: string | undefined;
  addressId: string;
  city: string;
  zipcode: string;
};

const ProfileAddressBook = (props: any) => {
  const { userId } = props;
  const { t } = useTranslation();
  const [activeAddress, setActiveAddress] = useState<AddressBookItem | undefined>(undefined);
  const history = useHistory();

  const getAddresses = async () => {
    const addresses = (await addressActions.getAddresses(userId)) || {};
    const userActiveAddress = await userActions.getUser(userId).then((r) => (r.metadata || {}).activeAddress);
    const activeAddress = userActiveAddress
      ? addresses.find((a: AddressBookItem) => a.addressId === userActiveAddress)
      : addresses[0];
    setActiveAddress(activeAddress ?? addresses[0]);
  };

  useEffect(() => {
    getAddresses();
  }, []);

  const goToAddressBookHandler = () => {
    history.push(`/${global.language}/addressbook`);
  };

  return (
    <span>
      <h2 className="iconHeader">
        <IonIcon name="home" className="ion-icon" />
        {t('myDetails.Address.title')}
      </h2>
      <p>{t('myDetails.Address.text')}</p>
      {!activeAddress && <p>no active address</p>}
      {activeAddress && (
        <div className="profileAddress">
          <div className="address">{activeAddress.address}</div>
          {activeAddress.address2 && <div className="address2">{activeAddress.address2}</div>}
          {activeAddress.zipcode && <div className="zipcode">{activeAddress.zipcode}</div>}
          {activeAddress.city && <div className="city">{activeAddress.city}</div>}
        </div>
      )}
      <div className="buttonContainer">
        <button id="sendOrderBt" className="button lined" type="submit" onClick={goToAddressBookHandler}>
          {t('myDetails.Address.addressbook.button')}
        </button>
      </div>
    </span>
  );
};

export default ProfileAddressBook;
