import './Spinner.scss';
import React from 'react';
import LottiePlayer from 'lottie-react';
import animation from './SpinnerAnimation.json';

const SaveAnimation = () => (
  <div className="savingDataAnimation">
    <LottiePlayer animationData={animation} loop />
  </div>
);
export default SaveAnimation;
