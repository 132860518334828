import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import './ImageProvider.scss';

export const ImageContext = createContext();

const ImageProvider = (props) => {
  const { children } = props;
  const [zoomedImage, setZoomedImage] = useState();

  return (
    <>
      <ImageContext.Provider value={setZoomedImage}>{children}</ImageContext.Provider>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setZoomedImage(null)}
        onKeyPress={() => null}
        className={`zoomedImage ${!zoomedImage ? 'hidden' : ''}`}
      >
        {zoomedImage && <img src={zoomedImage} alt="zoom" />}
      </div>
    </>
  );
};

ImageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ImageProvider;
